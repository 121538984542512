import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { Folder } from '../models/folder';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FolderService extends ApiService {

  constructor(public http: HttpClient,  public router: Router) {
    super(http, router);
  }

  getById(id): Observable<any> {
    const path = `${this.fsBasePath}/folders/${id}`;

    return Observable.create(observer => {
      this.authenticatedGet(path)
        .map(results => {
          return {'folder': this.mapFolder(results['body']), 'headers': results['headers']};
        })
        .subscribe(result => {
          observer.next(result);
        });
    });
  }

  getChildren(id): Observable<any> {
    const path = `${this.fsBasePath}/folders/${id}/child_folders`;

    return Observable.create(observer => {
      this.authenticatedGet(path)
        .map(results => {
          return {'folders': this.mapFolders(results['body']), 'headers': results['headers']};
        })
        .subscribe(result => {
          observer.next(result);
        });
    });
  }

  getTree(id): Observable<any> {
    const path = `${this.fsBasePath}/folders/${id}/tree`;

    return Observable.create(observer => {
      this.authenticatedGet(path)
        .map(results => {
          return {'tree': results['body'], 'headers': results['headers']};
        })
        .subscribe(result => {
          observer.next(result);
        });
    });
  }

  move(id, parentId) {
    const path = `${this.fsBasePath}/folders/${id}/change_parent`;

    const formData: FormData = new FormData();
    formData.append('folder_id', parentId);

    return this.authenticatedPut(path, formData);
  }

  rename(id: number, name: string): Observable<any> {
    const path = `${this.fsBasePath}/folders/${id}/rename`;
    return this.authenticatedPut(path, {name: name});
  }

  post(params: any): Observable<any> {
    const path = `${this.fsBasePath}/folders`;

    const formData: FormData = new FormData();
    formData.append('room_id', params.roomId);
    formData.append('path', params.path + params.formData.name);

    return this.authenticatedPost(path, formData);
  }

  archive(id): Observable<any> {
    const path = `${this.fsBasePath}/folders/${id}/archive`;

    return Observable.create(observer => {
      this.authenticatedPut(path)
        .map(results => {
          return {'results': results['body'], 'headers': results['headers']};
        })
        .subscribe(result => {
          observer.next(result);
        });
    });
  }

  normalizeFullPathHash(fph: any, currentFolderId?: number) {
    let pre;
    if (fph.parent) {
      pre = this.normalizeFullPathHash(fph.parent, currentFolderId);
    }

    let str = '';
    if (pre) {
      str += pre;
    }

    if (fph.id !== currentFolderId) {
      if (str) {
        str += '/';
      }
      str += fph.name;
    }

    return str;
  }

  removeSlashesAtEnd(path: string) {

  }

  serialize(data: any): any {
    return data;
  }

  private mapFolders(results) {
    const folders = [];
    for (const result of results) {
      folders.push(this.mapFolder(result));
    }

    return folders;
  }

  private mapFolder(item) {
    return new Folder(item);
  }
}
