import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ChangePasswordModal } from '../../modals/change-password/change-password.modal';
import { SupportModal } from '../../modals/support/support.modal';
import { TwoFactorModal } from '../../modals/two-factor/two-factor.modal';
import { Room } from '../../models/room';
import { AppService } from '../../services/app.service';
import { UserService } from '../../services/user.service';


@Component({
  selector: 'main-sidebar',
  templateUrl: './main-sidebar.component.html'
})
export class MainSidebarComponent {
  public changePasswordModalRef: any;
  private isMobileMenuActive: boolean;
  public twoFactorModalRef: any;
  public supportModalRef: any;
  public room_id: any;
  public currentUser: any;
  public currentUserName: string;

  @Input() room: Room;
  @Output() notificationsClicked = new EventEmitter()

  constructor(
    public userService: UserService,
    private router: Router,
    public translate: TranslateService,
    public modalService: BsModalService,
    public appService: AppService,
    public route: ActivatedRoute
  ) {
    this.currentUser = this.userService.getCurrentUser();
    this.currentUserName = this.userService.getCurrentUserName();
  }

  public logoClicked() {
    if (this.userService.isLoggedIn()) {
      this.router.navigate(['/rooms']);
    } else {
      this.router.navigate(['/']);
    }

    this.closeMobileMenu();
  }

  public logout(event) {
    event.preventDefault();
    this.userService.logout().subscribe(result => {
      this.router.navigate(['/']);
      this.closeMobileMenu();
    });
  }

  public goOverview(event) {
    event.preventDefault();
    this.router.navigate(['/rooms']);
    this.closeMobileMenu();
  }

  public openPasswordModal(event) {
    event.preventDefault();
    this.changePasswordModalRef = this.modalService.show(ChangePasswordModal, {
    });

    this.closeMobileMenu();
  }

  public openTwoFactorModal(event) {
    event.preventDefault();
    this.twoFactorModalRef = this.modalService.show(TwoFactorModal, {
    });
  }

  public openSupport(event) {
    event.preventDefault();
    this.supportModalRef = this.modalService.show(SupportModal, {});

    if(this.room) {
      this.supportModalRef.content.room = this.room;
    }
  }

  public changeLanguage(event, language) {
    event.preventDefault();
    this.translate.use(language);
    moment.locale (language);
    localStorage.setItem('language', language);
    this.closeMobileMenu();
  }

  public currentLanguage(language) {
    return language === localStorage.getItem('language');
  }

  public toggleMobileMenu() {
    this.appService.mobileMenuActive = !this.appService.mobileMenuActive;
  }

  public toggleNotifications() {
    this.notificationsClicked.emit();
  }

  public closeMobileMenu() {
    this.appService.mobileMenuActive = false;
  }
}
