import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, HostBinding } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { Room } from '../../../../../models/room';
import { RoomParty } from '../../../../../models/room-party';
import { Participation } from '../../../../../models/participation';

import { RoomInviteModal } from '../../../../../modals/room-invite/room-invite.modal';
import { ArchivingModal } from '../../../../../modals/archiving/archiving.modal';

import { RoomPartyService } from '../../../../../services/room-party.service';

@Component({
  selector: 'party-comp',
  templateUrl: './party-comp.component.html',
})
export class PartyCompComponent {
  @Input() room: Room;
  @Input() party: RoomParty;
  @Input() participations: Participation[];

  @Output() partyUpdated: EventEmitter<any> = new EventEmitter<any>();
  @Output() participationsUpdated: EventEmitter<any> = new EventEmitter<any>();

  public modalRef: BsModalRef;
  public archivingModalRef: any;

  public renameModeOn: boolean;
  public renameValue: string;
  public isRenaming: boolean;

  constructor(
    public modalService: BsModalService,
    public roomPartyService: RoomPartyService
  ) {}

  optionRename(event?) {
    if (event) {
      event.preventDefault();
    }

    this.renameModeOn = true;
    this.renameValue = this.party.company_name;
  }

  optionArchive(event, doUnarchive) {
    event.preventDefault();

    this.archivingModalRef = this.modalService.show(ArchivingModal, {
      class: `modal-md`
    });

    this.archivingModalRef.content.contextType = 'party';
    this.archivingModalRef.content.context = this.party;
    this.archivingModalRef.content.unArchive = doUnarchive;
    
    this.archivingModalRef.content.process().subscribe(() => {
      this.partyUpdated.emit({});
    });
  } 

  handleRename(event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.isRenaming) {
      return;
    }

    if (!this.renameValue || this.renameValue === this.party.company_name) {
      this.cancelRename();
      return;
    }

    this.isRenaming = true;
    this.roomPartyService.put(this.party.id, {company_name: this.renameValue}).subscribe(() => {
      this.party.company_name = this.renameValue;
      this.isRenaming = false;
      this.cancelRename();
    }, error => {
      this.isRenaming = false;
    });
  }

  cancelRename(event?) {
    if (event) {
      event.preventDefault();
    }

    this.renameModeOn = false;
    this.renameValue = '';
  }

  openInviteUserModal() {
    this.modalRef = this.modalService.show(RoomInviteModal, {
      class: `modal-lg`
    });

    this.modalRef.content.roomId = this.room.id;
    this.modalRef.content.partyId = this.party.id;
    
    this.modalRef.content.process().subscribe(() => {
      this.participationsUpdated.emit();
    });
  }

  inAdminContext() {
    return this.room.current_participation.role === 'admin';
  }
}
