import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AngularTokenService } from 'angular-token';

@Injectable()
export class ApiService {
  basePath = environment.basePath;
  fsBasePath = this.basePath + '/api/v1';
  frontendPath = environment.frontendPath;

  constructor(
    public http: HttpClient,
    private _router: Router
  ) {}

  authenticatedGet(path: string, data: object = {}) {
    return Observable.create(observer => {
      let params = new HttpParams();

      Object.keys(data).forEach(function (key) {
       params = params.append(key, data[key]);
      });

      this.http.get(path, {params: params, observe: 'response'}).subscribe(res => {
        observer.next({body: res.body, headers: res.headers});
      }, err => {
        this.handleError(err);
        observer.error(err);
      });
    });
  }

  authenticatedPost(path: string, data) {
    return Observable.create(observer => {
      this.http.post(path, data, {observe: 'response'}).subscribe(res => {
        observer.next({body: res.body, headers: res.headers});
      }, err => {
        this.handleError(err);
        observer.error(err);
      });
    });
  }

  authenticatedPut(path: string, data?) {
    return Observable.create(observer => {
      this.http.put(path, data, {observe: 'response'}).subscribe(res => {
        observer.next({body: res.body, headers: res.headers});
      }, err => {
        this.handleError(err);
        observer.error(err);
      });
    });
  }

  authenticatedDelete(path: string) {
    return Observable.create(observer => {
      this.http.delete(path, {observe: 'response'}).subscribe(res => {
        observer.next({body: res.body, headers: res.headers});
      }, err => {
        this.handleError(err);
        observer.error(err);
      });
    });
  }

  authenticatedPDFBlob(path: string) {
    return this.http.get(this.fsBasePath + '/' + path, {observe: 'response', responseType: 'blob'}).map(
      (res) => {
        return res;
        // return new Blob(res, { type: 'application/pdf' });
      });
  }

  private handleError(err) {
    let request: String = '';
    if (err.url) {
      request = err.url.substr(err.url.lastIndexOf('/') + 1);
    }
    
    if (err.status === 401 && request !== 'loggedin') {
      localStorage.clear();
      this._router.navigate(['/login']);
    }
  }
}
