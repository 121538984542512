import { Component, TemplateRef, OnInit, OnDestroy, Input, Output, ElementRef } from '@angular/core';
import { ValidationService } from '../../services/validation.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: '[form-label]',
  templateUrl: './form-label.component.html',
})
export class FormLabelComponent implements OnInit {
    @Input() control: FormControl;
    @Input() defaultLabel: String = '';
    @Input() showValidation: boolean;

    constructor(
      public el: ElementRef,
      public validationService: ValidationService
    ) {

    }

    ngOnInit() {
      
    }
}
