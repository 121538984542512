export class Document {
  public id: number;
  public file: any;
  public name: string;
  public version: number;
  public fullPathFolder: string;
  public questions_count: number;
  public view_count: number;
  public created_at: Date;
  public updated_at: Date;

  constructor(item) {
    if (!item) {
      return;
    }

    this.id = item.id;
    this.file = item.file;
    this.name = item.name;
    this.version = item.version;
    this.questions_count = item.questions_count;
    this.view_count = item.view_count;

    if (item.full_path_folder || item.full_path_folder == "") {
      this.fullPathFolder = item.full_path_folder;
    }

    if (item.created_at) {
      this.created_at = new Date(item.created_at);
    }

    if (item.updated_at) {
      this.updated_at = new Date(item.updated_at);
    }
  }

  getExtention() {
    return this.name.split('.').pop().toLowerCase();
  }

  getFaIcon() {
    const ext = this.getExtention();

    if (!ext) {
      return 'fa-file';
    }

    if (['png', 'jpg', 'bmp'].indexOf(ext) > -1) {
      return 'fa-file-image';
    } else
    if (['pdf'].indexOf(ext) > -1) {
      return 'fa-file-pdf';
    } else
    if (['mp4', 'avi', 'mov', 'mpeg', '3pg'].indexOf(ext) > -1) {
      return 'fa-file-video';
    } else
    if (['mp3', 'wav'].indexOf(ext) > -1) {
      return 'fa-file-audio';
    } else
    if (['html', 'css', 'sass'].indexOf(ext) > -1) {
      return 'fa-file-code';
    } else
    if (['docx', 'doc'].indexOf(ext) > -1) {
      return 'fa-file-word';
    } else
    if (['xls', 'xlsx', 'numbers'].indexOf(ext) > -1) {
      return 'fa-file-excel';
    } else
    if (['ppt', 'pptx', 'pt'].indexOf(ext) > -1) {
      return 'fa-file-powerpoint';
    } else
    if (['pages'].indexOf(ext) > -1) {
      return 'fa-file-alt';
    } else
    if (['zip', 'rar'].indexOf(ext) > -1) {
      return 'fa-file-archive';
    } else
    if (['jw'].indexOf(ext) > -1) {
      return 'fa-smile';
    } else {
      return 'fa-file';
    }
  }
}
