import { Component, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Observable } from 'rxjs';

import { CouponService } from '../../services/coupon.service';

@Component({
  selector: 'redeem-coupon-modal',
  templateUrl: './redeem-coupon.modal.html',
})
export class RedeemCouponModal {
  public form: FormGroup;
  public isChecking: boolean;
  public invalidToken: boolean;
  public isFinished: boolean;
  public errors: any[];
  public showValidations: boolean; //not used today.

  @Output() validated: EventEmitter<any> = new EventEmitter();

  constructor(
    public bsModalRef: BsModalRef,
    public formBuilder: FormBuilder,
    public couponService: CouponService
  ) {
    this.setupForm();
  }

  private setupForm() {
    const builderOptions = {};
    builderOptions['token'] = new FormControl('', Validators.required);

    this.form = this.formBuilder.group(builderOptions);
    this.isChecking = false;
  }

  onSubmit() {
    if (this.form.valid) {
      this.checkToken();
    }
  }

  private checkToken() {
    this.isChecking = true;
    this.invalidToken = false;
    this.isFinished = false;

    const token = this.form.value.token;
    this.couponService.get(token).subscribe(resp => {
      this.isChecking = false;
      this.invalidToken = false;
      this.isFinished = true;
      
      this.validated.emit(token);
      this.bsModalRef.hide();
    }, err => {
      this.isChecking = false;
      this.invalidToken = true;
    });
  }

  public closeModal() {
    this.bsModalRef.hide();
  }
}
