import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { AppService } from '../../services/app.service';
import { RoomNotificationService } from '../../services/room-notification.service';
import { RoomNotification } from '../../models/room-notification';
import { FolderService } from '../../services/folder.service';
import { Router } from '@angular/router';
import { Room } from '../../models/room';

@Component({
  selector: 'nav-notifications',
  templateUrl: './nav-notifications.component.html',
})
export class NavNotificationsComponent implements OnInit, OnDestroy  {
  public notifications: RoomNotification[];
  public notificationsHeaders: any;
  public isLoading: Boolean;
  public isMarkingAllAsRead: Boolean;
  public perPage: number = 25;
  
  private detectOutsideClick: boolean;
  
  @Input() room: Room;
  
  constructor(
    public appService: AppService,
    public notificationService: RoomNotificationService,
    public folderService: FolderService,
    public router: Router
  ) {}

  ngOnInit() {
    this.getNotifications();
    setTimeout(() => { this.detectOutsideClick = true; }, 250);
  }

  public onClickedOutside(event) {
    event.preventDefault();
    event.stopPropagation();
    
    if(!this.detectOutsideClick) {
      return;
    } else {
      this.close();
    }
  }

  public markAllAsRead() {
    this.isMarkingAllAsRead = true;
    const participationId = this.room.current_participation.id;
    this.notificationService.markAsAllRead(participationId).subscribe(() => {
      this.room.current_participation.unread_notifications = 0;
      this.getNotifications();
    });
  }

  public getNotifications() {
    this.isLoading = true;
    const participationId = this.room.current_participation.id;
    this.notificationService.get(participationId, 1, this.perPage).subscribe(resp => {
      this.notifications = resp.notifications;
      this.notificationsHeaders = resp.headers;
      this.isLoading = false;
      this.isMarkingAllAsRead = false;
    });
  }

  public notificationClicked(not) {
    return; //temp untill 404 clicked are resolved
    const routing = this.notificationService.getRouting(not);
    console.info('go to route for not:', not);
    console.info('routing:', routing);

    if(routing) {
      this.router.navigate(['rooms', this.room.id, routing.routerLink], { queryParams: routing.queryParams });
      this.close();
    }
  }

  public close() {
    this.appService.notificationsOpen = false;
  }

  ngOnDestroy() {
    this.detectOutsideClick = false;
    this.close();
  }

  public closeModal() {
    this.close();
  }
}
