import { Component, OnInit, OnDestroy, Input, Output } from '@angular/core';

import { RoomActivityService } from '../../../../../services/room-activity.service';

import { RoomActivity } from '../../../../../models/room-activity';

@Component({
  selector: 'tr-activity',
  templateUrl: './tr-activity.component.html',
  styles: [`
    :host {
      display: table-row;
    }
  `]
})
export class TrActivityComponent {
  private _activity: RoomActivity;
  
  public trackableFullName: String;

  @Input() set activity(value: RoomActivity) {
    this._activity = value;
  }

  get activity(): RoomActivity {
    return this._activity;
  }

  constructor(
    public roomActivityService: RoomActivityService
  ) {}
}
