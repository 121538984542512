export class RoomExport {
  public assignees: any[];
  public created_at: Date;
  public room_created_at: Date;
  public transaction_date_time: Date;
  public name: String;
  public certificate_ready: boolean;

  constructor(item) {
    if (!item) {
      return;
    }

    this.assignees = item.assignees;
    this.name = item.room_name;
    this.certificate_ready = item.certificate_ready;
    this.transaction_date_time = item.transaction_date_time;

    if (item.created_at) {
      this.created_at = new Date(item.created_at);
    }

    if (item.room_created_at) {
      this.room_created_at = new Date(item.room_created_at);
    }
  }
}
