import { Component, OnInit, Input } from '@angular/core';
import { Room } from '../../../../models/room';
import { RoomChartService } from '../../../../services/room-chart.service';

@Component({
  selector: 'room-tab-charts',
  templateUrl: './tab-charts.component.html'
})
export class TabChartsComponent implements OnInit {
  private _active: boolean;

  constructor(private chartService: RoomChartService ) {}
  public timeData = {};
  public topDocumentsData = {};
  public topViewersData = {};

  @Input() room: Room;

  @Input() set active(value: boolean) {
    this._active = value;

    if (this._active) {
      this.loadTimeLine();
      this.loadTopDocumentsViewed();
      this.loadTopViewers();
    }
  }

  loadTimeLine() {
    this.chartService.getTimeline(this.room.id)
      .subscribe(res => {
        let maxLength = 91;

        let keys = Object.keys(res);
        let values = Object.keys(res).map(key => res[key]);

        if(window.innerWidth<620) {
          maxLength = 21;
        }

        if(keys.length>maxLength) {
          keys = keys.splice((keys.length-maxLength),maxLength);
          values = values.splice((keys.length-maxLength),maxLength);
        }
        
        if(keys && values && keys.length && values.length) {
          this.timeData = {'keys': keys, 'values': values};
        }
      });
  }

  loadTopDocumentsViewed() {
    this.chartService.getTopDocumentsViewed(this.room.id)
      .subscribe(res => {
        const keys = Object.keys(res);
        const values = Object.keys(res).map(key => res[key]);

        if(keys && values && keys.length && values.length) {
          this.topDocumentsData = {'keys': keys, 'values': values};
        }
      });
  }

  loadTopViewers() {
    this.chartService.getTopViewers(this.room.id)
      .subscribe(res => {
        const keys = Object.keys(res);
        const values = Object.keys(res).map(key => res[key]);

        if(keys && values && keys.length && values.length) {
          this.topViewersData = {'keys': keys, 'values': values};
        }
      });
  }

  ngOnInit() {

  }
}
