import { Http } from '@angular/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { State } from '../models/state';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class StateService extends ApiService {

  constructor(
    public http: HttpClient,
    public router: Router
  ) {
    super(http, router);
  }

  get(country_id) {
    return Observable.create(observer => {
      this.http.get(this.fsBasePath + '/states?country_id=' + country_id)
        .map(results => {
          return this.mapStates(results['body']);
        })
        .subscribe(res => {
          observer.next(res);
        },
        err => {
          observer.error(err);
        });
    });
  }

  getById(id): Observable<any> {
    const path = `${this.fsBasePath}/states/${id}`;

    return Observable.create(observer => {
      this.authenticatedGet(path)
        .map(results => {
          return {'state': this.mapState(results['body']), 'headers': results['headers']};
        })
        .subscribe(result => {
          observer.next(result);
        });
    });
  }

  private mapStates(results) {
    const states = [];
    for (const result of results) {
        states.push(this.mapState(result));
    }
    return states;
  }

  private mapState(item) {
    return new State(item);
  }
}
