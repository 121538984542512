import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { UploadFile, FileSystemFileEntry } from 'ngx-file-drop';
import { FireUploadFile } from '../models/fire-upload-file';
import { combineLatest } from 'rxjs';

@Injectable()
export class FileService {

  constructor(
    private _ngZone: NgZone
  ) {}

  getUploadFilesInfo(uploadFiles: UploadFile[]) {
    return Observable.create(observer => {

      uploadFiles = uploadFiles.filter(uploadFile => {
        return uploadFile.fileEntry.isFile;
      });

      combineLatest(uploadFiles
        .map(uploadFile => {
          return this.getUploadFileInfo(uploadFile);
        }))
        .subscribe(response => {
          observer.next(response);
        });
    });
  }

  getUploadFileInfo(uploadFile: UploadFile): Observable<any> {
    return Observable.create(observer => {
      const fileEntry = uploadFile.fileEntry as FileSystemFileEntry;
      fileEntry.file(fileInfo => {
        const file: File = fileInfo;

        this._ngZone.run(() => {
          const fireUploadFile = new FireUploadFile({
            file: file,
            path: uploadFile.relativePath
          });

          observer.next(fireUploadFile);
        });
      });
    });
  }

  getWebkitFilesInfo(files: any[]) {
    return Observable.create(observer => {
      const fireFiles = [];

      for (const file of files) {
        fireFiles.push(new FireUploadFile({
          file: file,
          path: file.webkitRelativePath
        }));
      }

      observer.next(fireFiles);
    });
  }
}
