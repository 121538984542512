import * as moment from 'moment';

export class User {
  public id: number;
  public uid: string;
  public first_name: string;
  public last_name: string;
  public email: string;
  public invitationSentAt: Date;
  public updated_at: Date;

  constructor(item) {
    this.id = item.id;
    this.uid = item.uid;
    this.first_name = item.first_name || '';
    this.last_name = item.last_name || '';
    this.email = item.email;

    if (item.updated_at) {
      this.updated_at = new Date(item.updated_at);
    }

    if (item.invitation_sent_at) {
      this.invitationSentAt = item.invitation_sent_at
    }
  }

  getFullName(): string {
    let fn = '';

    if(this.first_name && this.last_name) {
      fn = [this.first_name, this.last_name].join(' ');
    } else if(this.first_name) {
      fn = this.first_name;
    } else if(this.last_name) {
      fn = this.last_name;
    }

    if(!fn || !fn.length) {
      fn = this.email;
    }

    return fn;
  }
}
