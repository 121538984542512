import { Party } from './party';

export class Contract {
  public id: number;
  public origin: string;
  public token: string;
  public parties: Party[] = [];
  public pages: string[] = [];

  constructor(item) {
    if (!item) {
      return;
    }

    this.id = item.id;
    this.origin = item.origin;
    this.token = item.token;
    this.pages = item.preview;
  }
}
