import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, HostBinding } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { Participation } from '../../../../../models/participation';
import { Room } from '../../../../../models/room';

import { SelectService } from '../../../../../services/select.service';
import { ParticipationService } from '../../../../../services/participation.service';

import { ArchivingModal } from '../../../../../modals/archiving/archiving.modal';

@Component({
  selector: 'tr-user',
  templateUrl: './tr-user.component.html',
  styles: [`
    :host {
      display: table-row;
    }
  `]
})
export class TrUserComponent {
  @Input() participation: Participation;
  @Input() room: Room;
  @Output() updated: EventEmitter<any> = new EventEmitter<any>();

  public archivingModalRef: any;
  public isInviting: Boolean;

  constructor(
    public modalService: BsModalService,
    public selectService: SelectService,
    public participationService: ParticipationService
  ) {}

  changeRole(event, roleId) {
    event.preventDefault();

    this.participation.role = roleId;

    this.participationService.put({
      id: this.participation.id,
      formData: {
        role: roleId
      }
    }).subscribe(response => {
      // update inner instance ...
      this.participation = response.participation;
      // OR update entire list ...
      // this.updated.emit(response.participation);
    });
  }

  optionArchive(event, doUnarchive) {
    event.preventDefault();

    this.archivingModalRef = this.modalService.show(ArchivingModal, {
      class: `modal-md`
    });

    this.archivingModalRef.content.contextType = 'room-participation';
    this.archivingModalRef.content.context = this.participation;
    this.archivingModalRef.content.room = this.room;
    this.archivingModalRef.content.unArchive = doUnarchive;

    this.archivingModalRef.content.process().subscribe(() => {
      this.updated.emit();
    });
  }

  resendParticipationInvite(participation: Participation) {
    this.isInviting = true;
    this.participationService.resendInvitation(participation).subscribe(updatedParticipation => {
      this.participation = updatedParticipation;
      this.isInviting = false;
    });
  }

  canChangeRole() {
    if(!this.inAdminContext()) {
      return false;
    }
    
    if(this.isSelf()) {
      return false;
    }

    if(!this.participation.active) {
      return false;
    }

    if(this.participation.state=='declined') {
      return false;
    }

    return true;
  }

  isArchived() {
    return (this.participation.archived || !this.participation.active);
  }

  canArchive() {
    if (this.isSelf()) {
      return false;
    }

    if (!this.inAdminContext()) {
      return false;
    }

    return true;
  }

  canSendInvitations() {
    if (!this.inAdminContext()) {
      return false;
    }

    return true;
  }

  isSelf() {
    return this.participation.id === this.room.current_participation.id;
  }

  inAdminContext() {
    return this.room.current_participation.role === 'admin';
  }

  getColor() {
    if(this.participation.state=='accepted') {
      return 'secondary';
    } else if(this.participation.state=='pending') {
      return 'danger';
    } else if(this.participation.state=='declined') {
      return 'danger';
    }
  }
}
