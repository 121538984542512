import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { RoomExport } from '../models/room-export';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class RoomExportService extends ApiService {

  constructor(
    public http: HttpClient,
    public router: Router
  ) {
    super(http, router);
  }

  getByRoomId(roomId: number, filters?: Object, page?: number, perPage?: number): Observable<any> {
    const path = `${this.fsBasePath}/rooms/${roomId}/export`;
    const params: any = {};

    return Observable.create(observer => {
      this.authenticatedGet(path, params)
        .map(results => {
          return {'export': this.mapRoomExport(results['body']), 'headers': results['headers']};
        })
        .subscribe(results => {
          observer.next(results);
        });
    });
  }

  getCertificateByRoomId(roomId: number, filters?: Object, page?: number, perPage?: number): Observable<any> {
    const path = `${this.fsBasePath}/rooms/${roomId}/export_pdf`;
    const params: any = {};

    return Observable.create(observer => {
      this.authenticatedGet(path, params)
        .map(results => results['body'])
        .subscribe(results => {
          observer.next(results);
        });
    });
  }

  getZipByRoomId(roomId: number, filters?: Object, page?: number, perPage?: number): Observable<any> {
    const path = `${this.fsBasePath}/rooms/${roomId}/export_zip`;
    const params: any = {
    };

    return Observable.create(observer => {
      this.authenticatedGet(path, params)
        .map(results => results['body'])
        .subscribe(results => {
          observer.next(results);
      });
    });
  }

  private mapRoomExport(item) {
    return new RoomExport(item);
  }
}
