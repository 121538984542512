import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';


@Component({
  selector: 'fs-confirmed',
  templateUrl: './confirmed.component.html'
})
export class ConfirmedComponent implements OnInit {
  constructor(private router: Router, private location: Location) { }

  ngOnInit() {
    this.location.replaceState('confirmed');
  }
}
