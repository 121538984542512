import { Title } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

@Injectable()
export class TitleService {

  constructor(
    private titleService: Title
  ) {}

  public setTitle(value: string): void {
    const sufix = 'FireStarter';

    if (value) {
      this.titleService.setTitle(`${value} | ${sufix}`);
    }
  }
}
