import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'fs-included',
  templateUrl: './included.modal.html',
})
export class IncludedModal {
  public constructor(
      public bsModalRef: BsModalRef
  ) { }

  close() {
    this.bsModalRef.hide();
  }
}
