import { Pipe } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe {
  transform(array: any[], field: string, reversed  = true, extra_field?): any[] {
    if (!array) array = [];

    array.sort((a: any, b: any) => {
      let a_value = a[field];
      let b_value = b[field];

      let c_value = a[extra_field];
      let d_value = b[extra_field];

      // Sort strings based on toLowerCase to prevent automatic CaseSensitive sorting.
      if(typeof(a[field]) == 'string' || typeof(b[field]) == 'string') {
        a_value = String(a[field]).toLowerCase();
        b_value = String(b[field]).toLowerCase();
      }

      if(typeof(a[extra_field]) == 'string' || typeof(b[extra_field]) == 'string') {
        c_value = String(a[extra_field]).toLowerCase();
        d_value = String(b[extra_field]).toLowerCase();
      }

      if (a_value < b_value) {
        return 1;
      } else if (a_value > b_value) {
        return -1;
      } else {
        if (c_value < d_value) {
          return -1;
        } else if (c_value > d_value) {
          return 1;
        } else {
          return 0;
        }
      }
    });

    if (reversed) {
      array = array.reverse();
    }

    return array;
  }
}
