import { Component, OnInit, Input, OnDestroy, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { Room } from '../../../../models/room';
import { Question } from '../../../../models/question';
import { Comment } from '../../../../models/comment';
import { QuestionService } from '../../../../services/question.service';
import { CommentService } from '../../../../services/comment.service';
import { UserService } from '../../../../services/user.service';
import { AssignQuestionModal } from "../../../../modals/questions/assign-question.modal";

@Component({
  selector: 'room-tab-answers',
  templateUrl: './tab-answers.component.html'
})
export class TabAnswersComponent implements OnInit, OnDestroy {
  public assignQuestionModal: any;
  public isLoading: boolean;
  public savingComment: boolean;
  public questionId: number;
  public question: Question;
  public newComment: string;
  public modalRef: BsModalRef;

  private _active: boolean;
  private routeSub: any;
  private commentToArchive;

  @Input() room: Room;

  @Input() set active(value: boolean) {
    this._active = value;
  }

  @ViewChild('commentInput') commentInput: ElementRef;

  get active(): boolean {
    return this._active;
  }

  constructor(
    public questionService: QuestionService,
    public commentService: CommentService,
    private activatedRoute: ActivatedRoute,
    public userService: UserService,
    public modalService: BsModalService
  ) {}

  ngOnInit() {
    this.routeSub = this.activatedRoute.queryParams.subscribe((queryParams: Params) => {
      this.questionId = queryParams['q'];
      this.update();
    });
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

  openArchiveCommentModal(template: TemplateRef<any>, comment: Comment, size: string) {
    this.commentToArchive = comment;
    this.modalRef = this.modalService.show(template, {class: `modal-${size}`});
  }

  update() {
    if (this.questionId) {
      this.isLoading = true;
      this.questionService.getById(this.questionId).subscribe(response => {
        this.question = response;
        this.isLoading = false;
      });
    }
  }

  openAssignUsersModal() {
    this.assignQuestionModal = this.modalService.show(AssignQuestionModal, {
      class: `modal-lg`
    });

    this.assignQuestionModal.content.roomId = this.room.id;
    this.assignQuestionModal.content.question = this.question;
    this.assignQuestionModal.content.process().subscribe(() => this.update());
  }

  toggleClosed() {
    this.question.closed = !this.question.closed;
    this.questionService.close(this.questionId, this.question.closed).subscribe(response => this.update());
  }

  createComment() {
    this.savingComment = true;
    this.commentService.create({
      questionId: this.questionId,
      description: this.newComment
    }).subscribe(response => {
      this.newComment = "";
      this.savingComment = false;
      this.update();
    });
  }

  archiveComment() {
    this.commentService.update(this.commentToArchive.id, {
      archived: true
    }).subscribe(response => {
      this.modalRef.hide();
      this.commentToArchive = null;
      this.update();
    });
  }

  scrollToBottom() {
    window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' });
    this.commentInput.nativeElement.focus({
      preventScroll: true
    });
  }

  getAssigneeNames(): String {
    let fullNames: String[] = this.question.assignees.map(assignee => {
      return assignee.getFullName()
    });

    return [fullNames.slice(0, -1).join(', '), fullNames.slice(-1)[0]].join(fullNames.length < 2 ? '' : ' and ');
  }
}
