import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { Document } from '../../models/document';
import { Room } from '../../models/room';
import { DocumentService } from '../../services/document.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'document-search',
  templateUrl: './document-search.component.html',
})
export class DocumentSearchComponent implements OnInit {
  listDocuments: (term: string) => Observable<Document[]>;
  public adapter: any;
  public resetting: boolean;

  MORE_RESULTS_MSG = 'Showing ' + Messages.PARTIAL_COUNT_VAR + ' of ' + Messages.TOTAL_COUNT_VAR + ' results. Refine your search.';
  NO_RESULTS_MSG = this.translate.instant('general.no_search_result');
  
  public messages = {
      moreResultsAvailableMsg: this.MORE_RESULTS_MSG,
      noResultsAvailableMsg: this.NO_RESULTS_MSG
  };

  constructor(
    private documentService: DocumentService,
    private translate: TranslateService
  ) {
  }

  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Output() onRemove: EventEmitter<any> = new EventEmitter();
  @Input() room: Room;
  @Input() selectedDocument: Document;
  @Input() placeholder: String;
  @Input() clearOnSelect: boolean = false;

  ngOnInit() {
    this.listDocuments = (term: string) => this.searchDocuments(term);
    this.adapter = (document: Document) => {
      return {
        id: document.id,
        text: document.name,
        entity: document
      };
    };
  }

  searchDocuments(term): Observable<any> {
    return Observable.create(observer => {
      this.documentService.search(this.room.id, term).subscribe( result => {
        observer.next(result.documents);
      });
    });
  }

  fileSelected($event) {
    this.onSelect.emit($event);

    // Very hacky way to clear Ng2Select2
    // But there is no other available or documented way.
    if(this.clearOnSelect) {
      this.resetting = true;
      this.selectedDocument = undefined;
      setTimeout(() => {
        this.resetting = false;
      }, 10);
    }
  }

  fileRemoved($event) {
    this.onRemove.emit($event);
  }
}


export class Messages {
  static readonly PARTIAL_COUNT_VAR; // Variable to be replaced by the amount of results being shown
  static readonly TOTAL_COUNT_VAR; // Variable to be replaced by the total count of results

  moreResultsAvailableMsg?: string;
  noResultsAvailableMsg?: string;
}