import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable, Injector, InjectionToken, ErrorHandler } from '@angular/core';
import { HttpModule } from '@angular/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {HttpClientModule} from '@angular/common/http';
import localeNLBE from '@angular/common/locales/nl-BE';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { AppRoutingModule, routingComponents} from './app.routes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularTokenModule } from 'angular-token';
import { IqSelect2Module } from 'ng2-iq-select2';
import { FileDropModule } from 'ngx-file-drop';
import { MomentModule } from 'angular2-moment';
import { ModalModule, BsDropdownModule, TooltipModule } from 'ngx-bootstrap';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ClickOutsideModule } from 'ng-click-outside';

import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';

// Pipes
import { SortPipe } from './pipes/sort.pipe';
import { TruncateMiddle } from './pipes/truncate-middle.pipe';

// Interceptors
import { LanguageInterceptor } from './interceptors/language.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';

// Services
import { UserService } from './services/user.service';
import { CommentService } from './services/comment.service';
import { CountryService } from './services/country.service';
import { StateService } from './services/state.service';
import { TitleService } from './services/title.service';
import { SelectService } from './services/select.service';
import { RoomService } from './services/room.service';
import { DocumentService } from './services/document.service';
import { FolderService } from './services/folder.service';
import { ParticipationService } from './services/participation.service';
import { RoomPartyService } from './services/room-party.service';
import { RoomActivityService } from './services/room-activity.service';
import { RoomNotificationService } from './services/room-notification.service';
import { FileService } from './services/file.service';
import { DataService } from './services/data.service';
import { ContractService } from './services/contract.service';
import { QuestionService } from './services/question.service';
import { ValidationService } from './services/validation.service';
import { CouponService } from './services/coupon.service';
import { RoomExportService } from './services/room-export.service';
import { ApiService } from './services/api.service';
import { AppService } from './services/app.service';
import { SupportService } from './services/support.service';
import { RoomChartService } from './services/room-chart.service';

// Rooms-Detail Tab Components
import { TabDocumentsComponent } from './pages/rooms/rooms-detail/tab-documents/tab-documents.component';
import { TabUsersComponent } from './pages/rooms/rooms-detail/tab-users/tab-users.component';
import { TabReportingComponent } from './pages/rooms/rooms-detail/tab-reporting/tab-reporting.component';
import { TabFaqComponent } from './pages/rooms/rooms-detail/tab-faq/tab-faq.component';
import { TabAnswersComponent } from './pages/rooms/rooms-detail/tab-answers/tab-answers.component';
import { TabChartsComponent } from './pages/rooms/rooms-detail/tab-charts/tab-charts.component';

// Modals
import { UploadDocumentsModal } from './modals/upload-documents/upload-documents.modal';
import { CreateQuestionModal } from './modals/questions/create-question.modal';
import { AssignQuestionModal } from './modals/questions/assign-question.modal';
import { RoomInviteModal } from './modals/room-invite/room-invite.modal';
import { NewFolderModal } from './modals/new-folder/new-folder.modal';
import { ArchivingModal } from './modals/archiving/archiving.modal';
import { PrivacyPolicyModal } from './modals/privacy-policy/privacy-policy.modal';
import { TermsOfServiceModal } from './modals/terms-of-service/terms-of-service.modal';
import { UsersModal } from './modals/users/users.modal';
import { ChangePasswordModal } from './modals/change-password/change-password.modal';
import { TwoFactorModal } from './modals/two-factor/two-factor.modal';
import { RoomCreateModal } from './modals/room-create/room-create.modal';
import { AcceptParticipationModal } from './modals/accept-participation/accept-participation.modal';
import { DeclineParticipationModal } from './modals/decline-participation/decline-participation.modal';
import { ChildActivitiesModal } from './modals/child-activities/child-activities.modal';
import { DocumentViewsModal } from './modals/document-views/document-views.modal';
import { IncludedModal} from './modals/included/included.modal';
import { RoomSealModal } from './modals/room-seal/room-seal.modal';
import { SupportModal } from './modals/support/support.modal';
import { FoldersTreeModal } from './modals/folders-tree/folders-tree.modal';
import { NewPartyModal } from './modals/new-party/new-party.modal';
import { RedeemCouponModal } from './modals/redeem-coupon/redeem-coupon.modal';


// Components
import { FoldersTreeItemComponent } from './components/folders-tree-item/folders-tree-item.component';
import { NavNotificationsComponent } from './components/nav-notifications/nav-notifications.component';
import { RoomCardComponent } from './components/room-card/room-card.component';
import { FilesDropZoneComponent } from './components/files-drop-zone/files-drop-zone.component';
import { FolderBreadcrumbComponent } from './components/folder-breadcrumb/folder-breadcrumb.component';
import { MainSidebarComponent } from './components/main-sidebar/main-sidebar.component';
import { TrDocumentComponent } from './pages/rooms/rooms-detail/components/tr-document/tr-document.component';
import { TrFolderComponent } from './pages/rooms/rooms-detail/components/tr-folder/tr-folder.component';
import { TrUserComponent } from './pages/rooms/rooms-detail/components/tr-user/tr-user.component';
import { TrActivityComponent } from './pages/rooms/rooms-detail/components/tr-activity/tr-activity.component';
import { PartyCompComponent } from './pages/rooms/rooms-detail/components/party-comp/party-comp.component';
import { FormLabelComponent } from './components/form-label/form-label.component';
import { AlwaysAutofocusComponent } from './components/always-autofocus/always-autofocus.component';
import { PageLoaderComponent } from './components/page-loader/page-loader.component';
import { FireInputComponent } from './components/fire-input/fire-input.component';
import { FireSelectComponent } from './components/fire-select/fire-select.component';
import { FireSimpleSelectComponent } from './components/fire-simple-select/fire-simple-select.component';
import { FireCheckboxComponent } from './components/fire-checkbox/fire-checkbox.component';
import { FireLineChartComponent } from './components/fire-linechart/fire-linechart.component';
import { FirePieChartComponent } from './components/fire-piechart/fire-piechart.component';
import { RoomRulesComponent} from './modals/room-rules/room-rules.component';
import { RoomCreateProgressComponent} from './modals/room-create/progress/room-create-progress.component';
import { RoomCreateSuccessComponent} from './modals/room-create/success/room-create-success.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { DocumentSearchComponent } from './components/document-search/document-search.component';
import { RoomRulesRuComponent } from './modals/room-rules/room-rules-ru/room-rules-ru.component';
import { RoomRulesPtComponent } from './modals/room-rules/room-rules-pt/room-rules-pt.component';
import { RoomRulesDeComponent } from './modals/room-rules/room-rules-de/room-rules-de.component';
import { RoomRulesNlComponent } from './modals/room-rules/room-rules-nl/room-rules-nl.component';
import { RoomRulesFrComponent } from './modals/room-rules/room-rules-fr/room-rules-fr.component';
import { RoomRulesEsComponent } from './modals/room-rules/room-rules-es/room-rules-es.component';
import { RoomRulesEnComponent } from './modals/room-rules/room-rules-en/room-rules-en.component';


// Injectables
import { LoginRouteGuard } from './injectables/login-route-guard';
import { PlanService } from './services/plan.service';

// Rollbar
import * as Rollbar from 'rollbar';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import { LOCALE_ID } from '@angular/core';
import { versions } from '../environments/versions';



registerLocaleData(localeNLBE);


const rollbarConfig = {
  accessToken: 'c068d96b30f6454db71709e0632ba659',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: environment.name,
    client: {
      javascript: {
            code_version: versions.revision
      }
    }
  }
};

registerLocaleData(localeNLBE);

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(err: any): void {
   const rollbar = this.injector.get(RollbarService);
   if (environment.rollbar) {
    rollbar.error(err.originalError || err);
   } else {
    console.error('local-err', err);
   }
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}
export const RollbarService = new InjectionToken<Rollbar>('rollbar');
export function HttpLoaderFactory(http: HttpClient) {
  const time = (new Date()).getTime();
  const extension =  '.json?' + time;
  return new TranslateHttpLoader(http, './assets/i18n/', extension);
}

// End Rollbar

@NgModule({
  declarations: [
    AppComponent,
    routingComponents,

    // Pipes
    SortPipe,
    TruncateMiddle,

    // Tabs
    TabDocumentsComponent,
    TabUsersComponent,
    TabReportingComponent,
    TabFaqComponent,
    TabAnswersComponent,
    TabChartsComponent,

    // Modals
    UploadDocumentsModal,
    CreateQuestionModal,
    AssignQuestionModal,
    RoomInviteModal,
    NewFolderModal,
    UsersModal,
    RoomSealModal,
    ChangePasswordModal,
    TwoFactorModal,
    RoomCreateModal,
    ArchivingModal,
    PrivacyPolicyModal,
    TermsOfServiceModal,
    AcceptParticipationModal,
    DeclineParticipationModal,
    ChildActivitiesModal,
    DocumentViewsModal,
    IncludedModal,
    SupportModal,
    FoldersTreeModal,
    NewPartyModal,
    RedeemCouponModal,

    // Components
    RoomCardComponent,
    FilesDropZoneComponent,
    FolderBreadcrumbComponent,
    MainSidebarComponent,
    TrDocumentComponent,
    TrFolderComponent,
    TrUserComponent,
    TrActivityComponent,
    PartyCompComponent,
    FormLabelComponent,
    AlwaysAutofocusComponent,
    PageLoaderComponent,
    FireInputComponent,
    FireSelectComponent,
    FireSimpleSelectComponent,
    FireCheckboxComponent,
    FireLineChartComponent,
    FirePieChartComponent,
    RoomRulesComponent,
    RoomCreateProgressComponent,
    RoomCreateSuccessComponent,
    PaginationComponent,
    DocumentSearchComponent,
    RoomRulesRuComponent,
    RoomRulesPtComponent,
    RoomRulesDeComponent,
    RoomRulesEsComponent,
    RoomRulesEnComponent,
    RoomRulesNlComponent,
    RoomRulesFrComponent,
    FoldersTreeItemComponent,
    NavNotificationsComponent
  ],

  entryComponents: [
    // Modals
    UploadDocumentsModal,
    CreateQuestionModal,
    AssignQuestionModal,
    RoomInviteModal,
    NewFolderModal,
    UsersModal,
    RoomSealModal,
    IncludedModal,
    ChangePasswordModal,
    TwoFactorModal,
    RoomCreateModal,
    ArchivingModal,
    PrivacyPolicyModal,
    TermsOfServiceModal,
    AcceptParticipationModal,
    DeclineParticipationModal,
    RoomRulesComponent,
    ChildActivitiesModal,
    DocumentViewsModal,
    SupportModal,
    FoldersTreeModal,
    NewPartyModal,
    RedeemCouponModal
  ],

  imports: [
    NgxQRCodeModule,
    BrowserModule,
    RouterModule,
    HttpModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    IqSelect2Module,
    FileDropModule,
    MomentModule,
    CreditCardDirectivesModule,
    AngularMultiSelectModule,
    ClickOutsideModule,
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    AngularTokenModule.forRoot({
      apiBase: environment.basePath
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }})
  ],
  providers: [
    AngularTokenModule,
    LoginRouteGuard,
    AppService,
    CommentService,
    ApiService,
    UserService,
    TitleService,
    SelectService,
    CountryService,
    StateService,
    RoomService,
    ContractService,
    QuestionService,
    DocumentService,
    FolderService,
    ParticipationService,
    RoomActivityService,
    RoomNotificationService,
    RoomPartyService,
    RoomExportService,
    RoomChartService,
    FileService,
    DataService,
    ValidationService,
    CouponService,
    PlanService,
    SupportService,
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
    { provide: LOCALE_ID, useValue: 'nl-BE' },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
