import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'fs-room-rules',
  templateUrl: './room-rules.component.html',
})
export class RoomRulesComponent implements OnInit {
  @Output() onProceed: EventEmitter<any> = new EventEmitter();

  public read: Boolean = false;
  public language: String;

  constructor(
    public bsModalRef: BsModalRef
  ) {
  }

  ngOnInit() {
    this.language = localStorage.getItem('language');
  }

  cancel() {
    this.bsModalRef.hide();
  }

  proceed() {
    this.onProceed.emit(this.read);
  }
}
