import { Component, OnInit, NgZone, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { Validators, FormGroup, FormControl, FormBuilder, FormArray } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
// import { UploadEvent, UploadFile, FileSystemFileEntry } from 'ngx-file-drop';

import { ContractService } from '../../../services/contract.service';
import { DataService } from '../../../services/data.service';
import { SelectService } from '../../../services/select.service';
import { PlanService } from '../../../services/plan.service';

import { RedeemCouponModal } from '../../../modals/redeem-coupon/redeem-coupon.modal';
import { Plan } from '../../../models/plan';

// import { Document } from '../../../models/document';

@Component({
  selector: 'fs-create-binder',
  templateUrl: './create-binder.component.html'
})
export class CreateBinderComponent implements OnInit {
  @Input() plans: Plan[];

  public form: FormGroup;
  public couponModalRef: any;
  public showValidations: boolean;
  public currencyModel: string;
  public currentPlan: Plan;

  // public files: UploadFile[] = [];
  // public isUploading: boolean;
  // public isPDF: boolean;

  constructor(
    private formBuilder: FormBuilder,
    public selectService: SelectService,
    public dataService: DataService,
    public contractService: ContractService,
    public modalService: BsModalService,
    private router: Router,
    // private _ngZone: NgZone
  ) {}

  ngOnInit() {
    this.createForm();

    this.currencyModel = this.dataService.getCurrency();

    if (this.dataService.binderFormData) {
      this.form.patchValue(this.dataService.binderFormData);
    }
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.minLength(2)]),
      company_name: new FormControl('', [Validators.required, Validators.minLength(1)]),
      region: new FormControl('', Validators.required),
      formule: new FormControl('', Validators.required),
      coupon_token: new FormControl('')
    });

    this.showValidations = false;
    // this.isUploading = false;
    // this.isPDF = true;
  }

  public selectRegion(region) {
    this.form.controls['region'].setValue(region);
  }

  public selectPlan(plan: Plan) {
    this.currentPlan = plan;
    this.form.controls['formule'].setValue(plan.id);
    this.dataService.selectedPlan = plan;
  }

  public formuleClass(formule) {
    if(!this.form.controls['formule'].value) {
      return ;
    } else if (this.form.controls['formule'].value==formule) {
      return 'selected';
    } else {
      return 'not-selected';
    }
  }

  public onSubmit(event) {
    event.preventDefault();

    this.showValidations = false;
    if (!this.form.valid) {
      this.showValidations = true;
    } else {
      this.showValidations = false;
      this.gotoNextStep();
    }
  }

  public gotoNextStep() {
    this.dataService.binderFormData = this.form.value;

    if(this.dataService.hasCoupon()) {
      this.router.navigateByUrl('order-details');
    } else {
      this.router.navigateByUrl('payment');
    }
  }

  openCouponModal() {
    this.couponModalRef = this.modalService.show(RedeemCouponModal, {
      class: `modal-md`
    });

    this.couponModalRef.content.validated.subscribe(token => {
      this.form.controls['coupon_token'].setValue(token);
      this.dataService.binderFormData = this.form.value;
      this.selectPlan(this.plans[0]);
    });
  }

  // public checkPdfType(fileEntry) {
  //   const document = new Document({
  //     name: fileEntry.name
  //   });

  //   return document.getExtention() === 'pdf';
  // }

  // public fileSelected(event: any) {
  //   const file = event.srcElement.files[0];
  //   this.fileProcessing(file);
  // }

  // public fileDropped(event: UploadEvent) {
  //   const file = event.files[0];
  //   const fileEntry = file.fileEntry as FileSystemFileEntry;

  //   fileEntry.file(fileInfo => {
  //     this._ngZone.run(() => {
  //       this.fileProcessing(fileInfo);
  //     });
  //   });
  // }

  // fileProcessing(file) {
  //   if (this.checkPdfType(file)) {
  //     this.isPDF = true;
  //     this.upload(file);
  //   } else {
  //     this.isPDF = false;
  //     this.form.controls['params']['controls']['token'].reset();
  //   }
  // }

  // public upload(file) {
  //   this.isUploading = true;
  //   this.contractService.upload(file).subscribe(result => {
  //     this.isUploading = false;
  //     if (result['token']) {
  //       this.form.controls['params']['controls']['token'].patchValue(result['token']);
  //     }
  //   });
  // }
}
