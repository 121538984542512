import { Component } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators, FormBuilder } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Observable } from 'rxjs';

import { FolderService } from '../../services/folder.service';

import { Folder } from '../../models/folder';

@Component({
  selector: 'new-folder-modal',
  templateUrl: './new-folder.modal.html',
})
export class NewFolderModal {
  public roomId: number;
  public basePath: string;
  public existingFolder: Folder;

  public currentObserver: any;
  public form: FormGroup;
  public showValidations: boolean;
  public isStarted: boolean;
  public isCompleted: boolean;

  public errors: any[];
  public warnings: any[];

  constructor(
    public bsModalRef: BsModalRef,
    public formBuilder: FormBuilder,
    public folderService: FolderService
  ) {
    this.setupForm();
  }

  public process(): Observable<any> {

    if(!this.roomId) console.error("new-folder.modal: roomId is expected!");
    if(!this.basePath) console.error("new-folder.modal: basePath is expected!");

    return Observable.create(observer => {
      this.currentObserver = observer;
      this.setupForm();
    });
  }

  private setupForm() {
    const builderOptions = {};

    // required
    builderOptions['name'] = new FormControl('', Validators.required);

    this.form = this.formBuilder.group(builderOptions);
    this.showValidations = false;
    this.isStarted = false;
    this.isCompleted = false;
  }

  onSubmit() {
    this.showValidations = false;

    if (!this.form.valid) {
      this.showValidations = true;
    } else {
      this.showValidations = false;
      this.createFolder();
    }
  }

  private createFolder() {
    this.isStarted = true;

    this.folderService.post({
      roomId: this.roomId,
      path: this.basePath,
      formData: this.form.value
    }).subscribe(response => {
      this.isCompleted = true;
      this.currentObserver.next();
      this.closeModal();
    });
  }

  public closeModal() {
    this.bsModalRef.hide();
  }
}
