import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Country } from '../models/country';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CountryService extends ApiService {

  constructor(
    public http: HttpClient,
    public router: Router
  ) {
    super(http, router);
  }

  get() {
    return Observable.create(observer => {
      this.http.get(this.fsBasePath + '/countries')
        .map(results => {
          return this.mapCountries(results);
        })
        .subscribe(res => {
          observer.next(res);
        },
        err => {
          observer.error(err);
        });
    });
  }

  getById(id): Observable<any> {
    const path = `${this.fsBasePath}/countries/${id}`;
    return Observable.create(observer => {
      this.authenticatedGet(path)
        .map(results => {
          return this.mapCountry(results);
        })
        .subscribe(result => {
          observer.next(result);
        });
    });
  }

  private mapCountries(results) {
    const countries = [];
    for (const result of results) {
        countries.push(this.mapCountry(result));
    }
    return countries;
  }

  private mapCountry(item) {
    return new Country(item);
  }
}
