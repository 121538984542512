export class Folder {
  public id: number;
  public name: string;
  public created_at: Date;
  public updated_at: Date;
  public fullPathHash: any;

  constructor(item) {
    if (!item) {
      return;
    }

    this.id = item.id;
    this.name = item.name;
    this.fullPathHash = item.full_path_hash;

    if (item.created_at) {
      this.created_at = new Date(item.created_at);
    }

    if (item.updated_at) {
      this.updated_at = new Date(item.updated_at);
    }
  }


  getFullPath(hash?) {
    if(!this.fullPathHash && !hash) return;

    let usedHash = (hash) ? hash : this.fullPathHash;
    let path = usedHash.name;

    if(usedHash.parent) {
      let parentPath = this.getFullPath(usedHash.parent);

      if(parentPath) {
        path = this.getFullPath(usedHash.parent) + usedHash.name;
      } else {
        path = usedHash.name;
      }
    }

    return path + '/';
  }
}
