import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { DocumentService } from '../../../../services/document.service';
import { FolderService } from '../../../../services/folder.service';
import { FileService } from '../../../../services/file.service';

import { Room } from '../../../../models/room';
import { Document } from '../../../../models/document';
import { Folder } from '../../../../models/folder';

import { NewFolderModal } from '../../../../modals/new-folder/new-folder.modal';
import { UploadDocumentsModal } from '../../../../modals/upload-documents/upload-documents.modal';
import { RoomSealModal } from '../../../../modals/room-seal/room-seal.modal';

import { timer, combineLatest } from 'rxjs';
import { Params, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'room-tab-documents',
  templateUrl: './tab-documents.component.html'
})
export class TabDocumentsComponent implements OnInit, OnDestroy {
  public documents: Document[];
  public folders: Folder[];
  public activeFolder: Folder;
  public isLoading: boolean;
  public newFolderModalRef: any;
  public uploadModalRef: any;
  public sealModal: any;
  public searchingDocument: any;

  private _active: boolean;
  private _routeSub: any;

  @Input() room: Room;

  @Output() folderChange: EventEmitter<Folder> = new EventEmitter<Folder>();

  @Input() set active(value: boolean) {
    this._active = value;

    if (this._active) {
      this.update();
    }
  }

  get active(): boolean {
    return this._active;
  }

  constructor(
    public documentService: DocumentService,
    public folderService: FolderService,
    public modalService: BsModalService,
    public fileService: FileService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    
  }

  update() {
    setTimeout(() => {
      if(this.activeFolder) {
        this.openFolder(null, this.activeFolder.id, true); 
      } else {
        this.openFolderInit();
      }
    }, 750);
  }

  openFolderInit(event?) {
    if(event) event.preventDefault();

    this._routeSub = this.activatedRoute.queryParams.subscribe((queryParams: Params) => {
      if(queryParams.hasOwnProperty('d')) {
        this.openFolder(null, queryParams.d);
      } else {
        this.openFolder(null, this.room.folder_id);
      }
    });

    
  }

  openFolder(event, folderId, hideLoading?) {
    if(event) event.preventDefault();

    if(!hideLoading) this.isLoading = true;

    
    combineLatest(
      this.folderService.getById(folderId),
      this.documentService.get({'folder_id': folderId}),
      this.folderService.getChildren(folderId)
    ).subscribe(response => {
      // this.folderService.getById
      const folderByIdResp = response[0];
      this.activeFolder = folderByIdResp.folder;
      this.folderChange.emit(this.activeFolder);

      // this.documentService.get
      const documentsResp = response[1];
      this.documents = documentsResp.documents;

      // this.folderService.getChildren
      const foldersResp = response[2];
      this.folders = foldersResp.folders;

      this.isLoading = false;
    });
  }

  archiveFolder(event) {
    event.preventDefault();
  }

  archiveFile(event) {
    event.preventDefault();
  }

  openFileFaq(event) {
    event.preventDefault();
  }

  newFolderModal() {
    this.newFolderModalRef = this.modalService.show(NewFolderModal, {
      class: `modal-md`
    });

    this.newFolderModalRef.content.roomId = this.room.id;
    this.newFolderModalRef.content.basePath = this.activeFolder.getFullPath();

    this.newFolderModalRef.content.process().subscribe(() => {
      this.update();
    });
  }

  onBreadcrumbClicked(event) {
    this.openFolder(null, event.id);
  }

  onFileInput(event) {
    const files: any = event.target.files;

    this.fileService.getWebkitFilesInfo(files).subscribe(fireUploadFiles => {
      this.uploadModalRef = this.modalService.show(UploadDocumentsModal, {
        class: `modal-lg`,
        backdrop: 'static'
      });

      this.uploadModalRef.content.fireUploadFiles = fireUploadFiles;
      this.uploadModalRef.content.roomId = this.room.id;
      this.uploadModalRef.content.basePath = this.activeFolder.getFullPath();

      this.uploadModalRef.content.process().subscribe(() => {
        this.update();
      });
    });
  }

  documentsTrackBy(index, item) {
    return item.id;
  }

  foldersTrackBy(index, item) {
    return item.id;
  }

  ngOnDestroy() {
    if(this.newFolderModalRef) this.newFolderModalRef.hide();
  }

  searchFileSelected($event) {
    const id = $event['id'];
    this.documentService.getUrl(id).subscribe(response => {
      setTimeout(() => {
        window.open(response.file.url);
      },100);
    });
  }

  openSealModal() {
    this.sealModal = this.modalService.show(RoomSealModal, {
      keyboard: false,
      ignoreBackdropClick: true,
      class: `modal-md`
    });

    this.sealModal.content.setRoom(this.room);
    this.sealModal.content.fetchParticipations();
  }

}
