import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, HostBinding } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { Room } from '../../../../../models/room';
import { Folder } from '../../../../../models/folder';

import { FolderService } from '../../../../../services/folder.service';

import { ArchivingModal } from '../../../../../modals/archiving/archiving.modal';
import { FoldersTreeModal } from '../../../../../modals/folders-tree/folders-tree.modal';

@Component({
  selector: 'tr-folder',
  templateUrl: './tr-folder.component.html',
  styles: [`
    :host {
      display: table-row;
    }
  `]
})
export class TrFolderComponent {
  @Input() folder: Folder;
  @Input() room: Room;

  @Output() folderClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() archived: EventEmitter<any> = new EventEmitter<any>();
  @Output() moved: EventEmitter<any> = new EventEmitter<any>();

  public renameModeOn:boolean;
  public renameValue:string;
  public isRenaming:boolean;

  public archivingModalRef: any;
  public foldersTreeModalRef: any;

  constructor(
    public folderService: FolderService,
    public modalService: BsModalService
  ) {}

  openFolder(event, folderId) {
    if(event) event.preventDefault();
    this.folderClick.emit({id: folderId});
  }

  optionRename(event) {
    event.preventDefault();
    this.renameModeOn = true;
    this.renameValue = this.folder.name;
  }

  optionArchive(event) {
    event.preventDefault();

    this.archivingModalRef = this.modalService.show(ArchivingModal, {
      class: `modal-md`
    });

    this.archivingModalRef.content.contextType = 'folder';
    this.archivingModalRef.content.context = this.folder;

    this.archivingModalRef.content.process().subscribe(() => {
      this.archived.emit({});
    });
  }

  optionMove(event) {
    event.preventDefault();

    this.foldersTreeModalRef = this.modalService.show(FoldersTreeModal, {
      class: `modal-md`
    });

    this.foldersTreeModalRef.content.folderId = this.room.folder_id;
    this.foldersTreeModalRef.content.activeFolderId = this.folder.id;
    this.foldersTreeModalRef.content.contextType = 'folder';
    this.foldersTreeModalRef.content.context = this.folder;

    this.foldersTreeModalRef.content.process().subscribe(() => {
      this.moved.emit({});
    });
  }

  handleRename(event) {
    event.preventDefault();
    event.stopPropagation();

    if(this.isRenaming) return;

    if(!this.renameValue || this.renameValue==this.folder.name) {
      this.cancelRename();
      return;
    }

    this.isRenaming = true;
    this.folderService.rename(this.folder.id, this.renameValue).subscribe(response => {
      this.folder.name = this.renameValue
      this.cancelRename();
      this.isRenaming = false;
    }, error => {
      this.isRenaming = false;
    });
  }

  cancelRename(event?) {
    if(event) {
      event.preventDefault();
      event.stopPropagation();
    }
    
    this.renameModeOn = false;
    this.renameValue = '';
  }

  canMakeChanges() {
    if(this.room.current_participation.role=='admin' 
      || this.room.current_participation.role=='editor') {
      return true;
    } else {
      return false;
    }
  }
}
