import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'fs-pagination',
  templateUrl: './pagination.component.html'
})
export class PaginationComponent implements OnInit {
  @Input() current: number;
  @Input() total_pages: number;
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {

  }

  gotoPage(event, page) {
    event.preventDefault();
    this.pageChange.emit(page);
    this.current = page;
    // window.scroll(0, 0);
  }

  preventDefault(event) {
    event.preventDefault();
  }
}
