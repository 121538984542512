import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Observable } from 'rxjs';

import { FolderService } from '../../services/folder.service';
import { DocumentService } from '../../services/document.service';
import { ParticipationService } from '../../services/participation.service';
import { RoomPartyService } from '../../services/room-party.service';

@Component({
  selector: 'archiving-modal',
  templateUrl: './archiving.modal.html',
})
export class ArchivingModal {
  public currentObserver: any;

  public context: any;
  public contextType: string;
  public unArchive: boolean;

  constructor(
    public bsModalRef: BsModalRef,
    public folderService: FolderService,
    public documentService: DocumentService,
    public participationService: ParticipationService,
    public partyService: RoomPartyService
  ) {
  }

  process(): Observable<any> {
    return Observable.create(observer => {
      this.currentObserver = observer;
    });
  }

  doArchive() {
    // Archiving a Folder
    if (this.contextType === 'folder') {
      this.folderService.archive(this.context.id).subscribe(response => {
        this.currentObserver.next(response);
      });
    }

    // Archiving a Document
    if (this.contextType === 'document') {
      this.documentService.archive(this.context.id).subscribe(response => {
        this.currentObserver.next(response);
      });
    }

    // Archiving / Unarchiving a Room Participation
    if (this.contextType === 'room-participation') {
      if(this.unArchive) {
        this.participationService.unarchive(this.context.id).subscribe(response => {
          this.currentObserver.next(response.participation);
        });
      } else {
        this.participationService.archive(this.context.id).subscribe(response => {
          this.currentObserver.next(response.participation);
        });
      }
    }

    // Archiving / Unarchiving a Party
    if(this.contextType=='party') {
      if(this.unArchive) {
        this.partyService.unArchive(this.context.id).subscribe(response => {
          this.currentObserver.next(response.party);
        });
      } else {
        this.partyService.archive(this.context.id).subscribe(response => {
          this.currentObserver.next(response.party);
        });
      }
    }

    this.bsModalRef.hide();
  }

  closeModal() {
    this.bsModalRef.hide();
  }
}
