import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Component, ViewChild } from '@angular/core';
import { ModalDirective, BsModalService } from 'ngx-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { comparePassword } from '../../validators/password-validation';


@Component({
  selector: 'change-password-modal',
  templateUrl: './change-password.modal.html',
})
export class ChangePasswordModal {
  public errors: Array<string> = [];
  public changePasswordForm: FormGroup;
  public showValidations: boolean;
  public success: boolean;

  public constructor(
    public bsModalRef: BsModalRef,
    private userService: UserService,
    public modalService: BsModalService
  ) { 
    this.createForm();
  }

  ngOnInit() {
    
  }

  createForm(): any {
    this.changePasswordForm = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      password_confirmation: new FormControl('', Validators.required)
    },
      comparePassword
    );
  }

  changePassword() {
    if (!this.changePasswordForm.valid) {
      this.showValidations = true;
    } else {
      this.showValidations = false;
      const data = this.changePasswordForm.value;

      this.userService.updatePassword( data['password'], data['password_confirmation']).subscribe(response => {
        this.success = true;
      });
    }
  }

  public closeModal() {
    this.bsModalRef.hide();
  }

  ngOnDestroy() {
    
  }
}
