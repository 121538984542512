import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { TabDocumentsComponent } from './tab-documents/tab-documents.component';
import { TabUsersComponent } from './tab-users/tab-users.component';
import { TabReportingComponent } from './tab-reporting/tab-reporting.component';

import { TitleService } from '../../../services/title.service';
import { RoomService } from '../../../services/room.service';

import { Room } from '../../../models/room';
import { Folder } from '../../../models/folder';
import { AppService } from '../../../services/app.service';
import { RoomNotificationService } from '../../../services/room-notification.service';

@Component({
  selector: 'app-rooms-detail',
  templateUrl: './rooms-detail.component.html'
})
export class RoomsDetailComponent implements OnInit, OnDestroy {
  public sub: any;
  public id: number;
  public room: Room;
  public currentParticipation: any;
  public activeTab: string;
  public activeFolder: Folder;
  public notInterval: any;

  @ViewChild(TabDocumentsComponent)
  private tabDocuments: TabDocumentsComponent;

  @ViewChild(TabUsersComponent)
  private tabUsers: TabUsersComponent;

  @ViewChild(TabReportingComponent)
  private tabReporting: TabReportingComponent;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public titleService: TitleService,
    public roomService: RoomService,
    public notificationService: RoomNotificationService,
    public appService: AppService
  ) {}

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      if (String(this.id) !== String(params.id)) {
        this.id = +params.id;
        this.room = null;
        this.currentParticipation = null;
        this.loadRoom();
      }

      if (String(this.activateTab) !== String(params.tab)) {
        this.activeTab = params.tab;
      }
    });

    this.notInterval = setInterval(() => this.synctNoti(), 15000);
  }

  loadRoom() {
    this.roomService.getById(this.id).subscribe(response => {
      this.room = response.room;
      this.setTitle();
      this.currentParticipation = this.room.current_participation;

      if (!this.activeTab) {
        this.activateTab('binder');
      }
    });
  }

  goBack(event) {
    event.preventDefault();
    const url = this.router.url;

    if (url.indexOf('/answers?q=') > - 1) {
      this.router.navigate(['/rooms', this.id, 'questions']);
    } else {
      this.router.navigate(['/rooms']);
    }

    this.appService.mobileMenuActive = false;
  }

  activateTab(tabName: string) {
    this.router.navigate(['rooms', this.id, tabName]);
    this.appService.mobileMenuActive = false;
    this.setTitle();
  }

  uploadComplete() {
    this.tabDocuments.update();
  }

  onFolderChange(folder) {
    this.activeFolder = folder;
  }

  setTitle() {
    this.titleService.setTitle(`${this.room.name}`); // Not working as expected #TODO
  }

  toggleNotifications() {
    this.appService.notificationsOpen =! this.appService.notificationsOpen;

    if (this.appService.notificationsOpen) {
      this.synctNoti();
    }
  }

  synctNoti() {
    if (!this.currentParticipation) {
      return;
    }

    this.notificationService.getUnreadCount(this.currentParticipation.id).subscribe(resp => {
      this.room.current_participation.unread_notifications = resp.count;
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    clearInterval(this.notInterval);
  }
}
