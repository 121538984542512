import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularTokenService } from 'angular-token';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthUser } from '../models/auth-user';
import { ApiService } from './api.service';

@Injectable()
export class UserService extends ApiService {

  constructor(
    public http: HttpClient,
    public router: Router,
    private tokenService: AngularTokenService
  ) {
    super(http, router);
  }

  clearUsersLocalStorage() {
    localStorage.removeItem('current_user');
    localStorage.removeItem('auth_headers');
  }

  /**
   * Create a new user.
   * @param user
   */
  create(authUser: any)  {
    authUser.confirm_success_url = this.frontendPath + '/confirmed';
    return this.http.post(this.basePath + '/auth', authUser);
  }

  /**
   * Update an existing user.
   * @param user
   */
  update(authUser: AuthUser) {
    return this.authenticatedPut('/auth', authUser);
  }

  checkOTPEnabled(email: string, password: string) {
    return Observable.create(observer => {
      const data = {
        email: email,
        password: password
      };

      this.http.post(this.basePath + '/auth/otp_enabled', data)
        .subscribe(res => {
          observer.next(res['otp_enabled']);
        },
        err => {
          observer.error(err);
        });
    });
  }

  login(email: string, password: string, otp: string) {
    return Observable.create(observer => {
      const data = {
        login: email,
        password: password,
      };

      const additional_data = {
        otp: otp
      };

      this.tokenService.signIn(data, additional_data).subscribe(
        res => {
          this.storeCurrentUser(res);
          observer.next(true);
        },
        error => {
          observer.error(error.error.errors);
        }
      );
    });
  }

  forgotPassword(email: string) {
    const data = { email: email, redirect_url: this.frontendPath + '/password/edit' };
    return this.http.post(this.basePath + '/auth/password', data)
      .pipe(map(response => response));
  }

  checkDuplicateEmail(email: string) {
    return this.http.get(this.basePath + '/auth/email?email=' + email)
      .pipe(map(response => response));
  }

  storeCurrentUser(current_user: any): void {
    localStorage.setItem('current_user', JSON.stringify(current_user));
  }

  getCurrentUser(): any {
    let cu = JSON.parse(localStorage.getItem('current_user'));

    if (cu && cu['data']) {
      cu = cu['data'];
    }

    return cu;
  }

  getCurrentUserName(): string {
    const cu: any = this.getCurrentUser();
    if (!cu) {
      return '';
    }

    const names = [];

    if (cu['first_name']) {
      names.push(cu['first_name']);
    }
    if (cu['last_name']) {
      names.push(cu['last_name']);
    }

    return names.join(' ');
  }

  getAuthenticatedUser(): Observable<AuthUser> {
    return Observable.create(observer => {
      this.authenticatedGet('/auth').pipe(map(res => res as AuthUser)).subscribe(user => {
        observer.next(user);
      });
    });
  }

  isLoggedIn(): Boolean {
    return this.tokenService.userSignedIn();
  }

  isLoggedInOnServer(): Observable<Boolean> {
    return Observable.create(observer => {
      this.authenticatedGet(this.basePath + '/auth/validate_token').subscribe(result => {
        observer.next(result);
      }, error => {
        observer.next(error);
      });
    });
  }

  logout(): Observable<Boolean> {
    return Observable.create(observer => {
      this.tokenService.signOut().subscribe(
        res =>      observer.next(true),
        error =>    error.error.errors
      );
    });
  }

  /**
   * Reset the password for the currently logged in user.
   */
  resetPassword(password: string, confirmPassword: string): Observable<Boolean> {
    return Observable.create(observer => {
      this.tokenService.updatePassword({
          password:             password,
          passwordConfirmation: confirmPassword,
          passwordCurrent:      null,
        }).subscribe(
          res => {
            observer.next(true);
          },
          err => {
            observer.error(err.error.errors.full_messages);
          }
        );
    });
  }

  /**
   * Reset the password for the currently logged in user.
   */
  updatePassword(password: String, confirmPassword: String): Observable<Boolean> {
    return Observable.create(observer => {
      const data = {
        password: password,
        password_confirmation: confirmPassword
      };

      this.authenticatedPut(this.basePath + '/auth/password', data).subscribe(res => {
        observer.next(true);
      }, err => {
        observer.error(err.error.errors.full_messages);
      });
    });
  }

  acceptInvitation(password: String, confirmPassword: String, accepted_policies: Boolean, first_name: String, last_name: String,params: Object): Observable<Boolean> {
    return Observable.create(observer => {
      const data = {
        accepted_policies: accepted_policies,
        first_name: first_name,
        last_name: last_name,
        password: password,
        password_confirmation: confirmPassword,
        invitation_token: params['invitation_token']
      };

      // var language = localStorage.getItem('language');
      // let options = new RequestOptions({ headers: new Headers({'language': language}) });

      this.http.put(this.basePath + '/auth/invitation', data).subscribe(res => {
        observer.next(true);
      }, err => {
        observer.error(err.error.errors.full_messages);
      });
    });
  }

  generateQR(): Observable<Boolean> {
    const path = `${this.fsBasePath}/users/generate_google_auth_secret`;

    return Observable.create(observer => {
      this.authenticatedGet(path).subscribe(response => {
        observer.next(response.body);
      }, err => {
        observer.error(err.error.errors.full_messages);
      });
    });
  }

  addGoogleAuth(secret, otp): Observable<Boolean> {
    const path = `${this.fsBasePath}/users/add_google_auth`;

    const data = {
      secret: secret,
      otp: otp,
    };

    return Observable.create(observer => {
      this.authenticatedPut(path, data).subscribe(result => {
        observer.next(result);
      }, err => {
        observer.error(err);
      });
    });
  }

  removeGoogleAuth(otp): Observable<Boolean> {
    const path = `${this.fsBasePath}/users/remove_google_auth`;

    const data = {
      otp: otp,
    };

    return Observable.create(observer => {
      this.authenticatedPut(path, data).subscribe(result => {
        observer.next(result);
      }, err => {
        observer.error(err.error.errors.full_messages);
      });
    });
  }
}
