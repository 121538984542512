import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Component, ViewChild, Output, EventEmitter, Input, OnInit, AfterViewInit, AfterContentChecked } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { Router } from '@angular/router';
import { Participation } from '../../models/participation';
import { FormGroup, NgForm, NgModel, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ParticipationService } from '../../services/participation.service';
import { RoomService } from '../../services/room.service';
import { Room } from '../../models/room';


@Component({
  selector: 'fs-room-seal',
  templateUrl: './room-seal.modal.html',
})
export class RoomSealModal implements OnInit {
  public state: String = '';
  public receiversDropdownList = [];
  public selectedReceivers = [];
  public receiversDropdownSettings = {};
  public participations: Participation[];
  public isStarted: boolean;
  public sealForm: FormGroup;
  public room: Room;
  public success: boolean;
  public confirmRoomName: String = '';

  public constructor(
    public bsModalRef: BsModalRef,
    public router: Router,
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public participationService: ParticipationService,
    public roomService: RoomService
  ) {}

  ngOnInit() {
    this.success = false;
    this.setupForm();
  }

  setRoom(room) {
    this.room = room;
  }


  closeModal() {
    this.bsModalRef.hide();
  }

  canSealRoom() {
    return (this.selectedReceivers.length > 0) && (this.confirmRoomName === this.room.name);
  }

  setupForm() {
    this.receiversDropdownSettings = {
      singleSelection: false,
      text: '',
      selectAllText: this.translate.instant('general.select_all'),
      unSelectAllText: this.translate.instant('general.unselect_all'),
      searchPlaceholderText: this.translate.instant('general.search'),
      enableSearchFilter: true,
      groupBy: 'partyName'
    };
  }

  private sealRoom() {
    const formValues = {'assignees': []};
    formValues['assignees'] = this.selectedReceivers.map(selectedReceiver => { return {user_id: selectedReceiver.id}});

    if (this.selectedReceivers.length === 0) {
      return;
    }
    this.success = true;

    this.roomService.seal(this.room.id, formValues).subscribe(response => {
      this.success = true;
    });
  }

  private fetchParticipations() {
    this.participationService.getByRoom(this.room.id).subscribe(response => {
      this.participations = response.participations;
      this.receiversDropdownList = this.participations.map(participation => {
        let obj:any =  {
          id: participation.user.id,
          itemName: participation.user.getFullName(),
        };

        if(participation.party && participation.party.company_name) {
          obj.partyName = participation.party.company_name
        }

        return obj;
      });
    });
  }

  public goToOverview() {
    this.bsModalRef.hide();
    this.router.navigate(['rooms']);
  }
}
