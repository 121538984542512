import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';

import { UserParticipation } from '../../models/user-participation';

@Component({
  selector: 'folder-breadcrumb',
  templateUrl: './folder-breadcrumb.component.html',
})
export class FolderBreadcrumbComponent {
  public breadcrumbValue: any;
  public breadArray = [];
  public prefferedNesting = 2;

  @Output() breadcrumbClick: EventEmitter<any> = new EventEmitter<any>();

  @Input() rootClickable: boolean = false;
  @Input() isActiveFolder: boolean = false;

  @Input() index: number = 0;

  @Input()
  set breadcrumb(val) {
    this.breadcrumbValue = val;
    this.breadArray = this.loopParents(val, []);
  }

  constructor() {}
  ngOnInit() {}

  folderClicked(event, folderId) {
    if(event) event.preventDefault();
    if(folderId) this.breadcrumbClick.emit({id: folderId});
  }

  loopParents(value, array) {
    array.unshift({id: value.id, name: value.name});
    if(value.parent) {
      this.loopParents(value.parent, array);
    }
    return array;
  }

  folderize() {
    return this.breadArray.length>(this.getMaxNesting()+1);
  }

  lastBreadItems() {
    if(this.folderize()) {
      return this.breadArray.slice(Math.max(this.breadArray.length - this.getMaxNesting()))
    } else {
      return this.breadArray; 
    }
  }

  firstBreadItems() {
    if(this.folderize()) {
      return this.breadArray.slice(0, this.breadArray.length-this.getMaxNesting()).reverse();
    } else {
      return []; 
    }
  }

  getMaxNesting() {
    if(window.innerWidth<767) {
      return 1;
    } else {
      return this.prefferedNesting;
    }
  }

}
