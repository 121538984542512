import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { UserService } from '../../../services/user.service';
import { Router } from '@angular/router';


@Component({
  selector: 'fs-reset-password-request',
  templateUrl: './reset-password-request.component.html'
})
export class ResetPasswordRequestComponent {
  public errors: Array<string> = [];
  public resetForm: FormGroup;
  public success: Boolean = false;

  public showValidations: boolean;
  public isRequestingPwd: boolean;

  constructor(private userService: UserService, private router: Router) {
    this.createForm();
  }

  createForm(): any {
    this.resetForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    });
  }

  send() {
    if (!this.resetForm.valid) {
      this.showValidations = true;
    } else {
      this.isRequestingPwd = true;
      this.showValidations = false;
      const data = this.resetForm.value;
      this.userService.forgotPassword(data['email']).subscribe(
        response => {
          this.isRequestingPwd = false;
          this.success = true;
        },
        err => {
          this.isRequestingPwd = false;
          this.errors = err.error.errors;
        }
      );
    }
  }
}
