import { Component, Input, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap';
import { environment } from '../../../../environments/environment';
import { Plan } from '../../../models/plan';
import { DataService } from '../../../services/data.service';
import { SelectService } from '../../../services/select.service';

@Component({
  selector: 'fs-payment',
  templateUrl: './payment.component.html'
})
export class PaymentComponent implements OnInit {
  @Input() plans: Plan[];

  public creditCardForm: FormGroup;
  public billingForm: FormGroup;
  public showValidations: boolean;
  public isStarted: boolean;
  public isCompleted: boolean;
  public errors: Array<string> = [];

  constructor(
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    private dataService: DataService,
    public router: Router,
    public selectService: SelectService,
    public modalService: BsModalService,
    public _ngZone: NgZone,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.route.data.subscribe(data => {
      if (!this.dataService.binderFormData) {
        this.router.navigate(['create']);
      }
    });

    this.createCreditCardForm();
    this.createBillingForm();

    if (this.dataService.creditCardFormData) {
      this.creditCardForm.patchValue(this.dataService.creditCardFormData);
    }

    if (this.dataService.billingFormData) {
      this.billingForm.patchValue(this.dataService.billingFormData);
    }
  }

  private createCreditCardForm(): void {
    this.creditCardForm = this.formBuilder.group({
      name: ['', [Validators.required] ],
      number: ['', [Validators.required] ],
      expiration: ['', [Validators.required] ],
      cvc: ['', [Validators.required] ]
    });
  }

  private createBillingForm(): void {
    this.billingForm = this.formBuilder.group({
      company: ['1'],
      company_name: ['', [Validators.required]],
      vat_number: [''],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      line1: ['', [Validators.required]],
      line2: [''],
      zip_code: ['', [Validators.required]],
      city: ['', [Validators.required]],
      country_id: ['', [Validators.required]],
    });

    // add validation switcher
    this.billingForm.get('company').valueChanges.subscribe( (company: boolean) => {
      if (company) {
        this.requireControl(this.billingForm.get('company_name'));
        this.requireControl(this.billingForm.get('vat_number'));
      } else {
        this.unRequireControl(this.billingForm.get('company_name'));
        this.unRequireControl(this.billingForm.get('vat_number'));
      }
    });
  }

  public requireControl(control) {
    control.setValidators([Validators.required]);
    control.updateValueAndValidity();
  }

  public unRequireControl(control) {
    control.setValidators([]);
    control.reset();
    control.updateValueAndValidity();
  }

  public submit(event) {
    event.preventDefault();

    this.errors = [];
    this.showValidations = false;

    if (!this.creditCardForm.valid || !this.billingForm.valid) {
      this.showValidations = true;
      return;
    }

    this.processCreditCardToken();
  }

  private processCreditCardToken() {
    const ccValues = this.creditCardForm.value;

    this.creditCardForm.disable();
    this.billingForm.disable();

    const stripe_values = {};
    const expiration = ccValues['expiration'].split('/');
    stripe_values['exp_month'] = Number(expiration[0]);
    stripe_values['exp_year'] = Number(expiration[1]);
    stripe_values['name'] = ccValues['name'];
    stripe_values['number'] = ccValues['number'];
    stripe_values['cvc'] = ccValues['cvc'];

    (<any>window).Stripe.setPublishableKey(environment.stripeToken);
    (<any>window).Stripe.card.createToken(stripe_values, (status: number, response: any) => {
      this.creditCardForm.enable();
      this.billingForm.enable();

      this._ngZone.run(() => {
        if (status === 200) {
          this.dataService.stripeData = response;
          this.gotoNextStep();
        } else {
          let errTrans = 'stripe_err_';
          if (response.error && response.error.code) {
            if (['incorrect_number', 'invalid_number', 'incorrect_cvc', 'invalid_expiry_month', 'invalid_expiry_year', 'expired_card'].indexOf(response.error.code) > -1) {
              errTrans = String(errTrans + response.error.code);
            } else {
              errTrans = String(errTrans + 'general');
            }
          } else {
            errTrans = String(errTrans + 'general');
          }
          this.errors.push(this.translate.instant(String('wizard.' + errTrans)));
          console.log('ERROR-Payment-Stripe.card.createToken', response);

          // setErrors({'incorrect': true}); does nothing visually
          // this.creditCardForm.controls['number'].setErrors({'incorrect': true});
        }
      });
    });
  }

  public gotoNextStep() {
    this.dataService.creditCardFormData = this.creditCardForm.value;
    this.dataService.billingFormData = this.billingForm.value;
    this.router.navigateByUrl('order-details');
  }

  public goBack(event) {
    event.preventDefault();
    history.back();
  }
}
