import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { Room } from '../models/room';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class RoomService extends ApiService {

  constructor(
    public http: HttpClient,
    public router: Router
  ) {
    super(http, router);
  }

  getById(id, params?: any): Observable<any> {
    const path = `${this.fsBasePath}/rooms/${id}`;

    return Observable.create(observer => {
      this.authenticatedGet(path)
        .map(results => {
          return {'room': this.mapRoom(results['body']), 'headers': results['headers']};
        })
        .subscribe(results => {
          observer.next(results);
        });
    });
  }

  post(params: any): Observable<any> {
    const path = `${this.fsBasePath}/rooms`;
    return this.authenticatedPost(path, params);
  }

  seal(id, params: any): Observable<any> {
    const path = `${this.fsBasePath}/rooms/${id}/export`;

    return Observable.create(observer => {
      this.authenticatedPut(path, params)
        .subscribe(result => {
          observer.next(result['body']);
        });
    });
  }

  private mapRoom(item) {
    return new Room(item);
  }
}
