import { Component, TemplateRef, OnInit, OnDestroy, Input, Output, EventEmitter, forwardRef, OnChanges } from '@angular/core';
import { FormControl, ControlValueAccessor, AbstractControl, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

@Component({
  selector: 'fire-select',
  templateUrl: './fire-select.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FireSelectComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => FireSelectComponent), multi: true }
  ]
})
export class FireSelectComponent implements ControlValueAccessor, OnChanges {
  propagateChange:any = () => {};
  validateFn:any = () => {};
  

  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Output() onRemove: EventEmitter<any> = new EventEmitter();

  @Input() formControlName: string;
  @Input() defaultLabel: string;
  @Input() showValidation: boolean;
  @Input() selectConfig: any;
  @Input() selectedProvider: any;
  @Input() dataSourceProvider: any;
  @Input() clientMode: boolean;
  @Input() referenceMode: any;
  @Input() placeholder: string;

  public _control;
  public resetting: boolean;

  @Input()
  get control() {
    return this._control;
  }
  
  set control(val: any) {
    this.inputValue = null;
    this.customValue = null;
    
    this._control = val;

    this.resetting = true;
    setTimeout(() => {
      this.resetting = false;
    });
  }

  @Input('inputValue') _inputValue;


  customValue:any;

  constructor (

  ) {}

  get inputValue() {
    return this._inputValue;
  }
  
  set inputValue(val) {
    this._inputValue = val;
    this.propagateChange(val);
  }

  ngOnChanges(inputs) {}

  writeValue(value) {
    if (this.referenceMode === 'entity') {
      this.customValue = value;
    }

    if (this.selectedProvider && this.referenceMode !== 'entity') {
      this.selectedProvider([value]).subscribe((items: any[]) => {
        this.customValue = items[0];
      });
    }

    if (value) {
      this.inputValue = value;
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  validate(c: FormControl) {
    return this.validateFn(c);
  }

  iqOnSelect(event) {
    this.onSelect.emit(event);

    if(event.hasOwnProperty('id') && this.referenceMode !== 'entity') {
      this.inputValue = event.id;
    }

    if(event.hasOwnProperty('entity') && this.referenceMode === 'entity') {
      this.inputValue = event.entity;
    }
  }

  iqOnRemove(event) {
    this.onRemove.emit(event);
    this.inputValue = null;
  }
}
