import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'fs-room-rules-ru',
  templateUrl: './room-rules-ru.component.html',
})
export class RoomRulesRuComponent {
  constructor() {
  }
}
