import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Component, Input, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { RoomActivityService } from '../../services/room-activity.service';
import { RoomActivity } from '../../models/room-activity';
import { Document } from '../../models/document';

@Component({
  selector: 'fs-document-views',
  templateUrl: './document-views.modal.html',
})
export class DocumentViewsModal {
  @ViewChild(ModalDirective) modal: ModalDirective;
  @Input() room_id: number;
  @Input() document: Document;

  public isLoading = false;

  // pagination
  public items_per_page = 15;
  public total_pages = 0;
  public current_page = 1;
  public total_activities = 0;

  public childActivities: Array<RoomActivity>;

  public constructor(
    public bsModalRef: BsModalRef,
    public roomActivityService: RoomActivityService
  ) {}

  update() {
    this.isLoading = true;

    let filters = {
      "for_code": "document_viewed",
      "for_trackable_type": "Document",
      "for_trackable_id": this.document.id,
      "only_child_activities": true
    }

    this.roomActivityService.getByRoomId(this.room_id, filters, this.current_page, this.items_per_page).subscribe( response => {
      this.childActivities = response.roomActivities;
      this.isLoading = false;
      this.total_activities = response['headers'].get('Total');
      this.total_pages = Math.ceil((this.total_activities / this.items_per_page));
    });
  }

  public pageChange(page) {
    this.current_page = page;
    this.update();
  }

  public closeModal() {
    this.bsModalRef.hide();
  }
}
