
import { Chart } from 'chart.js';
import { Component, Input, ElementRef } from '@angular/core';

@Component({
  selector: 'fire-linechart',
  templateUrl: './fire-linechart.component.html'
})
export class FireLineChartComponent {
  constructor(private elementRef: ElementRef) {}
  chart = [];
  public _data;

  @Input()
  get data() {
    return this._data;
  }

  set data(data: any) {
    if (data) {
      this._data = data;
      this.setChart(data.keys, data.values);
    }
  }

  setChart(keys: string[], values: string[]) {
    const htmlRef = this.elementRef.nativeElement.querySelector('#canvas');
    if (htmlRef) {
      this.chart = new Chart(htmlRef, {
        type: 'line',
        data: {
          labels: keys,
          datasets: [
            {
              data: values,
              borderColor: '#3cba9f',
              fill: false
            }
          ]
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              display: true,
              ticks: {
                autoSkip: true,
                autoSkipPadding: 10
              }
            }],
            yAxes: [{
              display: true,
              ticks: {
                autoSkip: true
              }
            }],
          }
        }
      });
    }
  }
}
