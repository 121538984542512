import { User } from './user';

export class RoomActivity {
  public id: number;
  public code: string;
  public user: User;
  public trackable: any;
  public trackableType: string;
  public created_at: Date;
  public childrenCount: number;
  public old_status: string;
  public new_status: string;
  public fullName: string;

  constructor(item) {
    if (!item) {
      return;
    }

    this.id = item.id;
    this.code = item.code;
    this.user = item.user;
    this.trackable = item.trackable;
    this.trackableType = item.trackable_type;
    this.childrenCount = item.children_count;
    this.old_status = item.old_status;
    this.new_status = item.new_status;

    if (item.user) {
      this.user = new User(item.user);
      this.fullName = this._compileName();
    }

    if (item.created_at) {
      this.created_at = new Date(item.created_at);
    }
  }

  activitiesTrackBy(index, item) {
    return item.id;
  }

  private _compileName():string {
    if(this.user) {
      return this.user.getFullName();
    }
  }
}
