import { Injectable } from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import { Router } from '@angular/router';
import { AngularTokenService } from 'angular-token';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private _router: Router,
    private tokenService: AngularTokenService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).do((event: HttpEvent<any>) => {}, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        let request: String = '';
        if (err.url) {
          request = err.url.substr(err.url.lastIndexOf('/') + 1);
        }

        if (err.status === 401 && request !== 'loggedin') {
          this.tokenService.signOut().subscribe(
            res =>      {
              this._router.navigate(['/login']);
            }
          );
        }

        return err;
      }
    });
  }
}
