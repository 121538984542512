import { Component, OnInit, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'fs-users',
  templateUrl: './users.component.html'
})
export class UsersComponent implements OnInit {
  public sub: any;
  public id: number;
  public activeStep;

  @Output() loggedIn = new EventEmitter();

  constructor(
    public route: ActivatedRoute,
    public router: Router,
  ) {
    this.setDefaultStep();
  }

  ngOnInit() {
    this.sub = this.route.data.subscribe(data => {
      if (String(this.activeStep) !== String(data.step)) {
        this.activeStep = data.step;
      }

      if (!data.step) {
        this.setDefaultStep();
      }
    });
  }

  setDefaultStep() {
    let hasLoggedInBefore = localStorage['hasLoggedInBefore'];
    this.activeStep = (hasLoggedInBefore ? 'login' : 'register');
  }

  public bubbleLoggedin(event) {
    localStorage['hasLoggedInBefore'] = true;
    this.loggedIn.emit(null);
  }

  public stepChanged(event) {
    this.activeStep = event;
  }
}
