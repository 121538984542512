import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CountryService } from './country.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class SelectService {
  public configs: any = {};

  constructor (
    private _countryService: CountryService,
    private translate: TranslateService
  ) {

    // Country Select Configuration
    this.configs.country = {
      sourceProvider: (term: string) => {
        return Observable.create(observer => {
          this._countryService.get().subscribe(response => {
            observer.next(response);
          });
        });
      },
      adapter: (country: any) => {
        return {
          id: country.id,
          text: country.name,
          has_states: country.has_states,
          entity: country
        };
      },
      messages: {
        noResultsAvailableMsg: 'No country found'
      },
      loadFromIds: (ids: string[]) => {
        const id = ids[0];
        return Observable.create(observer => {
          if (id === undefined) {
            observer.next([]);
          }
        });
      },
      placeholder: 'Select country'
    };

    // State Select Configuration
    this.configs.state = {
      adapter: (state: any) => {
        return {
          id: state.id,
          text: state.name,
          entity: state
        };
      },
      messages: {
        noResultsAvailableMsg: 'No state found'
      },
      placeholder: 'Select state'
    };


    // Party Select Configuration
    this.configs.party = {
      // sourceProvider not globally available
      adapter: (party: any) => {
        return {
          id: party.id,
          text: party.company_name
        };
      },
      messages: {
        noResultsAvailableMsg: 'No party found'
      },
      placeholder: 'Select a party'
    };

    // Room Select Configuration
    this.configs.room = {
      // sourceProvider not globally available
      adapter: (participation: any) => {
        const room = participation.room;
        return {
          id: room.id,
          text: room.name
        };
      },
      messages: {
        noResultsAvailableMsg: 'No room found'
      },
      placeholder: 'Select a room'
    };

    // NDA Type Select Configuration
    this.configs.nda_types = {
      sourceProvider: (term: string) => {
        return Observable.create(observer => {
          observer.next(this.getNDATypes());
        });
      },
      adapter: (value: any) => {
        return value;
      },
      loadFromIds: (ids: string[]) => {
        const id = ids[0];
        return Observable.create(observer => {
          if (id === undefined) {
            observer.next([]);
          }
          const type = this.getNDATypes().filter(x => x.id === id) [0];
          observer.next([type]);
        });
      },
      messages: {
        noResultsAvailableMsg: 'No type found'
      },
      placeholder: 'Select NDA type'
    };

    // NDA Languages
    this.configs.languages = {
      sourceProvider: (term: string) => {
        return Observable.create(observer => {
          observer.next(this.getLanguages());
        });
      },
      adapter: (value: any) => {
        return value;
      },
      loadFromIds: (ids: string[]) => {
        const id = ids[0];
        return Observable.create(observer => {
          if (id === undefined) {
            observer.next([]);
          }
          const type = this.getLanguages().filter(x => x.id === id) [0];
          observer.next([type]);
        });
      },
      messages: {
        noResultsAvailableMsg: 'No type found'
      }
    };

    // NDA Directions Select Configuration
    this.configs.nda_directions = {
      sourceProvider: (term: string) => {
        return Observable.create(observer => {
          observer.next(this.getNDADirections());
        });
      },
      adapter: (value: any) => {
        return value;
      },
      loadFromIds: (ids: string[]) => {
        const id = ids[0];
        return Observable.create(observer => {
          if (id === undefined) {
            observer.next([]);
          }
          const type = this.getNDADirections().filter(x => x.id === id) [0];
          observer.next([type]);
        });
      },
      messages: {
        noResultsAvailableMsg: 'No direction found'
      },
      placeholder: 'Select NDA direction'
    };

    // Q&A Subjects Select Configuration
    this.configs.qna_subjects = {
      sourceProvider: (term: string) => {
        return Observable.create(observer => {
          observer.next(this.getSubjects());
        });
      },
      adapter: (value: any) => {
        return value;
      },
      loadFromIds: (ids: string[]) => {
        const id = ids[0];
        return Observable.create(observer => {
          if (id === undefined) {
            observer.next([]);
          }
          const type = this.getSubjects().filter(x => x.id === id) [0];
          observer.next([type]);
        });
      },
      messages: {
        noResultsAvailableMsg: 'No subject found'
      },
      placeholder: 'Select subject'
    };
  }

  getUserRoles() {
    return [
      {id: 'admin', text: this.translate.instant('general.admin')},
      {id: 'editor', text: this.translate.instant('general.editor')},
      {id: 'viewer', text: this.translate.instant('general.viewer')}
    ];
  }

  getUserRole(id) {
    return this.getUserRoles().find(x => x.id === id);
  }

  getNDATypes() {
    return [
      {id: 'MnA', text: 'Mergers & Acquisitions'},
      {id: 'product_development', text: 'Product or Business Development'},
      {id: 'financing', text: 'Financing or Investment'},
      {id: 'real_estate', text: 'Real Estate Transaction'}
    ];
  }

  getNDADirections() {
    return [
      {id: 'mutual', text: 'Mutual'},
      {id: 'unilateral', text: 'Unilateral'}
    ];
  }

  getLanguages() {
    return [
      {id: 'nl', text: this.translate.instant('general.dutch')},
      // {id: 'fr', text: this.translate.instant('general.french')},
      // {id: 'es', text: this.translate.instant('general.spanish')},
      // {id: 'ru', text: this.translate.instant('general.russian')},
      // {id: 'pt', text: this.translate.instant('general.portuguese')},
      // {id: 'de', text: this.translate.instant('general.german')},
      {id: 'en', text: this.translate.instant('general.english')}
    ];
  }

  getSubjects() {
    return [
      {id: '1', text: this.translate.instant('q&a.miscellaneous')},
      {id: '2', text: this.translate.instant('q&a.legal')},
      {id: '3', text: this.translate.instant('q&a.regulatory')},
      {id: '4', text: this.translate.instant('q&a.hr')},
      {id: '5', text: this.translate.instant('q&a.finance')},
      {id: '6', text: this.translate.instant('q&a.it')},
      {id: '7', text: this.translate.instant('q&a.commercial')},
      {id: '8', text: this.translate.instant('q&a.quality')}
    ];
  }
}
