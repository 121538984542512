import { Injectable, Inject } from '@angular/core';
import { Observable, observable } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class RoomChartService extends ApiService {

  constructor(public http: HttpClient,  public router: Router) {
    super(http, router);
  }

  getTimeline(roomId: number): Observable<any> {
    const path = `${this.fsBasePath}/rooms/${roomId}/charts/timeline?code=room_visited`;
    const params: any = {};

    return Observable.create(observer => {
      this.authenticatedGet(path, params).map(results => {
          return results['body'];
        }).subscribe(results => {
          observer.next(results);
      });
    });
  }

  getTopDocumentsViewed(roomId: number): Observable<any> {
    const path = `${this.fsBasePath}/rooms/${roomId}/charts/top_documents_viewed`;
    const params: any = {};

    return Observable.create(observer => {
      this.authenticatedGet(path, params).map(results => {
        return results['body'];
      }).subscribe(results => {
        observer.next(results);
      });
    });
  }

  getTopViewers(roomId: number): Observable<any> {
    const path = `${this.fsBasePath}/rooms/${roomId}/charts/top_viewers`;
    const params: any = {};

    return Observable.create(observer => {
      this.authenticatedGet(path, params).map(results => {
        return results['body'];
      }).subscribe(results => {
        observer.next(results);
      });
    });
  }
}
