import { User } from './user';
import { Room } from './room';
import * as moment from 'moment';

export class Participation {
  public id: number;
  public role: string;
  public party: any;
  public active: any;
  public state: string;

  // GET /participations
  public signed: boolean;
  public room: Room;
  public unread_notifications_count: number;
  public export_accessible: boolean;

  // GET /rooms/room_id/participations
  public user: User;
  public archived: boolean;
  public invitationSentAt: Date;

  constructor(item) {
    if (!item) {
      return;
    }

    this.id = item.id;
    this.role = item.role;
    this.active = item.active;
    this.state = item.state;

    this.signed = item.signed;
    this.room = item.room;
    this.unread_notifications_count = item.unread_notifications_count;
    this.export_accessible = item.export_accessible;

    this.archived = item.archived;

    if (item.user) {
      this.user = new User(item.user);
    }

    if (item.room) {
      this.room = new Room(item.room);
    }

    if (item.party) {
      this.party = item.party;
    }

    if (item.invitation_sent_at) {
      this.invitationSentAt = item.invitation_sent_at;
    }
  }

  public isActive() {
    return this.active;
  }

  invitedLessThanOneHourAgo(): boolean {
    if (this.invitationSentAt) {
      return moment(this.invitationSentAt).isAfter(moment().subtract(1, 'hours'));
    } else {
      return false;
    }
  }
}
