import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'fs-room-rules-nl',
  templateUrl: './room-rules-nl.component.html',
})
export class RoomRulesNlComponent {
  constructor() {
  }
}
