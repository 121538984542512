import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { UserService } from '../../../services/user.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { comparePassword } from '../../../validators/password-validation';

@Component({
  selector: 'fs-reset-password',
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit {
  public errors: Array<string> = [];
  public resetForm: FormGroup;
  public success: Boolean = false;
  private params: Object = {};
  public error: Boolean = false;

  public showValidations: boolean;
  public isStarted: boolean;
  public isCompleted: boolean;

  constructor(
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location) {
    this.createForm();
  }

  createForm(): any {
    this.resetForm = new FormGroup({
      password: new FormControl('', [Validators.required]),
      password_confirmation: new FormControl('', [Validators.required])
    },
      comparePassword
    );
  }

  send() {
    if (!this.resetForm.valid) {
      this.showValidations = true;
    } else {
      this.showValidations = false;
      const data = this.resetForm.value;
      this.userService.resetPassword(data['password'], data['password_confirmation']).subscribe(
        response => {
          this.success = true;
        },
        err => {
          this.errors = err.error.errors;
        }
      );
    }
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.params = params;

      if (!params.token) {
        this.error = true;
      } else {
        this.error = false;
      }

      this.location.replaceState('password/edit');
    });
  }
}
