import { Component, Input, Output, OnDestroy, EventEmitter, NgZone} from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Observable } from 'rxjs';
import { UploadEvent, UploadFile } from 'ngx-file-drop';
import { FileService } from '../../services/file.service';
 
import { Room } from '../../models/room';
import { FireUploadFile } from '../../models/fire-upload-file';

import { UploadDocumentsModal } from '../../modals/upload-documents/upload-documents.modal';

@Component({
  selector: 'files-drop-zone',
  templateUrl: './files-drop-zone.component.html',
  styleUrls: ['./files-drop-zone.component.scss']
})
export class FilesDropZoneComponent implements OnDestroy {
  @Input() room: Room;
  @Input() basePath: string;
  @Output() uploadComplete: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public fileService: FileService,
    public modalService: BsModalService,
    private _ngZone: NgZone
  ) {}

  public fileHovering: boolean;
  public uploadModalRef: any;

  public dropped(event: UploadEvent) {
    const uploadFiles: UploadFile[] = event.files;

    this.fileHovering = false;

    if (!this.droppingIsAuthorized()) {
      return;
    }

    this.fileService.getUploadFilesInfo(uploadFiles).subscribe(fireUploadFiles => {
      this.uploadModalRef = this.modalService.show(UploadDocumentsModal, {
        class: `modal-lg`,
        backdrop: 'static'
      });

      this.uploadModalRef.content.fireUploadFiles = fireUploadFiles;
      this.uploadModalRef.content.roomId = this.room.id;
      this.uploadModalRef.content.basePath = this.basePath;

      this.uploadModalRef.content.process().subscribe(() => {
        this.uploadComplete.emit({});
      });
    });
  }

  public droppingIsAuthorized() {
    return (this.room.current_participation.role == 'admin' || this.room.current_participation.role=='editor');
  }

  public fileOver(event) {
    if (this.droppingIsAuthorized()) this.fileHovering = true;
  }

  public fileLeave(event) {
    this.fileHovering = false;
  }

  ngOnDestroy() {
    if(this.uploadModalRef) this.uploadModalRef.hide();
  }
}
