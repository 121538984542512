export class Country {
  public id: string;
  public name: string;
  public has_states: boolean;

  constructor(item) {
    this.id = item.id;
    this.name = item.name;
    this.has_states = item.has_states;
  }
}
