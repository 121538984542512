import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { Contract } from '../models/contract';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ContractService extends ApiService {

  constructor(
    public http: HttpClient,
    public router: Router
  ) {
    super(http, router);
  }

  preview(data: any): Observable<any> {
    const path = `${this.fsBasePath}/contracts/preview`;
    return this.authenticatedPost(path, data);
  }

  upload(file: any): Observable<any> {
    const path = `${this.fsBasePath}/contracts/upload`;
    const name = file.name;

    const formData: FormData = new FormData();
    formData.append('file', file, name);

    return this.authenticatedPost(path, formData);
  }

  getByRoomId(id: number): Observable<any> {
    const path = `${this.fsBasePath}/rooms/${id}/contracts`;
    return Observable.create(observer => {
      this.authenticatedGet(path)
        .map(results => {
          return this.mapContract( results['body'] );
        })
        .subscribe(results => {

          observer.next(results);
        });
    });
  }

  getDownloadLink(room_id: number, contract_id: number): Observable<any> {
    const path = `${this.fsBasePath}/rooms/${room_id}/contracts/${contract_id}/download`;
    return Observable.create(observer => {
      this.authenticatedGet(path)
        .subscribe(results => {
          observer.next(results);
        });
    });
  }

  private mapContract(item) {
    return new Contract(item);
  }
}
