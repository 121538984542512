import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from '../../../services/user.service';
import { ParticipationService } from '../../../services/participation.service';

import { Participation } from '../../../models/participation';


@Component({
  selector: 'app-rooms-overview',
  templateUrl: './rooms-overview.component.html',
})
export class RoomsOverviewComponent implements OnInit {
  public participations: Participation[];

  constructor(
    public userService: UserService,
    public participationService: ParticipationService
  ) {

  }

  ngOnInit() {
    this.contextReady();
  }

  contextReady() {
    setTimeout(() => {
      this.participationService.get().subscribe( response => {
        this.participations = response.participations;
      });
    }, 1000);
  }
}
