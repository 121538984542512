import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/users/login/login.component';
import { ResetPasswordRequestComponent } from './pages/users/reset-password-request/reset-password-request.component';
import { RoomsOverviewComponent } from './pages/rooms/rooms-overview/rooms-overview.component';
import { RoomsDetailComponent } from './pages/rooms/rooms-detail/rooms-detail.component';
import { RegisterComponent } from './pages/users/register/register.component';
import { ResetPasswordComponent } from './pages/users/reset-password/reset-password.component';
import { ConfirmedComponent } from './pages/users/confirmed/confirmed.component';
import { LoginRouteGuard } from './injectables/login-route-guard';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { ExportComponent } from './pages/export/export.component';
import { CreateBinderComponent } from './pages/wizard/create-binder/create-binder.component';
import { PaymentComponent } from './pages/wizard/payment/payment.component';
import { OrderDetailsComponent } from './pages/wizard/order-details/order-details.component';
import { WizardComponent } from './pages/wizard/wizard.component';
import { UsersComponent } from './pages/users/users.component';
import { InvitationComponent } from './pages/users/invitation/invitation.component';

const routes: Routes = [
  {
    path: '',
    component: HomepageComponent,
    data: {
      step: 'redirect'
    }
  },
  {
    path: 'login',
    component: HomepageComponent,
    data: {
      title: 'Login',
      step: 'login'
    }
  },
  {
    path: 'password/reset',
    component: HomepageComponent,
    data: {
      title: 'Reset Password',
      step: 'reset-password'
    }
  },
  {
    path: 'confirmed',
    component: HomepageComponent,
    data: {
      title: 'Confirmed',
      step: 'confirmed'
    }
  },
  {
    path: 'password/edit',
    component: HomepageComponent,
    data: {
      title: 'Edit Password',
      step: 'edit-password'
    }
  },
  {
    path: 'register',
    component: HomepageComponent,
    data: {
      title: 'Register',
      step: 'register'
    }
  },
  {
    path: 'invitation',
    component: HomepageComponent,
    data: {
      title: 'Invitation',
      step: 'invitation'
    }
  },
  {
    path: 'new',
    redirectTo: 'create', 
    pathMatch: 'full'
  },
   {
    path: 'export/:id',
    component: ExportComponent,
    data: {
      title: 'Export'
    }
  },
  {
    path: 'rooms',
    component: RoomsOverviewComponent,
    canActivate: [LoginRouteGuard],
    data: {
      title: 'My Binders'
    }
  },
  {
    path: 'rooms/:id/:tab',
    component: RoomsDetailComponent,
    canActivate: [LoginRouteGuard]
  },
  {
    path: 'rooms/:id',
    redirectTo: 'rooms/:id/binder'
  },
  {
    path: 'nda/upload/step1',
    redirectTo: 'create',
    pathMatch: 'full'
  },
  {
    path: 'create',
    component: WizardComponent,
    data: {
      title: 'Create Binder',
      component: 'create-binder'
    }
  },
  {
    path: 'payment',
    component: WizardComponent,
    data: {
      title: 'Payment',
      component: 'payment',
      origin: 'upload'
    }
  },
  {
    path: 'order-details',
    component: WizardComponent,
    data: {
      title: 'Binder Summary',
      component: 'order-details'
    }
  }
];

@NgModule({
   imports: [RouterModule.forRoot(routes)],
   exports: [RouterModule]
})
export class AppRoutingModule {
}

export const routingComponents = [
  HomepageComponent,
  ExportComponent,
  UsersComponent,
  LoginComponent,
  ResetPasswordRequestComponent,
  ResetPasswordComponent,
  InvitationComponent,
  RoomsOverviewComponent,
  RoomsDetailComponent,
  RegisterComponent,
  ConfirmedComponent,
  WizardComponent,
  CreateBinderComponent,
  PaymentComponent,
  OrderDetailsComponent
];
