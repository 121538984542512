import { Room } from './room';
import { User } from './user';
import { Document } from './document';
import { Comment } from './comment';

export class Question {
  public id: number;
  public title: String;
  public description: String;
  public partyName: String;
  public closed: boolean;
  public document: Document;
  public user: User;
  public room: Room;
  public assigned_to: User;
  public created_at: Date;
  public closed_at: Date;
  public comments: Comment[] = [];
  public nrOfComments: number;
  public assignees: User[] = [];
  public subject: String;

  constructor(item) {
    if (!item) {
      return;
    }

    this.id = item.id;
    this.title = item.title;
    this.description = item.description;
    this.closed = item.closed;
    this.created_at = new Date(item.created_at);
    this.room = new Room(item.room);
    this.user = new User(item.user);
    this.nrOfComments = item.nr_of_comments;
    this.partyName = item.party_name;
    this.subject = item.subject;

    if (item.closed_at) {
      this.closed_at = new Date(item.closed_at);
    }

    if (item.document) {
      this.document = new Document(item.document);
    }

    if (item.assigned_to) {
      this.assigned_to = new User(item.assigned_to);
    }

    if (item.comments) {
      for (const comment of item.comments) {
        this.comments.push(new Comment(comment));
      }
    }

    if (item.assignees) {
      for (const assignee of item.assignees) {
        this.assignees.push(new User(assignee));
      }
    }
  }
}