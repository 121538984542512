import { Component } from '@angular/core';

@Component({
  selector: 'fs-room-create-progress',
  templateUrl: './room-create-progress.component.html',
})
export class RoomCreateProgressComponent {
  constructor() {
  }
}
