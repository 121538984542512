import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { Document } from '../models/document';
import { FireUploadFile } from '../models/fire-upload-file';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DocumentService extends ApiService {

  constructor(
    public http: HttpClient,
    public router: Router
  ) {
    super(http, router);
  }

  get(params: any): Observable<any> {
    const path = `${this.fsBasePath}/documents`;

    if (!params) {
      params = {};
    }

    const data: object = {};
    data['folder_id'] = params.folder_id;

    return Observable.create(observer => {
      this.authenticatedGet(path, data)
        .map(results => {
          return {'documents': this.mapDocuments(results['body']), 'headers': results['headers']};
        })
        .subscribe(results => {
          observer.next(results);
        });
    });
  }

  getOne(id): Observable<any> {
    const path = `${this.fsBasePath}/documents/${id}`;

    return Observable.create(observer => {
      this.authenticatedGet(path)
      .map(result => {
        return this.mapDocument(result['body']);
      })
      .subscribe(result => {
        observer.next(result);
      });
    });
  }

  search(room_id: number, search_name: string): Observable<any> {
    const path = `${this.fsBasePath}/documents/search`;

    const data: object = {};
    data['room_id'] = room_id;
    data['search_name'] = search_name;

    return Observable.create(observer => {
      this.authenticatedGet(path, data)
        .map(results => {
          return {'documents': this.mapDocuments(results['body']), 'headers': results['headers']};
        })
        .subscribe(results => {
          observer.next(results);
        });
    });
  }

  move(id, parentId) {
    const path = `${this.fsBasePath}/documents/${id}/change_parent`;

    const formData: FormData = new FormData();
    formData.append('folder_id', parentId);

    return this.authenticatedPut(path, formData);
  }

  rename(id: number, name: string): Observable<any> {
    const path = `${this.fsBasePath}/documents/${id}/rename`;
    return this.authenticatedPut(path, {name: name});
  }

  post(params: any): Observable<any> {
    const path = `${this.fsBasePath}/documents`;

    const fireUploadFile: FireUploadFile = params.fireUploadFile;
    const file: File = fireUploadFile.file;
    const filePath: string = fireUploadFile.getNamelessPath();

    let fileName: string = fireUploadFile.fileName;
    if (String.hasOwnProperty('normalize') || String.prototype.hasOwnProperty('normalize')) {
      fileName = fileName.normalize();
    }

    const formData: FormData = new FormData();
    formData.append('room_id', params.roomId);
    formData.append('path', params.basePath + filePath);
    formData.append('name', fileName);
    formData.append('file', file, fileName);

    if (params.overwrite) {
      formData.append('overwrite', 'true');
    }

    return this.authenticatedPost(path, formData);
  }

  archive(id): Observable<any> {
    const path = `${this.fsBasePath}/documents/${id}/archive`;

    return Observable.create(observer => {
      this.authenticatedPut(path)
        .map(results => {
          return {'results': results['body'], 'headers': results['headers']};
        })
        .subscribe(result => {
          observer.next(result);
        });
    });
  }

  getUrl(id): Observable<any> {
    const path = `${this.fsBasePath}/documents/${id}/url`;

    return Observable.create(observer => {
      this.authenticatedGet(path)
        .map(results => {
          return {'file': results['body'].file, 'headers': results['headers']};
        })
        .subscribe(result => {
          observer.next(result);
        });
    });
  }

  serialize(data: any): any {
    return data;
  }

  private mapDocuments(results) {
    const docs = [];
    for (const result of results) {
      docs.push(this.mapDocument(result));
    }

    return docs;
  }

  private mapDocument(item) {
    return new Document(item);
  }
}
