import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Component, ViewChild, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
  selector: 'fs-accept-participation',
  templateUrl: './accept-participation.modal.html',
})
export class AcceptParticipationModal {
  @ViewChild(ModalDirective) modal: ModalDirective;
  @Output() onAccepted: EventEmitter<any> = new EventEmitter();

  public constructor(
    public bsModalRef: BsModalRef
  ) { }

  acceptParticipation() {
    this.onAccepted.emit(true);
  }

  closeModal() {
    this.bsModalRef.hide();
  }
}
