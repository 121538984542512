import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, HostBinding } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';

import { Document } from '../../../../../models/document';
import { Room } from '../../../../../models/room';

import { DocumentService } from '../../../../../services/document.service';

import { ArchivingModal } from '../../../../../modals/archiving/archiving.modal';
import { CreateQuestionModal } from '../../../../../modals/questions/create-question.modal';
import { DocumentViewsModal } from '../../../../../modals/document-views/document-views.modal';
import { FoldersTreeModal } from '../../../../../modals/folders-tree/folders-tree.modal';

@Component({
  selector: 'tr-document',
  templateUrl: './tr-document.component.html',
  styles: [`
    :host {
      display: table-row;
    }
  `]
})
export class TrDocumentComponent {
  @Input() document: Document;
  @Input() room: Room;

  @Output() archived: EventEmitter<any> = new EventEmitter<any>();
  @Output() moved: EventEmitter<any> = new EventEmitter<any>();

  public renameModeOn: boolean;
  public renameValue: string;
  public isRenaming: boolean;

  public archivingModalRef: any;
  public foldersTreeModalRef: any;
  public createQuestionModal: any;
  public isLoadingUrl: boolean;
  public documentViewsModal: any;

  constructor(
     public modalService: BsModalService,
     public documentService: DocumentService,
     public router: Router
  ) {}

  openFile(event, url) {
    event.preventDefault();

    if (!this.renameModeOn) {
      const winRef: any = window.open();

      this.isLoadingUrl = true;
      this.documentService.getUrl(this.document.id).subscribe(response => {
        setTimeout(() => {
          this.isLoadingUrl = false;
          this.document.view_count++;
          // window.open(response.file.url);
          winRef.location = response.file.url;
        }, 100);
      });
    }
  }

  optionRename(event) {
    event.preventDefault();
    this.renameModeOn = true;
    this.renameValue = this.document.name;
  }

  optionArchive(event) {
    event.preventDefault();

    this.archivingModalRef = this.modalService.show(ArchivingModal, {
      class: `modal-md`
    });

    this.archivingModalRef.content.contextType = 'document';
    this.archivingModalRef.content.context = this.document;

    this.archivingModalRef.content.process().subscribe(() => {
      this.archived.emit({});
    });
  }

  optionMove(event) {
    event.preventDefault();

    this.foldersTreeModalRef = this.modalService.show(FoldersTreeModal, {
      class: `modal-md`
    });

    this.foldersTreeModalRef.content.folderId = this.room.folder_id;
    this.foldersTreeModalRef.content.contextType = 'document';
    this.foldersTreeModalRef.content.context = this.document;

    this.foldersTreeModalRef.content.process().subscribe(() => {
      this.moved.emit({});
    });
  }

  handleRename(event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.isRenaming) {
      return;
    }

    if (!this.renameValue || this.renameValue === this.document.name) {
      this.cancelRename();
      return;
    }

    this.isRenaming = true;
    this.documentService.rename(this.document.id, this.renameValue).subscribe(response => {
      this.document.name = this.renameValue;
      this.cancelRename();
      this.isRenaming = false;
    }, error => {
      this.isRenaming = false;
    });
  }

  cancelRename(event?) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.renameModeOn = false;
    this.renameValue = '';
  }

  openCreateQuestionModal(event) {
    event.preventDefault();
    this.createQuestionModal = this.modalService.show(CreateQuestionModal, {
      class: `modal-lg`
    });

    this.createQuestionModal.content.roomId = this.room.id;
    this.createQuestionModal.content.document = this.document;
    this.createQuestionModal.content.process().subscribe();
  }

  optionViewQuestions(event) {
    event.preventDefault();
    this.router.navigate(['/rooms', this.room.id, 'questions'], {queryParams: {filter: this.document.id }});
  }

  canMakeChanges() {
    if (this.room.current_participation.role === 'admin'
      || this.room.current_participation.role === 'editor') {
      return true;
    } else {
      return false;
    }
  }

  openViewsModal(event: any, document: Document) {
    event.preventDefault();
    if (document.view_count > 0) {
      this.documentViewsModal = this.modalService.show(DocumentViewsModal, {
        keyboard: false,
        class: 'modal-lg'
      });
      this.documentViewsModal.content.document = document;
      this.documentViewsModal.content.room_id = this.room.id;
      this.documentViewsModal.content.update();
    }
  }

}
