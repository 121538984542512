import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Component, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { Router } from "@angular/router";

import { QuestionService } from '../../services/question.service';
import { ParticipationService } from '../../services/participation.service';
import { Document } from '../../models/document';
import { Participation } from '../../models/participation';
import { TranslateService } from '@ngx-translate/core';
import { Question } from "../../models/question";


@Component({
  selector: 'fs-assign-question-modal',
  templateUrl: './assign-question.modal.html',
})
export class AssignQuestionModal {
  @ViewChild(ModalDirective) modal: ModalDirective;
  @Input() question: Question;

  public roomId: number;
  public document: Document;
  public form: FormGroup;
  public isStarted: boolean;
  public isCompleted: boolean;
  public currentObserver: any;
  public participations: Participation[];
  public assigneesDropdownList = [];
  public selectedAssignees = [];
  public assigneesDropdownSettings = {};

  public constructor(
    public bsModalRef: BsModalRef,
    public formBuilder: FormBuilder,
    public questionService: QuestionService,
    public participationService: ParticipationService,
    private router: Router,
    private translate: TranslateService
  ) {
    this.setupForm();
  }

  public process(): Observable<any> {
    if(!this.roomId) console.error("new-folder.modal: roomId is expected!");

    return Observable.create(observer => {
      this.currentObserver = observer;
      this.setupForm();
    });
  }

  private setupForm() {
    if (this.roomId) {
      this.fetchParticipations();
    }

    this.assigneesDropdownSettings = {
      singleSelection: false,
      text: this.translate.instant("q&a.create_question_form_select_assignees"),
      selectAllText:this.translate.instant("general.select_all"),
      unSelectAllText:this.translate.instant("general.unselect_all"),
      searchPlaceholderText: this.translate.instant("general.search"),
      enableSearchFilter: true,
      groupBy: "partyName"
    };

    const builderOptions = {};

    // invisible
    builderOptions['room_id'] = new FormControl(this.roomId, Validators.required);

    this.form = this.formBuilder.group(builderOptions);
    this.isStarted = false;
    this.isCompleted = false;
  }

  onSubmit() {
    this.assignQuestion();
  }

  private assignQuestion() {
    this.isStarted = true;
    let assignees = {assignees: this.selectedAssignees.map(selectedAssignee => { return {user_id: selectedAssignee.id}})};
    this.questionService.assign(this.question.id, assignees).subscribe(response => {
      this.isCompleted = true;
      this.currentObserver.next();
      this.bsModalRef.hide();
      this.router.navigate(['rooms', this.roomId, 'answers'], { queryParams: { q: response.id } });
    });
  }

  private fetchParticipations() {
    this.participationService.getByRoom(this.roomId).subscribe(response =>{
      this.participations = response.participations;
      this.assigneesDropdownList = this.participations.map(participation => {
        let obj:any = {
          id: participation.user.id,
          itemName: participation.user.getFullName()
        }

        if(participation.party && participation.party.company_name) {
          obj.partyName = participation.party.company_name
        }

        return obj;
      })

      this.selectedAssignees = this.question.assignees.map(user => {
        return {
          id: user.id,
          itemName: user.getFullName()
        }
      })
    });
  }

  public closeModal() {
    this.bsModalRef.hide();
  }
}
