import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Component, ViewChild, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'fs-room-create',
  templateUrl: './room-create.modal.html',
})
export class RoomCreateModal {
  @ViewChild(ModalDirective) modal: ModalDirective;
  @Output() onRead: EventEmitter<any> = new EventEmitter();

  public state: String = '';

  public constructor(
    public bsModalRef: BsModalRef,
    public router: Router
  ) { }

  setState(state) {
    this.state = state;
  }

  goToOverview() {
    this.bsModalRef.hide();
    this.router.navigate(['rooms']);
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  proceed(read) {
    this.onRead.emit(read);
  }
}
