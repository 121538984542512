import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { AcceptParticipationModal } from '../../modals/accept-participation/accept-participation.modal';
import { DeclineParticipationModal } from '../../modals/decline-participation/decline-participation.modal';
import { RoomRulesComponent } from '../../modals/room-rules/room-rules.component';
import { Participation } from '../../models/participation';
import { ParticipationService } from '../../services/participation.service';




@Component({
  selector: 'room-card',
  templateUrl: './room-card.component.html',
})
export class RoomCardComponent {
  public modalRef: BsModalRef;

  public acceptParticipationModal: any;
  public declineParticipationModal: any;
  public roomRulesModal: any;

  // Participation - Two-Way-Binding - Start
  public participationValue: Participation;
  @Output() participationChange = new EventEmitter();

  @Input()
  get participation() {
    return this.participationValue;
  }

  set participation(val) {
    this.participationValue = val;
    this.participationChange.emit(this.participationValue);
  }
  // Participation - Two-Way-Binding - End

  get room() {
    if (this.participationValue && this.participationValue.room) {
      return this.participationValue.room;
    }
  }

  constructor(
    public participationService: ParticipationService,
    public modalService: BsModalService
  ) {}

  openModal(template: TemplateRef<any>, size: string) {
    this.modalRef = this.modalService.show(template, {class: `modal-${size}`});
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  openAcceptParticipationModal() {
    this.acceptParticipationModal = this.modalService.show(AcceptParticipationModal, {class: `modal-sm`});
    this.acceptParticipationModal.content.onAccepted.subscribe(result => {
      this.acceptParticipationModal.hide();
      this.openRoomRulesModal();
    });
  }

  openRoomRulesModal() {
    this.roomRulesModal = this.modalService.show(RoomRulesComponent, {class: `modal-lg`});
    this.roomRulesModal.content.onProceed.subscribe(result => {
      this.roomRulesModal.hide();
      this.acceptParticipation(result);
    });
  }

  openDeclineParticipationModal() {
    this.declineParticipationModal = this.modalService.show(DeclineParticipationModal, {class: `modal-sm`});
    this.declineParticipationModal.content.participation = this.participation;
    this.declineParticipationModal.content.onDeclined.subscribe(result => {
      this.participation = result;
    });
  }

  acceptParticipation(accepted_room_rules) {
    this.participationService.put({
      id: this.participation.id,
      formData: {
        state: 'accepted',
        accepted_room_rules: accepted_room_rules
      }
    }).subscribe(response => {
      if (response.participation) {
        this.participation = response.participation;
      }
    });

    this.closeModal();
  }
}
