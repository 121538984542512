import { User } from './user';
import { RoomActivity } from './room-activity';

export class RoomNotification {
  public id: number;
  public read: boolean;
  public code: string;
  public created_at: Date;
  public user: User;
  public trackable: any;
  public trackable_type: string;
  public old_status: any;
  public new_status: any;

  constructor(data) {
    if (!data) {
      return;
    }

    this.id = data.id;
    this.read = data.read;

    if(data.activity) {
      const act = data.activity;

      this.code = act.code;
      this.created_at = new Date(act.created_at);
      this.user = new User(act.user);
      this.trackable = act.trackable;
      this.trackable_type = act.trackable_type;
      this.old_status = act.old_status;
      this.new_status = act.new_status;
    }

    if(['document_renamed', 'folder_renamed', 'document_created', 'folder_created'].indexOf(this.code)>-1) {
      if(!this.new_status) {
        this.new_status = this.trackable.name;
      }
    }
  }
}
