import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'fs-room-create-success',
  templateUrl: './room-create-success.component.html',
})
export class RoomCreateSuccessComponent {
  @Output() onFinish: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  finish() {
    this.onFinish.emit(null);
  }
}
