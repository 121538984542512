import { Component, OnInit, Input } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { QuestionService } from '../../../../services/question.service';
import { DocumentService } from '../../../../services/document.service';
import { UserService } from '../../../../services/user.service';
import { Question } from '../../../../models/question';
import { Room } from '../../../../models/room';
import { User } from '../../../../models/user';

import { CreateQuestionModal } from '../../../../modals/questions/create-question.modal';
import { ActivatedRoute } from '@angular/router';
import { Document } from "../../../../models/document";

@Component({
  selector: 'room-tab-faq',
  templateUrl: './tab-faq.component.html'
})
export class TabFaqComponent implements OnInit {
  public questions: Question[];
  public document: Document;
  public filteredQuestions: Question[];
  public createQuestionModal: any;
  public filter: String = 'all';
  public isLoading: boolean;

  private _active: boolean;

  @Input() room: Room;

  @Input() set active(value: boolean) {
    this._active = value;

    if (this._active) {
      const filter: String = this.route.snapshot.queryParamMap.get('filter');
      this.update(filter);
    }
  }

  get active(): boolean {
    return this._active;
  }

  constructor(
    public questionService: QuestionService,
    public documentService: DocumentService,
    public modalService: BsModalService,
    public userService: UserService,
    public route: ActivatedRoute,
  ) {}

  ngOnInit() {

  }

  setFilter(event: Event, fitler: String) {
    event.preventDefault();
    this.filter = fitler;
    this.setFilteredQuestions();
  }

  setFilteredQuestions(): void {
    this.filteredQuestions = this.getFilteredQuestions();
  }

  getFilteredQuestions(): Question[] {
    if (!this.questions) {
      return;
    }

    if (this.filter === 'all') {
      return this.questions;
    } else if (this.filter === 'open') {
      return this.getOpenQuestions();
    } else if (this.filter === 'closed') {
      return this.getClosedQuestions();
    } else if (this.filter === 'mine') {
      return this.getMyQuestions();
    }
  }

  getOpenQuestions(): Question[] {
    if(!this.questions) return;
    return this.questions.filter(question => !question.closed);
  }

  getClosedQuestions(): Question[] {
    if(!this.questions) return;
    return this.questions.filter(question => question.closed);
  }

  getMyQuestions(): Question[] {
    if(!this.questions) return;
    let currentUser: User = new User(this.userService.getCurrentUser());
    return this.questions.filter(question => question.user.id == currentUser.id);
  }

  getNrOfOpenQuestions(): number {
    if(!this.questions) return;
    return this.getOpenQuestions().length;
  }

  getNrOfClosedQuestions(): number {
    if(!this.questions) return;
    return this.questions.length - this.getNrOfOpenQuestions();
  }

  getNrOfMyQuestions(): number {
    if(!this.questions) return;
    return this.getMyQuestions().length;
  }

  update(document_id = null) {
    this.isLoading = true;

    if (document_id) {
      this.documentService.getOne(document_id).subscribe(document => {
        this.document = document;
      });
    }

    setTimeout(() => {
      this.questionService.getByRoomId(this.room.id, document_id).subscribe(response => {
        this.questions = response;
        this.setFilteredQuestions();
        this.isLoading = false;
      });
    }, 1000);
  }

  openCreateQuestionModal() {
    this.createQuestionModal = this.modalService.show(CreateQuestionModal, {
      class: `modal-lg`
    });

    this.createQuestionModal.content.roomId = this.room.id;
    this.createQuestionModal.content.process().subscribe(() => {
      this.update();
    });
  }

  searchFileSelected(event) {
    this.update(event.id);
  }

  searchFileRemoved(event) {
    this.update();
  }
}
