import { Component, OnInit, OnDestroy } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { UserService } from '../../../services/user.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SelectService } from '../../../services/select.service';
import { comparePassword } from '../../../validators/password-validation';
import { BsModalService } from 'ngx-bootstrap';
import { PrivacyPolicyModal } from '../../../modals/privacy-policy/privacy-policy.modal';
import { TermsOfServiceModal } from '../../../modals/terms-of-service/terms-of-service.modal';


@Component({
  selector: 'fs-register',
  templateUrl: './register.component.html'
})
export class RegisterComponent implements OnInit, OnDestroy {
  public errors: Array<string> = [];
  public registerForm: FormGroup;
  public success: Boolean = false;

  public showValidations: boolean;
  public isStarted: boolean;
  public isCompleted: boolean;

  public privacyPolicyModal: any;
  public termsOfServiceModal: any;

  public language: string;

  constructor(
    private userService: UserService,
    private router: Router,
    public selectService: SelectService,
    public modalService: BsModalService,
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.language = localStorage.getItem('language');
  }

  createForm(): any {
    this.registerForm = new FormGroup({
      first_name: new FormControl('', Validators.required),
      last_name: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      password_confirmation: new FormControl('', Validators.required),
      accepted_policies: new FormControl('', [Validators.pattern('true'), Validators.required])
    },
      comparePassword
    );
  }

  register() {
    if (!this.registerForm.valid) {
      this.showValidations = true;
    } else {
      this.showValidations = false;
      const data = this.registerForm.value;
      this.userService.create(data).subscribe(
        response => {
          this.success = true;
        },
        err => {
          this.errors = err.error.errors['full_messages'];
        }
      );
    }
  }

  public openPrivacyPolicy(event) {
    event.preventDefault();
    this.privacyPolicyModal = this.modalService.show(PrivacyPolicyModal, {
      keyboard: false
    });
    return false;
  }

  public openTermsOfService(event) {
    event.preventDefault();
    this.termsOfServiceModal = this.modalService.show(TermsOfServiceModal, {
      keyboard: false
    });
    return false;
  }

  ngOnDestroy() {
    if (this.privacyPolicyModal) {
      this.privacyPolicyModal.hide();
    }
    if (this.termsOfServiceModal) {
      this.termsOfServiceModal.hide();
    }
  }
}
