import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { RoomNotification } from '../models/room-notification';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class RoomNotificationService extends ApiService {

  constructor(
    public http: HttpClient,
    public router: Router
  ) {
    super(http, router);
  }

  get(participationId: number, page?: number, perPage?: number): Observable<any> {
    const path = `${this.fsBasePath}/participations/${participationId}/notifications`;

    const params: any = {
      page: page ? page : 1,
      per_page: perPage ? perPage : 9999
    };

    return Observable.create(observer => {
      this.authenticatedGet(path, params)
        .map(results => {
          return {'notifications': this.mapNotifications(results['body']), 'headers': results['headers']};
        })
        .subscribe(results => {
          observer.next(results);
        });
    });
  }

  getUnreadCount(participationId: number): Observable<any> {
    return Observable.create(observer => {
      this.authenticatedGet(`${this.fsBasePath}/participations/${participationId}/unread_notification_count`)
        .subscribe(result => {
          observer.next(result['body']);
        });
    });
  }

  markAsAllRead(participationId: number) {
    const path = `${this.fsBasePath}/participations/${participationId}/notifications`;

    return Observable.create(observer => {
      this.authenticatedPut(path)
        .subscribe(result => {
          observer.next(result['body']);
        });
    });
  }

  private mapNotifications(results) {
    const notifications = [];
    for (const result of results) {
      notifications.push(this.mapNotification(result));
    }

    return notifications;
  }

  private mapNotification(item) {
    return new RoomNotification(item);
  }

  getIcon(code: String) {
    switch (code) {
      case 'folder_created' : return 'fas fa-folder';
      case 'folder_renamed' : return 'fas fa-folder';
      case 'folder_parent_changed' : return 'fas fa-folder';
      case 'document_created' : return 'fas fa-file';
      case 'document_renamed' : return 'fas fa-file';
      case 'document_parent_changed' : return 'fas fa-file';
      case 'question_created' : return 'fas fa-question-circle';
      case 'comment_created' : return 'fas fa-comment';
      case 'folder_archived' : return 'fas fa-trash';
      case 'document_archived' : return 'fas fa-trash';
      case 'comment_archived' : return 'fas fa-trash';
      default : return 'fas fa-clock';
    }
  }

  getRouting(not: RoomNotification): any {
    if (['folder_created', 'folder_renamed', 'folder_parent_changed'].indexOf(not.code) > -1 ) {
      return {
        routerLink: `binder`,
        queryParams: {
          d : not.trackable.id,
          r : (new Date()).getTime()
        }
      };
    }
  }
}
