import { Injectable } from '@angular/core';

@Injectable()
export class AppService {
  public mobileMenuActive;
  public notificationsOpen;

  constructor() {
  }
}
