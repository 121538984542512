import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CommentService extends ApiService {

  constructor(
    public http: HttpClient,
    public router: Router
  ) {
    super(http, router);
  }

  create(params: any): Observable<any> {
    const path = `${this.fsBasePath}/comments`;
    const data = {
      'question_id': params.questionId,
      'description': params.description
    };
    return this.authenticatedPost(path, data);
  }

  update(id: number, params: any): Observable<any> {
    const path = `${this.fsBasePath}/comments/${id}`;
    return this.authenticatedPut(path, params);
  }
}
