import { Component, Input } from '@angular/core';

import { UploadEvent, UploadFile } from 'ngx-file-drop';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Observable } from 'rxjs';

import { DocumentService } from '../../services/document.service';

import { FireUploadFile } from '../../models/fire-upload-file';

@Component({
  selector: 'upload-documents-modal',
  templateUrl: './upload-documents.modal.html',
  styles: [`
    .progress-bar {
      transition: width 0.25s;
      -webkit-transition: width 0.25s;
    }
  `]
})
export class UploadDocumentsModal {
  public fireUploadFiles: FireUploadFile[];
  public roomId: number;
  public basePath: string;

  public completedCount: number;
  public isStarted: boolean;
  public isCompleted: boolean;
  public isHandelingFile: boolean;
  public isHandeleingApplyAll: boolean;
  public isRenamingFile: boolean;
  
  public currentObserver: any;
  public handledfireUploadFile: any;
  public handleApplyType: string;
  public renamedFileName: string;

  public errors: any[];
  public warnings: any[];

  constructor(
    public bsModalRef: BsModalRef,
    public documentService: DocumentService
  ) {}

  public process(): Observable<any> {
    this.isStarted = false;
    this.isCompleted = false;
    this.isHandeleingApplyAll = false;
    this.isRenamingFile = false;
    this.completedCount = 0;
    this.handleApplyType = null;

    if(!this.fireUploadFiles) console.error("upload-documents.modal: fireUploadFiles are expected!");
    if(!this.roomId) console.error("upload-documents.modal: roomId is expected!");
    if(!this.basePath) console.error("upload-documents.modal: basePath is expected!");

    return Observable.create(observer => {
      this.currentObserver = observer;
    });
  }

  public startUploading(value) {
    this.isStarted = true;
    this.errors = [];
    this.warnings = [];
    this.uploadFile();
  }

  public uploadFile(forceOverwrite?) {
    const fireUploadFile = this.getCurrentFile();
    let params:any = {
      fireUploadFile: fireUploadFile,
      roomId: this.roomId,
      basePath: this.basePath,
      overwrite: forceOverwrite
    };

    this.documentService.post(params).subscribe(
      response => {
        this.nextFile();
      },

      error => {
        this.handleFile(fireUploadFile);
      }
    );
  }

  public nextFile() {
    this.completedCount++;

    if (this.completedCount < this.fireUploadFiles.length) {
      setTimeout(() => {
        this.uploadFile();
      } , 500);
    } else {
      this.uploadCompleted();
    }
  }

  handleFile(fireUploadFile: FireUploadFile) {
    this.handledfireUploadFile = fireUploadFile;
    this.isHandelingFile = true;
    
    if(this.isHandeleingApplyAll && this.handleApplyType) {
      if(this.handleApplyType=="skip") {
        this.handleFileSkip();
      } else
      if(this.handleApplyType=="rename") {
        this.handleFileRename();
      } else
      if(this.handleApplyType=="overwrite") {
        this.handleFileOverwrite();
      }
    }
  }

  handleFileSkip() {
    if(this.isHandeleingApplyAll && !this.handleApplyType) {
      this.handleApplyType = "skip";
    }

    this.handledfireUploadFile = null;
    this.isHandelingFile = false;
    this.nextFile();
  }

  handleFileRename() {
    if(this.isHandeleingApplyAll && !this.handleApplyType) {
      this.handleApplyType = "rename";
    }

    if(this.handledfireUploadFile) {
      this.renamedFileName = this.handledfireUploadFile.fileName;
    }

    this.isRenamingFile = true;
  }

  fileRenamed() {
    let file:FireUploadFile = this.getCurrentFile();
    file.rename(this.renamedFileName);

    this.isRenamingFile = false;
    this.handledfireUploadFile = null;
    this.isHandelingFile = false;

    this.uploadFile();
  }

  handleFileOverwrite() {
    if(this.isHandeleingApplyAll && !this.handleApplyType) {
      this.handleApplyType = "overwrite";
    }

    let file:FireUploadFile = this.getCurrentFile();

    this.handledfireUploadFile = null;
    this.isHandelingFile = false;

    this.uploadFile(true);
  }

  public uploadCompleted() {
    this.isCompleted = true;
    this.currentObserver.next();
  }

  public getProcessWidth() {
    let startWidth = 2;

    if (this.fireUploadFiles && this.fireUploadFiles.length && this.completedCount) {
      return (startWidth*2) + ((this.getCompletedFileSize() / this.getTotalFileSize()) * (100-(startWidth*2)));
    } else {
      if(!this.isStarted) {
        return startWidth;
      } else {
        return startWidth*2;
      }
    }
  }

  public getCurrentFile() {
    if (!this.fireUploadFiles) return;
    return this.fireUploadFiles[this.completedCount];
  }

  public getTotalFileSize() {
    let total:number = 0;

    if(!this.fireUploadFiles) {
      return total;
    }

    for (const fireUplaodFile of this.fireUploadFiles) {
      total += fireUplaodFile.file.size;
    }

    return total;
  }

  public getCompletedFileSize() {
    let total:number = 0;

    if(!this.fireUploadFiles) {
      return total;
    }

    for (var i = 0; i < this.fireUploadFiles.length; ++i) {
      if(this.completedCount>i) {
        total += this.fireUploadFiles[i].file.size;
      }
    }

    return total;
  }

  public closeModal() {
    this.bsModalRef.hide();
  }
}
