import { Component, Input, OnInit, OnDestroy, AfterViewInit, AfterContentChecked } from '@angular/core';
import { UploadEvent, UploadFile } from 'ngx-file-drop';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Observable } from 'rxjs';
import { FormGroup, FormControl, FormArray, Validators, FormBuilder } from '@angular/forms';

import { ParticipationService } from '../../services/participation.service';
import { RoomPartyService } from '../../services/room-party.service';
import { SelectService } from '../../services/select.service';
import { Party } from '../../models/party';

@Component({
  selector: 'app-room-invite-modal',
  templateUrl: './room-invite.modal.html',
})
export class RoomInviteModal implements  OnInit {
  public roomId: any;
  public partyId: any;
  public form: FormGroup;
  public selectLoads: any;
  public showValidations: boolean;
  public isSending: boolean;
  public isCompleted: boolean;
  public partySourceProvider: any;
  public languageSourceProvider: any;
  public currentObserver: any;
  public parties: any;
  public roles: any;
  public languages: any;

  constructor(
    public bsModalRef: BsModalRef,
    public formBuilder: FormBuilder,
    public selectService: SelectService,
    public participationService: ParticipationService,
    public roomPartyService: RoomPartyService
  ) {
    this.setupForm();
    this.roles = this.selectService.getUserRoles();
    this.languages = this.selectService.getLanguages();
  }
 
  public process(): Observable<any> {
    return Observable.create(observer => {
      this.currentObserver = observer;
      this.resetForm();
    });
  }


  ngOnInit() {
    setTimeout(() => this.initSelects(),0);
  }

  initSelects() {
    const parties = [];
    this.roomPartyService.getByRoomId(this.roomId).subscribe(response => {
      response.roomParties.forEach(function (value) {
        parties.push({id: value.id, text: value.company_name});
      });

      this.parties = parties;

      if (this.partyId) {
        this.form['controls']['party_id'].setValue(this.partyId);
      }
    });
  }

  public setupForm() {
    const builderOptions = {};

    // invisible
    builderOptions['room_id'] = new FormControl(this.roomId, Validators.required);

    // required
    builderOptions['party_id'] = new FormControl('', Validators.required);
    builderOptions['role'] = new FormControl('', Validators.required);
    builderOptions['language'] = new FormControl('', Validators.required);
    builderOptions['user'] = this.formBuilder.group({
      'email': new FormControl('', [Validators.required, Validators.email])
    });
    this.form = this.formBuilder.group(builderOptions);
    this.showValidations = false;
    this.isSending = false;
    this.isCompleted = false;
  }

  public resetForm() {
    this.showValidations = false;
    this.isSending = false;
    this.isCompleted = false;

    this.form.enable();
    this.form.reset();

    this.setupForm();
    if (this.partyId) {
      this.form['controls']['party_id'].setValue(this.partyId);
    }
  }

  onSubmit() {
    if (this.isSending) {
      return;
    }

    this.showValidations = false;
    this.isSending = false;
    this.isCompleted = false;

    if (!this.form.valid) {
      this.showValidations = true;
    } else {
      this.showValidations = false;
      this.saveInvitation();
    }
  }

  private saveInvitation() {
    this.form.disable();

    this.isSending = true;
    this.participationService.post({
      roomId: this.roomId,
      formData: this.form.value
    }).subscribe(response => {
      this.isSending = false;
      this.isCompleted = true;
      this.currentObserver.next();
    });
  }

  public closeModal() {
    this.bsModalRef.hide();
  }
}
