import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

import { Plan } from '../models/plan';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PlanService extends ApiService {

  constructor(public http: HttpClient,  public router: Router) {
    super(http, router);
  }

  get() {
    return Observable.create(observer => {
      this.authenticatedGet(this.fsBasePath + '/plans')
        .map(results => {
          return this.mapPlans(results['body']);
        })
        .subscribe(res => {
          observer.next(res);
        }, err => {
          observer.error(err);
        }
      );
    });
  }

  private mapPlans(results) {
    const plans = [];

    for (const result of results) {
      const plan = this.mapPlan(result);

      plans.push(plan);
    }
    return plans;
  }

  private mapPlan(item) {
    return new Plan(item);
  }
}
