import { Pricing } from "./pricing";

export class Plan {
  public id: string;
  public name: string;
  public pricings: Pricing[];

  constructor(item) {
    this.id = item.id;
    this.name = item.name;
    
    this.pricings = [];
    item.yearly_rates.forEach(rate => {
      this.pricings.push(new Pricing(rate));
    });
  }

  getCurrencies() {
    return this.pricings.map((pricing) => {
      return pricing.iso;
    });
  }

  getPricing(currency_iso) {
    let filtered = this.pricings.filter((pricing) => {
      return pricing.iso==currency_iso;
    });

    if(filtered && filtered.length) {
      return filtered[0];
    } else {
      return;
    }
  }
}