import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserService } from '../../../services/user.service';
import { comparePassword } from '../../../validators/password-validation';
import { BsModalService } from 'ngx-bootstrap';
import { PrivacyPolicyModal } from '../../../modals/privacy-policy/privacy-policy.modal';
import { TermsOfServiceModal } from '../../../modals/terms-of-service/terms-of-service.modal';

@Component({
  selector: 'fs-invitation',
  templateUrl: './invitation.component.html'
})
export class InvitationComponent implements OnInit, OnDestroy {
  public errors: Array<string> = [];
  public setForm: FormGroup;
  public success: Boolean = false;
  private params: Object = {};

  public showValidations: boolean;
  public isStarted: boolean;
  public isCompleted: boolean;

  public privacyPolicyModal: any;
  public termsOfServiceModal: any;

  public language: string;

  constructor(
    private userService: UserService,
    public modalService: BsModalService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location) {
      this.createForm();
    }

  createForm(): any {
    this.setForm = new FormGroup({
      first_name: new FormControl('', [Validators.required]),
      last_name: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      password_confirmation: new FormControl('', [Validators.required]),
      accepted_policies: new FormControl('', [Validators.pattern('true'), Validators.required])
    }, comparePassword
    );
  }

  send() {
    if (!this.setForm.valid) {
      this.showValidations = true;
    } else {
      this.showValidations = false;
      const data = this.setForm.value;
      this.userService.acceptInvitation(data['password'], data['password_confirmation'], data['accepted_policies'], data['first_name'], data['last_name'], this.params).subscribe(
        response => {
          this.success = true;
        },
        err => {
          this.errors = err.error.errors;
        }
      );
    }
  }

  ngOnInit() {
    this.language = localStorage.getItem('language');
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.params = params;
    });
  }

  public openPrivacyPolicy(event) {
    event.preventDefault();
    this.privacyPolicyModal = this.modalService.show(PrivacyPolicyModal, {
      keyboard: false
    });
    return false;
  }

  public openTermsOfService(event) {
    event.preventDefault();
    this.termsOfServiceModal = this.modalService.show(TermsOfServiceModal, {
      keyboard: false
    });
    return false;
  }

  ngOnDestroy() {
    if (this.privacyPolicyModal) {
      this.privacyPolicyModal.hide();
    }
    if (this.termsOfServiceModal) {
      this.termsOfServiceModal.hide();
    }
  }
}
