import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'folders-tree-item',
  templateUrl: './folders-tree-item.component.html',
})
export class FoldersTreeItemComponent {
  @Input() item: any;
  @Input() activeFolderId: any;
  @Input() selectedItem: any;

  @Output() itemClicked: EventEmitter<any> = new EventEmitter<any>();

  public isOpen:boolean;
  
  constructor(
    
  ) {
    
  }

  public toggle() {
    this.isOpen = !this.isOpen;
  }

  public itemClick() {
    if(this.selectedItem && this.selectedItem.id==this.item.id) {
      this.itemClicked.emit(null);
    } else {
      this.itemClicked.emit(this.item);
    }
  }

  public onParentClicked($event) {
    this.itemClicked.emit($event);
  }

  public isActive() {
    if(!this.selectedItem) return false;
    return this.selectedItem.id==this.item.id;
  }
}
