import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Observable } from 'rxjs/Observable';

import { FolderService } from '../../services/folder.service';
import { DocumentService } from '../../services/document.service';

@Component({
  selector: 'folders-tree-modal',
  templateUrl: './folders-tree.modal.html',
})
export class FoldersTreeModal {
  public folderId: number;
  public activeFolderId: number;
  public contextType: string;
  public context: any;
  
  public tree: any;
  public selectedTreeItem: any;
  public currentObserver: any;
  public isMoving: boolean;

  constructor(
    public bsModalRef: BsModalRef,
    public folderService: FolderService,
    public documentService: DocumentService
  ) {
  }

  public process(): Observable<any> {
    if(!this.folderId) console.error("folders-tree.modal: folderId is expected!");
    if(!this.contextType) console.error("folders-tree.modal: contextType is expected!");

    return Observable.create(observer => {
      this.currentObserver = observer;
      this.getTree();
    });
  }

  private getTree() {
    this.folderService.getTree(this.folderId).subscribe(response => {
      this.tree = response.tree;
    });
  }

  public onTreeChoice(event) {
    this.selectedTreeItem = event;
  }

  public moveContext() {
    this.isMoving = true;
    if(this.contextType=='folder') {
      this.folderService.move(this.context.id, this.selectedTreeItem.id).subscribe(()=> {
        this.finish();
      }, error => {
        this.isMoving = false;
      });
    }

    if(this.contextType=='document') {
      this.documentService.move(this.context.id, this.selectedTreeItem.id).subscribe(()=> {
        this.finish();
      }, error => {
        this.isMoving = false;
      });
    }
  }

  private finish() {
    this.currentObserver.next();
    this.closeModal();
  }

  private ngOnDestroy() {
    this.currentObserver.unsubscribe();
  }

  public closeModal() {
    this.bsModalRef.hide();
  }
}
