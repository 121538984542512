import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Component, Input, ViewChild } from '@angular/core';
import { RoomActivityService } from '../../services/room-activity.service';
import { RoomActivity } from '../../models/room-activity';

@Component({
  selector: 'fs-child-activities',
  templateUrl: './child-activities.modal.html',
})
export class ChildActivitiesModal  {
  @Input() room_id: number;
  @Input() activity: RoomActivity;

  public isLoading = false;

  // pagination
  public items_per_page = 5;
  public total_pages = 0;
  public current_page = 1;
  public total_activities = 0;

  public childActivities: Array<RoomActivity>;

  public constructor(
    public bsModalRef: BsModalRef,
    public roomActivityService: RoomActivityService
  ) { }

  update() {
    this.isLoading = true;
    this.roomActivityService.getChildActivities(this.room_id, this.activity.id, this.current_page, this.items_per_page).subscribe((response) => {
      this.childActivities = response.roomActivities;
      this.isLoading = false;
      this.total_activities = response['headers'].get('Total');
      this.total_pages = Math.ceil((this.total_activities / this.items_per_page));
    });
  }

  pageChange(page) {
    this.current_page = page;
    this.update();
  }

  closeModal() {
    this.bsModalRef.hide();
  }
}
