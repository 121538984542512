import { User } from './user';

export class Comment {
  public id: number;
  public description: string;
  public partyName: string;
  public questionTitle: string;
  public user: User;
  public created_at: Date;

  constructor(item) {
    if (!item) {
      return;
    }

    this.id = item.id;
    this.description = item.description;
    this.partyName = item.party_name;
    this.user = new User(item.user);
    this.questionTitle = item.question_title;
    this.created_at = new Date(item.created_at);
  }
}
