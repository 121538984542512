import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Component, ViewChild, Output, EventEmitter, Input, OnInit, AfterViewInit, AfterContentChecked } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { Router } from '@angular/router';
import { FormGroup, NgForm, NgModel, FormBuilder, Validators, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SelectService } from '../../services/select.service';
import { Observable } from 'rxjs';
import { RoomService } from '../../services/room.service';
import { ParticipationService } from '../../services/participation.service';
import { SupportService } from '../../services/support.service';
import { Participation } from '../../models/participation';
import { Room } from '../../models/room';

@Component({
  selector: 'fs-support',
  templateUrl: './support.modal.html',
})
export class SupportModal implements OnInit {
  public success: boolean;
  public supportForm: FormGroup;
  public showValidations: boolean;
  public isSending: boolean;
  public dataSourceProvider: any;
  public rooms: any;
  public room: Room;

  public constructor(
    public bsModalRef: BsModalRef,
    public router: Router,
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public selectService: SelectService,
    public supportService: SupportService,
    public participationService: ParticipationService
  ) {
    
    this.setupForm();
    this.initSelect();
  }

  ngOnInit() {
    this.success = false;
  }

  initSelect() {
    this.supportForm['controls']['room'].disable();
    const rooms = [];

    this.participationService.get().subscribe( response => {
      response.participations.forEach(function (value) {
        rooms.push({id: value.room.id, text: value.room.name});
      });
      this.rooms = rooms;
      
      if (this.room) {
        this.supportForm['controls']['room'].setValue(this.room.id);
      }
      this.supportForm['controls']['room'].enable();
    });
  }

  setupForm() {
    const builderOptions = {};
    builderOptions['room'] = new FormControl('');
    builderOptions['title'] = new FormControl('', Validators.required);
    builderOptions['description'] = new FormControl('', Validators.required);

    this.supportForm = this.formBuilder.group(builderOptions);
    this.showValidations = false;
    this.isSending = false;
    this.success = false;
  }

  submitForm() {
    if (this.isSending) {
      return;
    }

    this.showValidations = false;
    this.isSending = false;

    if (!this.supportForm.valid) {
      this.showValidations = true;
    } else {
      this.showValidations = false;
      this.saveTicket();
    }
  }

  private saveTicket() {
    this.supportForm.disable();

    this.isSending = true;
    this.supportService.create(this.supportForm.value).subscribe(response => {
      this.isSending = false;
      this.success = true;
    });
  }

  closeModal() {
    this.bsModalRef.hide();
  }
}
