import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CouponService extends ApiService {

  constructor(
    public http: HttpClient,
    public router: Router
  ) {
    super(http, router);
  }

  get(token: String): Observable<any> {
    const path = `${this.fsBasePath}/coupons/${token}`;

    return Observable.create(observer => {
      this.authenticatedGet(path)
        .subscribe(result => {
          observer.next(result);
        },
        error => {
          observer.error(error);
        });
    });
  }
}
