import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { RoomExportService } from '../../services/room-export.service';
import { RoomExport } from '../../models/room-export';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../services/api.service';
import { Observable } from 'rxjs';
import { AppService } from '../../services/app.service';
import { interval } from 'rxjs';


@Component({
  selector: 'app-export',
  templateUrl: 'export.component.html'
})
export class ExportComponent implements OnInit, OnDestroy {
  public export: RoomExport;
  public room_id: number;
  public backend_path: String;
  public export_pull: any;
  public isLoadingCertificate: Boolean;

  constructor(
    private router: Router,
    public route: ActivatedRoute,
    private roomExportService:  RoomExportService,
    private apiService: ApiService,
    public appService: AppService
  ) {
  }

  ngOnInit() {
    const basePath = environment.basePath;
    this.backend_path = basePath + '/api/v1';

    this.route.params.subscribe(params => {
      this.room_id = params.id;
      this.loadExport(params.id);
      this.export_pull = interval(5000).map(tick => this.loadExport(params.id)).subscribe(() => {});
    });
  }

  ngOnDestroy() {
    this.export_pull.unsubscribe();
  }

  loadExport(id) {
    this.roomExportService.getByRoomId(id).subscribe(result => {
      this.export = result.export;
      if (this.export['certificate_ready']) {
        this.export_pull.unsubscribe();
      }
    });
  }

  getCertificate() {
    this.isLoadingCertificate = true;

    this.roomExportService.getCertificateByRoomId(this.room_id).subscribe(result => {
      window.open(result.url, '_blank');
      this.isLoadingCertificate = false;
    });
  }

  getZip() {
    this.roomExportService.getZipByRoomId(this.room_id).subscribe(result => {
      window.open(result.download_link, '_blank');
    });
  }

  goBack() {
    this.router.navigate(['/rooms']);
    this.appService.mobileMenuActive = false;
  }
}
