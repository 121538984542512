
import { Chart } from 'chart.js';
import { Component, Input, ElementRef } from '@angular/core';

@Component({
  selector: 'fire-piechart',
  templateUrl: './fire-piechart.component.html',
  styleUrls: ['fire-piechart.component.scss'],
})
export class FirePieChartComponent {
  constructor(private elementRef: ElementRef) {
    
  }
  public chart: any = {};
  public _data;
  public colors = [
    '#0ab7d6',    // turqoise
    '#87e5f6',    // light-turqoise

    '#3e95cd',    // blue
    '#1c4159',    // dark-blue

    '#ea0043',    // red
    '#950a32',    // dark-red

    '#8e5ea2',    // purple
    '#471c59',    // dark-purple

    '#3cba9f',    // green
    '#226a5a'     // dark-green
  ];

  @Input() id: string;

  @Input()
  get data() {
    return this._data;
  }

  set data(data: any) {
    if (data) {
      this._data = data;
      this.setChart(data.keys, data.values);
    }
  }

  setChart(keys: string[], values: string[]) {
    const htmlRef = this.elementRef.nativeElement.querySelector('#' + this.id);

    if (htmlRef) {
      this.chart = new Chart(htmlRef, {
        type: 'pie',
        data: {
          labels: keys,
          datasets: [
            {
              backgroundColor: this.colors,
              data: values
            }
          ]
        },
        options: {
          responsive: true,
          legend: false
        }
      });
    }
  }

  getBackgroundColor(index) {
    return this.colors[index];
  }

  getValue(index) {
    if(this.data && this.data.values) {
      return this.data.values[index];
    }
  }
}
