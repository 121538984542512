import {AbstractControl} from '@angular/forms';

export const comparePassword = (AC: AbstractControl): {[key: string]: any} => {
  const password = AC.get('password').value; // to get value in input tag
  const confirmPassword = AC.get('password_confirmation').value; // to get value in input tag
  if (password !== confirmPassword) {
      AC.get('password_confirmation').setErrors( {matchPassword: true});
  } else {
      return null;
  }
};


