import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap';
import { ChildActivitiesModal } from '../../../../modals/child-activities/child-activities.modal';
import { Participation } from '../../../../models/participation';
import { Room } from '../../../../models/room';
import { RoomActivity } from '../../../../models/room-activity';
import { ParticipationService } from '../../../../services/participation.service';
import { RoomActivityService } from '../../../../services/room-activity.service';


@Component({
  selector: 'room-tab-reporting',
  templateUrl: './tab-reporting.component.html'
})
export class TabReportingComponent implements OnInit {
  public activities: RoomActivity[];
  public isLoading: boolean;
  public participations: Array<Participation> = [];

  public userFilterLabel: String  = this.translate.instant('reporting.all_participants');
  public typeFilterLabel: String  = this.translate.instant('reporting.all_types');
  public filter: Object = {for_user: null, for_code: null};
  public childActivitiesModal: any;

  // pagination
  public items_per_page = 50;
  public total_pages = 0;
  public current_page = 1;
  public total_activities = 0;

  private _active: boolean;

  @Input() room: Room;

  @Input() set active(value: boolean) {
    this._active = value;

    if (this._active) {
      this.update();
    }
  }

  get active(): boolean {
    return this._active;
  }

  constructor(
    public roomActivityService: RoomActivityService,
    public participationService: ParticipationService,
    private translate: TranslateService,
    public modalService: BsModalService
  ) {}

  ngOnInit() {
    this.getParticipations();
  }

  pageChange(page) {
    this.current_page = page;
    this.update();
  }

  openChildren(event, activity) {
    event.preventDefault();
    if (activity.childrenCount >= 1) {
      this.childActivitiesModal = this.modalService.show(ChildActivitiesModal, {
        keyboard: true,
        class: 'modal-lg'
      });
      this.childActivitiesModal.content.activity = activity;
      this.childActivitiesModal.content.room_id = this.room.id;
      this.childActivitiesModal.content.update();
    }
  }

  update(filters = {}) {
    this.isLoading = true;
    this.roomActivityService.getByRoomId(this.room.id, filters, this.current_page, this.items_per_page).subscribe( response => {
      this.activities = response.roomActivities;
      this.isLoading = false;
      this.total_activities = response['headers'].get('Total');
      this.total_pages = Math.ceil((this.total_activities / this.items_per_page));
      window.scroll(0,0);
    });
  }

  getParticipations() {
    this.participationService.getByRoom(this.room.id).subscribe(response => {
      this.participations = response.participations;
      this.isLoading = false;
    });
  }

  activitiesTrackBy(index, item) {
    return item.id;
  }

  addUserFilter(event, user) {
    event.preventDefault();
    this.userFilterLabel = user.getFullName();
    this.filter['for_user'] = user.id;
    this.update(this.filter);
  }

  removeUserFilter(event) {
    event.preventDefault();
    this.userFilterLabel = this.translate.instant('reporting.all_participants');
    this.filter['for_user'] = null;
    this.update(this.filter);
  }

  addTypeFilter(event, types) {
    event.preventDefault();
    this.typeFilterLabel = event.target.innerHTML;
    this.filter['for_code'] = types;
    this.update(this.filter);
  }

  removeTypeFilter(event) {
    event.preventDefault();
    this.typeFilterLabel = this.translate.instant('reporting.all_types');
    this.filter['for_code'] = null;
    this.update(this.filter);
  }

  filterContainsCode(code) {
    const codes = this.filter['for_code'];
    if(!codes || !codes.length) return false;
    return (codes.indexOf(code)>-1);
  }
}
