import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularTokenService } from 'angular-token';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-homepage',
  templateUrl: 'homepage.component.html'
})
export class HomepageComponent implements OnInit {
  public flow: string;

  constructor(
    private router: Router,
    public route: ActivatedRoute,
    public userService: UserService,
    public tokenService: AngularTokenService
  ) {}

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.flow = data.flow || data.step;

      if (this.tokenService.userSignedIn()) {
        if (['login', 'register', 'redirect'].indexOf(this.flow) > -1) {
          this.router.navigate(['/rooms']);
        }
      } else {
        if (['redirect'].indexOf(this.flow) > -1) {
          this.router.navigate(['/login']);
        }
      }
    });
  }

  public loggedIn(event) {
    this.router.navigate(['/rooms']);
  }
}
