import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ValidationService {

  constructor(private translate: TranslateService) {}

  public getLabel(control: FormControl, defaultLabel: String = 'Field'): string {
    if (control.errors.required) {
        return `${defaultLabel} ${this.translate.instant('validation.required')}`;
    }

    if (control.errors.matchPassword) {
      return `${this.translate.instant('validation.passwords_match')}`;
    }

    if (control.errors.minlength) {
      return `${defaultLabel} ${this.translate.instant('validation.min_chars', {value: control.errors.minlength.requiredLength})}`;
    }

    return `${defaultLabel} ${this.translate.instant('validation.invalid')}`;
  }
}
