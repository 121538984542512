export class RoomParty {
  public id: number;
  public company_name: string;
  public archived: boolean;

  constructor(item) {
    if (!item) {
      return;
    }

    this.id = item.id;
    this.company_name = item.company_name;
    this.archived = item.archived;
  }
}
