import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { SelectService } from '../../../../services/select.service';
import { ParticipationService } from '../../../../services/participation.service';
import { RoomPartyService } from '../../../../services/room-party.service';

import { Room } from '../../../../models/room';
import { RoomParty } from '../../../../models/room-party';
import { Participation } from '../../../../models/participation';
import { Contract } from '../../../../models/contract';

import { ContractService } from '../../../../services/contract.service';

import { RoomInviteModal } from '../../../../modals/room-invite/room-invite.modal';
import { NewPartyModal } from '../../../../modals/new-party/new-party.modal';

@Component({
  selector: 'room-tab-users',
  templateUrl: './tab-users.component.html'
})
export class TabUsersComponent implements OnInit {
  public participations: Participation[];
  public roomParties: RoomParty[];
  public mappedParties: any[];

  public modalRef: BsModalRef;
  public isLoading: boolean;
  public contract: Contract;
  private _active: boolean;

  @Input() room: Room;

  @Input() set active(value: boolean) {
    this._active = value;

    if (this._active) {
      this.update();
      this.fetchContract();
    }
  }

  get active(): boolean {
    return this._active;
  }

  constructor(
    public participationService: ParticipationService,
    public roomPartyService: RoomPartyService,
    public contractService: ContractService,
    public modalService: BsModalService,
    public selectService: SelectService
  ) {}

  ngOnInit() {}

  update() {
    setTimeout(() => {
      this.fetchParties();
    }, 1000);
  }

  fetchParties(hideLoading?) {
    if (!hideLoading) {
      this.isLoading = true;
    }

    this.roomPartyService.getByRoomId(this.room.id).subscribe(response => {
      this.roomParties = response.roomParties;
      this.fetchParticipations();
    });
  }

  fetchParticipations() {
    this.participationService.getByRoom(this.room.id).subscribe(response => {
      this.participations = response.participations;
      this.isLoading = false;
      this.mappedParties = this.mapUsersToParties();
    });
  }

  fetchContract() {
    this.contractService.getByRoomId(this.room.id).subscribe( response => {
      this.contract = response;
    });
  }

  openModal(template: TemplateRef<any>, size: string) {
    this.modalRef = this.modalService.show(template, {class: `modal-${size}`});
  }

  openInviteUserModal(party_id) {
    this.modalRef = this.modalService.show(RoomInviteModal, {
      class: `modal-lg`
    });

    this.modalRef.content.roomId = this.room.id;
    this.modalRef.content.partyId = party_id;

    this.modalRef.content.process().subscribe(() => {
      this.fetchParticipations();
    });
  }

  canChangeList() {
    return ['role', 'archived'];
  }

  mapUsersToParties() {
    return this.roomParties.map(party => {
      return {
        party: party,
        participations: this.participations.filter(participation => {
          return party.id === participation.party.id;
        })
      };
    });
  }

  downloadNda() {
    this.contractService.getDownloadLink(this.room.id, this.contract.id).subscribe( response => {
      const result = response.json();
      if (result.link) {
        window.open(result.link);
      }
    });
  }

  openNewPartyModal() {
    this.modalRef = this.modalService.show(NewPartyModal, {
      class: `modal-md`
    });

    this.modalRef.content.roomId = this.room.id;
    
    this.modalRef.content.process().subscribe(() => {
      this.fetchParties();
    });
  }

  inAdminContext() {
    return this.room.current_participation.role === 'admin';
  }
}
