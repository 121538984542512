import { Component } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators, FormBuilder } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Observable } from 'rxjs';

import { RoomPartyService } from '../../services/room-party.service';

@Component({
  selector: 'new-party-modal',
  templateUrl: './new-party.modal.html',
})
export class NewPartyModal {
  public roomId: number;

  public currentObserver: any;
  public form: FormGroup;
  public showValidations: boolean;
  public isStarted: boolean;
  public isCompleted: boolean;
  public errors: any[];

  constructor(
    public bsModalRef: BsModalRef,
    public formBuilder: FormBuilder,
    public roomPartyService: RoomPartyService
  ) {
    this.setupForm();
  }

  public process(): Observable<any> {
    if(!this.roomId) console.error("new-folder.modal: roomId is expected!");

    return Observable.create(observer => {
      this.currentObserver = observer;
      this.setupForm();
    });
  }

  private setupForm() {
    const builderOptions = {};

    // required
    builderOptions['company_name'] = new FormControl('', Validators.required);

    this.form = this.formBuilder.group(builderOptions);
    this.showValidations = false;
    this.isStarted = false;
    this.isCompleted = false;
  }

  onSubmit() {
    this.showValidations = false;

    if (!this.form.valid) {
      this.showValidations = true;
    } else {
      this.showValidations = false;
      this.createParty();
    }
  }

  private createParty() {
    this.isStarted = true;

    this.roomPartyService.post(this.roomId, this.form.value).subscribe(response => {
      this.isCompleted = true;
      this.currentObserver.next();
      this.bsModalRef.hide();
    }, error => {
      this.bsModalRef.hide();
    });
  }

  public closeModal() {
    this.bsModalRef.hide();
  }
}
