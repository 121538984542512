import { Component, TemplateRef, OnInit, OnDestroy, Input, Output, EventEmitter, forwardRef, OnChanges } from '@angular/core';
import { FormControl, ControlValueAccessor, AbstractControl, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

@Component({
  selector: 'fire-simple-select',
  templateUrl: './fire-simple-select.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FireSimpleSelectComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => FireSimpleSelectComponent), multi: true }
  ]
})
export class FireSimpleSelectComponent implements ControlValueAccessor, OnChanges {
  propagateChange:any = () => {};
  validateFn:any = () => {};

  @Input() defaultLabel: string;
  @Input() formControlName: string;
  @Input() collection:any;
  @Input() showValidation: boolean; 
  @Input() emptyLabel: string;
  

  public _control;
  
  @Input()
  get control() {
    return this._control;
  }
  
  set control(val: any) {
    this._control = val;
    this._inputValue = null;
  }

  @Input('inputValue') _inputValue;

  constructor (
  ) {}

  get inputValue() {
    return this._inputValue;
  }
  
  set inputValue(val) {
    this._inputValue = val;
    this.propagateChange(val);
  }

  ngOnChanges(inputs) {

  }

  writeValue(value) {
    if (value ||  value === '') {
      this.inputValue = value;
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  validate(c: FormControl) {
    return this.validateFn(c);
  }
}

