import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'fs-page-loader',
  templateUrl: './page-loader.component.html'
})
export class PageLoaderComponent implements OnInit {
  @Input() label: String;
  @Input() hideCard: Boolean;
  
  constructor() { }

  ngOnInit() {}
}
