import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { UserService } from '../../../services/user.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AngularTokenService } from 'angular-token';

@Component({
  selector: 'fs-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public errors: Array<string> = [];
  public otpError: string;
  public otpAttempts: number;

  public showValidations: boolean;
  public isLoggingIn: boolean;
  public OTPEnabled: boolean;

  @Output() loggedin = new EventEmitter();
  @Output() stepChanged = new EventEmitter();

  constructor(
    private userService: UserService,
    private router: Router,
    private translate: TranslateService,
    private tokenService: AngularTokenService
  ) {
    this.OTPEnabled = false;
    this.createForm();
  }

  ngOnInit() {}

  private createForm(): void {
    const FAPattern = '^[0-9_]{6}$';
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      otp: new FormControl('', [Validators.pattern(FAPattern)])
    });
  }

  gotoStep(event, step) {
    event.preventDefault();
    this.stepChanged.emit(step);
  }

  checkOTPEnabled(): void {
    const converted_errors = [];
    if (this.OTPEnabled) {
      this.login();
      return;
    }

    if (!this.loginForm.valid) {
      this.showValidations = true;
    } else {
      this.isLoggingIn = true;
      this.showValidations = false;
      const data = this.loginForm.value;
      this.userService.checkOTPEnabled(data.email, data.password).subscribe(
        (result) => {
          if (result === true) {
            this.OTPEnabled = true;
            this.isLoggingIn = false;
          } else {
            this.login();
          }
        },
        err => {
          this.isLoggingIn = false;
          this.errors = err.error.errors;
        }
      );
    }
  }

  login(): void {
    this.otpError = undefined;
    if (!this.loginForm.valid) {
      this.showValidations = true;
    } else {
      this.isLoggingIn = true;
      this.showValidations = false;
      const data = this.loginForm.value;
      this.userService.login(data.email, data.password, data.otp).subscribe(
        () => {
          this.isLoggingIn = false;
          this.loggedin.emit(null);
        },
        err => {
          this.errors = err;
          this.isLoggingIn = false;
          const converted_errors = [];

          /// handle wrong OTP
          for (const error of this.errors) {
            if (error['message'] === 'incorrect_otp') {
              const attempts = error['max_failed_attempts'] - error['failed_otp_count'];
              if (attempts > 0) {
                this.otpAttempts = attempts;
                this.otpError = 'wrong_otp';
              } else {
                this.otpError = 'blocked';
              }
            } else if (error === 'too_much_failed_otps') {
              this.otpError = 'blocked';

            } else {
              converted_errors.push(error);
            }

            this.errors = converted_errors;
          }
        }
      );
    }
  }
}
