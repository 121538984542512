export class Room {
  public id: number;
  public name: string;
  public folder_id: number;
  public current_participation: any;
  public parties: any[];
  public created_at: Date;
  public last_updated: Date;
  public archived_at: Date;
  public archived: boolean;
  public export_status: string;
  public room_export_id: number;

  constructor(item) {
    if (!item) {
      return;
    }

    this.id = item.id;
    this.name = item.name;
    this.folder_id = item.folder_id;
    this.current_participation = item.current_participation;
    this.archived = item.archived;
    this.export_status = item.export_status;
    this.room_export_id = item.room_export_id;

    if (item.parties) {
      this.parties = item.parties.reverse();
    }

    if (item.created_at) {
      this.created_at = new Date(item.created_at);
    }

    if (item.last_updated) {
      this.last_updated = new Date(item.last_updated);
    }

    if (item.archived_at) {
      this.archived_at = new Date(item.archived_at);
    }
  }
}
