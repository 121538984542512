import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { PlanService } from '../../services/plan.service';
import { Plan } from '../../models/plan';

@Component({
  selector: 'fs-wizard',
  templateUrl: './wizard.component.html'
})
export class WizardComponent implements OnInit {
  public sub: any;
  public id: number;
  public activeStep: string;
  public plans: Plan;
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public planService: PlanService
  ) {}

  ngOnInit() {
    this.getPlans();
    
    this.sub = this.route.data.subscribe(data => {
      if (String(this.activeStep) !== String(data.component)) {
        this.activeStep = data.component;
      }
    });
  }

  private getPlans(): void {
    this.planService.get().subscribe(plans => {
      this.plans = plans;
    });
  }
}
