import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { RoomActivity } from '../models/room-activity';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class RoomActivityService extends ApiService {

  constructor(
    public http: HttpClient,
    public router: Router
  ) {
    super(http, router);
  }

  getByRoomId(roomId: number, filters?: Object, page?: number, perPage?: number): Observable<any> {
    const path = `${this.fsBasePath}/rooms/${roomId}/activities`;
    const params: any = {
      page: page ? page : 1,
      per_page: perPage ? perPage : 9999
    };

    const keys = Object.keys(filters);
    keys.forEach(key => {
      if (filters[key] != null) {
        params[key] = filters[key];
      }
    });

    return Observable.create(observer => {
      this.authenticatedGet(path, params)
        .map(results => {
          return {'roomActivities': this.mapRoomActivities(results['body']), 'headers': results['headers']};
        })
        .subscribe(results => {
          observer.next(results);
        });
    });
  }

  getChildActivities(roomId: number, activityId: number, page?: number, perPage?: number) {
    const path = `${this.fsBasePath}/rooms/${roomId}/activities/${activityId}/child_activities`;
    const params: any = {
      page: page ? page : 1,
      per_page: perPage ? perPage : 9999
    };

    return Observable.create(observer => {
      this.authenticatedGet(path, params)
        .map(results => {
          return {'roomActivities': this.mapRoomActivities(results['body']), 'headers': results['headers']};
        })
        .subscribe(results => {
          observer.next(results);
        });
    });
  }

  private mapRoomActivities(results) {
    const userParticipations = [];
    for (const result of results) {
      userParticipations.push(this.mapRoomActivity(result));
    }

    return userParticipations;
  }

  private mapRoomActivity(item) {
    return new RoomActivity(item);
  }

  getActivityIcon(activity: RoomActivity) {
    switch (activity.code) {
      case 'room_visited' : return 'fas fa-key';
      case 'room_created' : return 'fas fa-plus';
      case 'folder_archived' : return 'fas fa-folder';
      case 'folder_created' : return 'fas fa-folder';
      case 'folder_renamed' : return 'fas fa-folder';
      case 'folder_parent_changed' : return 'fas fa-folder';
      case 'document_archived' : return 'fas fa-file';
      case 'document_created' : return 'fas fa-file';
      case 'document_renamed' : return 'fas fa-file';
      case 'document_parent_changed' : return 'fas fa-file';
      case 'document_viewed' : return 'fas fa-eye';
      case 'question_created' : return 'fas fa-question-circle';
      case 'question_closed' : return 'fas fa-question-circle';
      case 'question_user_assigned' : return 'fas fa-question-circle';
      case 'comment_created' : return 'fas fa-comment';
      case 'comment_archived' : return 'fas fa-comment';
      case 'participation_created' : return 'fas fa-user';
      case 'participation_accepted' : return 'fas fa-user';
      case 'participation_declined' : return 'fas fa-user';
      case 'participation_archived' : return 'fas fa-user';
      case 'participation_unarchived' : return 'fas fa-user';
      case 'participation_role_changed' : return 'fas fa-user';
      case 'party_created' : return 'fas fa-user-tie';
      case 'party_archived' : return 'fas fa-user-tie';
      case 'party_unarchived' : return 'fas fa-user-tie';
      case 'party_renamed' : return 'fas fa-user-tie';
      default : return 'fas fa-clock';
    }
  }

  getActivityColor(activity: RoomActivity) {
    // purple, secondary, primary, sky, green
    switch (activity.code) {
      case 'room_visited' : return 'purple';
      case 'room_created' : return 'purple';
      case 'folder_archived' : return 'muted';
      case 'folder_created' : return 'secondary';
      case 'folder_renamed' : return 'secondary';
      case 'folder_parent_changed' : return 'secondary';
      case 'document_created' : return 'secondary';
      case 'document_renamed' : return 'secondary';
      case 'document_parent_changed' : return 'secondary';
      case 'document_viewed' : return 'purple';
      case 'document_archived' : return 'muted';
      case 'question_created' : return 'green';
      case 'question_closed' : return 'green';
      case 'question_user_assigned' : return 'green';
      case 'comment_created' : return 'green';
      case 'comment_archived' : return 'muted';
      case 'participation_created' : return 'primary';
      case 'participation_accepted' : return 'primary';
      case 'participation_declined' : return 'primary';
      case 'participation_archived' : return 'muted';
      case 'participation_unarchived' : return 'primary';
      case 'participation_role_changed' : return 'primary';
      case 'party_created' : return 'primary';
      case 'party_archived' : return 'muted';
      case 'party_unarchived' : return 'primary';
      case 'party_renamed' : return 'primary';
    }
  }

  getActivityRoomRoute(activity: RoomActivity): any {
    if (activity.trackable && activity.code === 'folder_created') {
      return {
        route: 'binder',
        queryParams: { folder: activity.trackable.id }
      };
    } else
    if (activity.trackable && activity.code === 'document_created') {
      return {
        route: 'binder',
        queryParams: { folder: activity.trackable.folder_id }
      };
    } else
    if (activity.trackable && activity.code === 'question_created') {
      return {
        route: 'answers',
        queryParams: { q: activity.trackable.id }
      };
    }
  }
}
