import { Injectable } from '@angular/core';
import { Plan } from '../models/plan';

@Injectable()
export class DataService {
  public binderFormData: any;
  public creditCardFormData: any;
  public billingFormData: any;

  public stripeData: any;

  public selectedPlan: Plan;
  public selectedCurrency: string;
  public defaultCurrency: string;

  constructor() {
    this.defaultCurrency = 'eur';
    this.selectedCurrency = localStorage.getItem('fs-currency');

    if(!this.selectedCurrency) {
      this.saveCurrency(this.defaultCurrency);
    }
  }

  public compileBinderData() {
    let output: any = {};

    output.name = this.binderFormData.title;
    output.region = this.binderFormData.region;
    output.accepted_room_rules = false;
    
    output.party = {};
    output.party.company_name = this.binderFormData.company_name;

    output.payment = {};
    output.payment.plan = this.selectedPlan.id;
    
    if(this.hasCoupon()) {
      output.payment.coupon_token = this.binderFormData.coupon_token;
    } else {
      output.payment.stripe_card_token = this.stripeData.id;
      output.payment.save_card = false;
      output.payment.yearly_rate_id = this.getPricing().id;
      output.payment.billing_address = this.billingFormData;
    }

    return output;
  }

  public saveCurrency(currency) {
    if(currency) {
      this.selectedCurrency = currency;
    } else {
      this.selectedCurrency = this.defaultCurrency;
    }
    
    localStorage.setItem('fs-currency', this.selectedCurrency);
  }

  public getCurrency() {
    if(this.selectedCurrency) {
      return this.selectedCurrency
    } else {
      return this.defaultCurrency;
    }
  }

  public getPricing() {
    if(this.selectedPlan && this.selectedCurrency) {
      return this.selectedPlan.getPricing(this.selectedCurrency);
    }
  }

  public hasCoupon() {
    if(this.binderFormData) {
      return this.binderFormData.coupon_token ? true : false;
    }
  }

  public clearAllData() {
    this.binderFormData = null;
    this.creditCardFormData = null;
    this.billingFormData = null;
    this.selectedPlan = null;
    this.stripeData = null;
  }
}
