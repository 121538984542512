import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Component, ViewChild } from '@angular/core';
import { ModalDirective, BsModalService } from 'ngx-bootstrap';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'two-factor-modal',
  templateUrl: './two-factor.modal.html',
})
export class TwoFactorModal {
  public errors: Array<string> = [];
  public twoFactorForm: FormGroup;
  public showValidations: boolean;
  public success: boolean;
  public elementType = 'url';
  public value: String = '';
  public secret: String= '';
  public FA_enabled: Boolean = false;

  public constructor(
    public bsModalRef: BsModalRef,
    private userService: UserService,
    public modalService: BsModalService,
    public formBuilder: FormBuilder,
    private translate: TranslateService
  ) {
    this.createForm();
  }

  ngOnInit() {
    const user = this.userService.getCurrentUser();
    this.FA_enabled = user['google_auth_enabled'];

    this.userService.generateQR().subscribe(response => {
      this.value = response['provisioning_uri'];
      this.secret = response['secret'];
    });
  }

  createForm(): any {
    const FAPattern = '^[0-9_]{6}$';
    const builderOptions = {};
    builderOptions['auth_code'] = new FormControl('', [Validators.required, Validators.pattern(FAPattern)]);
    this.twoFactorForm = this.formBuilder.group(builderOptions);
  }

  enableTwoFactor() {
    this.errors = [];
    if (!this.twoFactorForm.valid) {
      this.showValidations = true;
    } else {
      this.showValidations = false;
      const otp = this.twoFactorForm.value['auth_code'];
      this.userService.addGoogleAuth(this.secret, otp).subscribe(result => {

        this.FA_enabled = true;
        this.success = true;
      }, error => {
        const message = this.translate.instant('two_step.incorrect_authentication_code');
        this.errors.push(message);
      });
    }
  }

  removeTwoFactor() {
    this.errors = [];
    if (!this.twoFactorForm.valid) {
      this.showValidations = true;
    } else {
      const otp = this.twoFactorForm.value['auth_code'];
      this.userService.removeGoogleAuth(otp).subscribe(result => {
        this.userService.storeCurrentUser(result);
        this.FA_enabled = false;
        this.success = true;
      }, error => {
        const message = this.translate.instant('two_step.incorrect_authentication_code');
        this.errors.push(message);
      });
    }
  }

  public closeModal() {
    this.bsModalRef.hide();
  }
}
