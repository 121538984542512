export class FireUploadFile {
  public path: string;
  public fileName: string;
  public file: File;

  constructor(item) {
    if (!item) {
      return;
    }

    this.path = item.path;
    this.file = item.file;
    this.fileName = this.file.name;
  }

  getNamelessPath() {
    let namelessPath = '';
    let pathParts = [];

    if(this.path && this.file) {
      pathParts = this.path.split(this.fileName);
      if(pathParts.length) namelessPath = pathParts[0];
    }

    return namelessPath;
  }

  rename(toString) {
    this.path = this.getNamelessPath() + toString;
    this.fileName = toString;
  }
}
