import { Component, OnInit, NgZone, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { AngularTokenService } from 'angular-token';

import { UsersModal } from '../../../modals/users/users.modal';
import { RoomCreateModal } from '../../../modals/room-create/room-create.modal';

import { DataService } from '../../../services/data.service';
import { RoomService } from '../../../services/room.service';

import { Plan } from '../../../models/plan';

@Component({
  selector: 'fs-order-details',
  templateUrl: './order-details.component.html'
})
export class OrderDetailsComponent implements OnInit, OnDestroy {
  @Input() plans: Plan[];

  public usersModal: any;
  public roomCreateModal: any;
  public currentPricing: any;
  public errors: Array<string> = [];

  constructor(
    public dataService: DataService,
    public modalService: BsModalService,
    public roomService: RoomService,
    public route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private tokenService: AngularTokenService
  ) {
    this.currentPricing = this.dataService.getPricing();
  }

  ngOnInit() {
    this.route.data.subscribe(data => {
      if (!this.dataService.binderFormData) {
        this.router.navigate(['create']);
      } else if(!this.tokenService.userSignedIn()) {
        this.openUsersModal();
      }
    });
  }

  private openUsersModal() {
    this.usersModal = this.modalService.show(UsersModal, {
      keyboard: false, ignoreBackdropClick: true
    });
  }

  public submitBinder() {
    let binderData = this.dataService.compileBinderData();

    this.roomCreateModal = this.modalService.show(RoomCreateModal, {
      class: `modal-lg`, keyboard: false, ignoreBackdropClick: true
    });
    
    this.roomCreateModal.content.setState('data-room-rules');
    
    this.roomCreateModal.content.onRead.subscribe(($read) => {
      binderData.accepted_room_rules = $read;
      
      this.roomCreateModal.content.setState('creating');
      this.roomService.post(binderData).subscribe( result => {
        this.roomCreateModal.content.setState('success');
        this.dataService.clearAllData();
      },
      errResp => {
        this.errors = [];
        this.roomCreateModal.hide();

        if(errResp.error) {
          const errObj = errResp.error;
          if(errObj.error) {
            const errChild = errObj.error;
            if (errChild.includes('vat_number')) {
              this.errors.push('wizard.wrong_vat');
            } else {
              this.errors.push('general.form_error');
            }
          }
        }
      });
     });
  }

  ngOnDestroy() {
    if (this.usersModal) {
      this.usersModal.hide();
    }
    if (this.roomCreateModal) {
      this.roomCreateModal.hide();
    }
  }
}
