import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { environment } from '../environments/environment';
import { TitleService } from './services/title.service';


declare var ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  public hideMobileNotification: boolean;
  public showCookieConsent: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: TitleService,
    private translate: TranslateService,
    private route: ActivatedRoute
  ) {
    this.hideMobileNotification = true;
  }

  ngOnInit() {
    //
    // Language Storage
    //
    let language = localStorage.getItem('language');
    if (!language) {
      language = 'en';
      localStorage.setItem('language', 'en');
    }
    this.translate.use(language);
    moment.locale (language);

    //
    // Cookie Consent
    //
    if (localStorage.getItem('fireAccCookies')) {
      this.showCookieConsent = false;
    } else {
      this.showCookieConsent = true;
    }

    //
    // Page Title
    //
    this.router.events
    .filter((event) => {
      return event instanceof NavigationEnd;
    })
    .map(() => this.activatedRoute)
    .map((route) => {
      while (route.firstChild) {
        route = route.firstChild;
      }
      return route;
    })
    .filter((route) => route.outlet === 'primary')
    .mergeMap((route) => route.data)
    .subscribe((event) => {
      this.titleService.setTitle(event['title']);
    });

    //
    // Language Management
    //
    this.route.queryParams.subscribe((queryParams:any) => {
      const languages = ['en', 'nl', 'fr', 'pt', 'es', 'ru', 'de'];
      if (queryParams['language'] && languages.indexOf(queryParams['language'])) {
        const language = queryParams['language'];
        this.translate.use(language);
        moment.locale (language);
        localStorage.setItem('language', language);
      }
    });

    //
    // GA Anonymize IP
    //
    if (environment.production) {
      ga('set', 'anonymizeIp', true);
    }

    //
    // GA Management
    //
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (!event || !event.urlAfterRedirects) {
          return;
        }

        const gaPath = this.getAnalyticsPath(event.urlAfterRedirects);

        if(!gaPath) {
          return;
        }

        if (environment.production) {
          ga('set', 'page', gaPath);
          ga('send', 'pageview');

          if (localStorage.getItem('showGaEvents')) {
            console.log('GA: ', gaPath);
          }
        } else {
          if (localStorage.getItem('showGaEvents')) {
            console.log('GA (DEV): ', gaPath);
          }
        }
      }
    });
  }

  getAnalyticsPath(path) {
    let anonymPath = path.replace(/[0-9]/g, '').replace(/\/\//g, '/');

    if(anonymPath.indexOf('/answers?q=')>-1) {
      anonymPath = anonymPath.replace(/\/answers\?q=/g, '/answers');
    }

    anonymPath = this.gaStartsWithNormalizer(anonymPath, '/invitation');
    anonymPath = this.gaStartsWithNormalizer(anonymPath, '/login');
    anonymPath = this.gaStartsWithNormalizer(anonymPath, '/password/edit');
    anonymPath = this.gaStartsWithNormalizer(anonymPath, '/confirmed');

    if(anonymPath=='/') {
      return false;
    }
    
    return anonymPath;
  }

  gaStartsWithNormalizer(path, searchString) {
    if(path.slice(0,searchString.length)==searchString) {
      path = searchString;
    } 

    return path;
  }

  saveAcceptCookies() {
    localStorage.setItem('fireAccCookies', String((new Date()).getTime()))
    this.showCookieConsent = false;
  }

  onActivate(event) {
    window.scroll(0,0);
  }
}
