import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Component, ViewChild, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { Participation } from '../../models/participation';
import { ParticipationService } from '../../services/participation.service';

@Component({
  selector: 'fs-decline-participation',
  templateUrl: './decline-participation.modal.html',
})
export class DeclineParticipationModal {
  public participation: Participation;


  @ViewChild(ModalDirective) modal: ModalDirective;
  @Output() onDeclined: EventEmitter<any> = new EventEmitter();

  public constructor(
    public bsModalRef: BsModalRef,
    public participationService: ParticipationService
  ) { }

  declineParticipation() {
    this.participationService.put({
      id: this.participation.id,
      formData: {
        state: 'declined'
      }
    }).subscribe(response => {
      if (response.participation) {
        this.onDeclined.emit(response.participation);
      }
    });
    this.bsModalRef.hide();
  }

  closeModal() {
    this.bsModalRef.hide();
  }
}
