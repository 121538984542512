import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Question } from '../models/question';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class QuestionService extends ApiService {

  constructor(
    public http: HttpClient,
    public router: Router
  ) {
    super(http, router);
  }

  getByRoomId(id: number, document_id: number = null) {
    const data: object = {};
    data['room_id'] = id;
    if (document_id) {
      data['for_document'] = document_id;
    }

    return Observable.create(observer => {
      this.authenticatedGet(this.fsBasePath + '/questions', data)
        .map(results => {
          return this.mapQuestions(results['body']);
        })
        .subscribe(results => {
          observer.next(results);
        });
    });
  }

  getById(id: number) {
    return Observable.create(observer => {
      this.authenticatedGet(this.fsBasePath + '/questions/' + id, {})
        .map(results => {
          return this.mapQuestion(results['body']);
        })
        .subscribe(results => {
          observer.next(results);
        });
    });
  }

  create(params: any): Observable<any> {
    const path = `${this.fsBasePath}/questions`;
    return this.authenticatedPost(path, params);
  }

  close(id: number, closed: boolean): Observable<any> {
    const path = `${this.fsBasePath}/questions/${id}/close`;
    return this.authenticatedPut(path, {closed: closed});
  }

  assign(id: number, params: any): Observable<any> {
    const path = `${this.fsBasePath}/questions/${id}/assign`;
    return this.authenticatedPut(path, params);
  }

  private mapQuestions(results) {
    const questions = [];
    for (const result of results) {
        questions.push(this.mapQuestion(result));
    }
    return questions;
  }

  private mapQuestion(item) {
    return new Question(item);
  }
}
