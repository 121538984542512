import { Component, TemplateRef, OnInit, OnDestroy, Input, Output, EventEmitter, forwardRef, OnChanges } from '@angular/core';
import { FormControl, ControlValueAccessor, AbstractControl, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

@Component({
  selector: 'fire-input',
  templateUrl: './fire-input.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FireInputComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => FireInputComponent), multi: true }
  ]
})
export class FireInputComponent implements ControlValueAccessor, OnChanges {
  propagateChange:any = () => {};
  validateFn:any = () => {};

  @Input() formControlName: string;
  @Input() defaultLabel: string;
  @Input() inputType: string;
  @Input() showValidation: boolean; 

  @Input() isCreditCard: boolean;
  @Input() isCVC: boolean;
  @Input() isExp: boolean;

  private _control;

  @Input()
  get control() {
    return this._control;
  }
  
  set control(val: any) {
    this._control = val;
    this._inputValue = null;
  }

  @Input('inputValue') _inputValue;

  constructor (
  ) {}

  get inputValue() {
    return this._inputValue;
  }
  
  set inputValue(val) {
    this._inputValue = val;
    this.propagateChange(val);
  }

  ngOnChanges(inputs) {
    // if (inputs.counterRangeMax || inputs.counterRangeMin) {
    //   this.validateFn = createCounterRangeValidator(this.counterRangeMax, this.counterRangeMin);
    //   this.propagateChange(this.inputValue);
    // }
  }

  writeValue(value) {
    if (value || value==null) {
      this.inputValue = value;
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  validate(c: FormControl) {
    return this.validateFn(c);
  }
}
