import { Http } from '@angular/http';

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SupportService extends ApiService {

  constructor(
    public http: HttpClient,
    public router: Router
  ) {
    super(http, router);
  }

  create(params: any): Observable<any> {
    const path = `${this.fsBasePath}/tickets/app`;

    const formData: FormData = new FormData();
    formData.append('room_id', params.room);
    formData.append('title', params.title);
    formData.append('description', params.description);

    return this.authenticatedPost(path, formData);
  }
}
