import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'fs-room-rules-pt',
  templateUrl: './room-rules-pt.component.html',
})
export class RoomRulesPtComponent {
  constructor() {
  }
}
