import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { Participation } from '../models/participation';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ParticipationService extends ApiService {

  constructor(
    public http: HttpClient,
    public router: Router
  ) {
    super(http, router);
  }

  get(params?: any): Observable<any> {
    const path = `${this.fsBasePath}/participations`;

    return Observable.create(observer => {
      this.authenticatedGet(path)
        .map(results => {
          return {'participations': this.mapParticipations(results['body']), 'headers': results['headers']};
        })
        .subscribe(results => {
          observer.next(results);
        });
    });
  }

  getByRoom(roomId: number, params?: any): Observable<any> {
    const path = `${this.fsBasePath}/rooms/${roomId}/participations`;

    return Observable.create(observer => {
      this.authenticatedGet(path)
        .map(results => {
          return {'participations': this.mapParticipations(results['body']), 'headers': results['headers']};
        })
        .subscribe(results => {
          observer.next(results);
        });
    });
  }

  resendInvitation(participation: Participation): Observable<any> {
    const path = `${this.fsBasePath}/rooms/${participation.room.id}/participations/${participation.id}/resend`;

    return Observable.create(observer => {
      this.authenticatedPut(path)
        .map(result => {
          return this.mapParticipation(result['body']);
        })
        .subscribe(result => {
          observer.next(result);
        });
    });
  }

  put(params?: any, method?: any): Observable<any> {
    let path = '';
    
    if (!method) {
      path = `${this.fsBasePath}/participations/${params.id}`;
    } else {
      path = `${this.fsBasePath}/participations/${params.id}/${method}`;
    }

    return Observable.create(observer => {
      this.authenticatedPut(path, params.formData)
        .map(result => {
          return {'participation': this.mapParticipation(result['body']), 'headers': result['headers']};
        })
        .subscribe(result => {
          observer.next(result);
        });
    });
  }

  archive(id: number): Observable<any> {
    return Observable.create(observer => {
      this.authenticatedPut(`${this.fsBasePath}/participations/${id}/archive`)
        .map(result => {
          return {'participation': this.mapParticipation(result['body']), 'headers': result['headers']};
        })
        .subscribe(result => {
          observer.next(result);
        });
    });
  }

  unarchive(id: number): Observable<any> {
    return Observable.create(observer => {
      this.authenticatedPut(`${this.fsBasePath}/participations/${id}/unarchive`)
        .map(result => {
          return {'participation': this.mapParticipation(result['body']), 'headers': result['headers']};
        })
        .subscribe(result => {
          observer.next(result);
        });
    });
  }

  post(params: any): Observable<any> {
    const path = `${this.fsBasePath}/rooms/${params.roomId}/participations`;
    return this.authenticatedPost(path, params.formData);
  }

  hasActiveAdmin(participations: Array<Participation>) {
    return true;
  }

  private mapParticipations(results) {
    const participations = [];
    for (const result of results) {
      participations.push(this.mapParticipation(result));
    }
    return participations;
  }

  private mapParticipation(item) {
    return new Participation(item);
  }
}
