import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Component, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { FormGroup, FormControl, FormArray, Validators, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { Router } from "@angular/router";

import { QuestionService } from '../../services/question.service';
import { ParticipationService } from '../../services/participation.service';
import { Document } from '../../models/document';
import { Participation } from '../../models/participation';
import { TranslateService } from '@ngx-translate/core';
import { SelectService } from '../../services/select.service';


@Component({
  selector: 'fs-create-question-modal',
  templateUrl: './create-question.modal.html',
})
export class CreateQuestionModal {
  @ViewChild(ModalDirective) modal: ModalDirective;

  public roomId: number;
  public document: Document;
  public form: FormGroup;
  public showValidations: boolean;
  public isStarted: boolean;
  public isCompleted: boolean;
  public currentObserver: any;
  public participations: Participation[];
  public assigneesDropdownList = [];
  public selectedAssignees = [];
  public assigneesDropdownSettings = {};

  public constructor(
    public bsModalRef: BsModalRef,
    public formBuilder: FormBuilder,
    public questionService: QuestionService,
    public participationService: ParticipationService,
    private router: Router,
    private translate: TranslateService,
    public selectService: SelectService
  ) {
    this.setupForm();
  }

  public process(): Observable<any> {
    if (!this.roomId) {
      console.error('new-folder.modal: roomId is expected!');
    }

    return Observable.create(observer => {
      this.currentObserver = observer;
      this.setupForm();
    });
  }

  private setupForm() {
    if (this.roomId) {
      this.fetchParticipations();
    }

    this.assigneesDropdownSettings = {
      singleSelection: false,
      text: this.translate.instant("q&a.create_question_form_select_assignees"),
      selectAllText: this.translate.instant("general.select_all"),
      unSelectAllText: this.translate.instant("general.unselect_all"),
      searchPlaceholderText: this.translate.instant("general.search"),
      enableSearchFilter: true,
      groupBy: 'partyName'
    };

    const builderOptions = {};

    // invisible
    builderOptions['room_id'] = new FormControl(this.roomId, Validators.required);
    if (this.document) {
      builderOptions['document_id'] = new FormControl(this.document.id, Validators.required);
    }

    // required
    builderOptions['title'] = new FormControl('', Validators.required);
    builderOptions['description'] = new FormControl('', Validators.required);
    builderOptions['subject'] = new FormControl('', Validators.required);

    this.form = this.formBuilder.group(builderOptions);

   // console.log(this.form.value);
    this.showValidations = false;
    this.isStarted = false;
    this.isCompleted = false;

    setTimeout(() => {
      this.form.controls['subject'].patchValue('1');
    }, 1000);
  }

  onSubmit() {
    this.showValidations = false;

    if (!this.form.valid) {
      this.showValidations = true;
    } else {
      this.showValidations = false;
      this.saveQuestion();
    }
  }

  private saveQuestion() {
    this.isStarted = true;
    const formValues = this.form.value;
    formValues.assignees = this.selectedAssignees.map(selectedAssignee => { return {user_id: selectedAssignee.id}});
    this.questionService.create(formValues).subscribe(response => {
      this.isCompleted = true;
      this.currentObserver.next();
      this.bsModalRef.hide();
      this.router.navigate(['rooms', this.roomId, 'answers'], { queryParams: { q: response['body'].id } });
    });
  }

  private fetchParticipations() {
    this.participationService.getByRoom(this.roomId).subscribe(response => {
      this.participations = response.participations;
      this.assigneesDropdownList = this.participations.map(participation => {
        const obj: any = {
          id: participation.user.id,
          itemName: participation.user.getFullName()
        };

        if (participation.party && participation.party.company_name) {
          obj.partyName = participation.party.company_name;
        }

        return  obj;
      });
    });
  }

  public closeModal() {
    this.bsModalRef.hide();
  }
}
