import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { RoomParty } from '../models/room-party';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class RoomPartyService extends ApiService {

  constructor(
    public http: HttpClient,
    public router: Router
  ) {
    super(http, router);
  }

  getByRoomId(id: number, params?: any): Observable<any> {
    const path = `${this.fsBasePath}/rooms/${id}/parties`;

    return Observable.create(observer => {
      this.authenticatedGet(path)
        .map(results => {
          return {'roomParties': this.mapRoomParties(results['body']), 'headers': results['headers']};
        })
        .subscribe(results => {
          observer.next(results);
        });
    });
  }

  post(roomId:Number, formData: any): Observable<any> {
    return this.authenticatedPost(`${this.fsBasePath}/rooms/${roomId}/parties`, formData);
  }

  put(id: number, formData: any): Observable<any> {
    return this.authenticatedPut(`${this.fsBasePath}/parties/${id}`, formData);
  }

  archive(id: number): Observable<any> {
    return Observable.create(observer => {
      this.authenticatedPut(`${this.fsBasePath}/parties/${id}/archive`)
        .map(result => {
          return {'party': this.mapRoomParty(result['body']), 'headers': result['headers']};
        })
        .subscribe(result => {
          observer.next(result);
        });
    });
  }

  unArchive(id: number): Observable<any> {
    return Observable.create(observer => {
      this.authenticatedPut(`${this.fsBasePath}/parties/${id}/unarchive`)
        .map(result => {
          return {'party': this.mapRoomParty(result['body']), 'headers': result['headers']};
        })
        .subscribe(result => {
          observer.next(result);
        });
    });
  }

  private mapRoomParties(results) {
    const userParticipations = [];
    for (const result of results) {
      userParticipations.push(this.mapRoomParty(result));
    }

    return userParticipations;
  }

  private mapRoomParty(item) {
    return new RoomParty(item);
  }
}
