import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'fs-room-rules-fr',
  templateUrl: './room-rules-fr.component.html',
})
export class RoomRulesFrComponent {
  constructor() {
  }
}
