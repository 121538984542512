import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Component, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';


@Component({
  selector: 'fs-users-modal',
  templateUrl: './users.modal.html',
})
export class UsersModal {
  @ViewChild(ModalDirective) modal: ModalDirective;

  public constructor(
    private _bsModalRef: BsModalRef
  ) { }

  public loggedIn(event) {
    this._bsModalRef.hide();
  }
}
