export class Pricing {
  public id: string;
  public iso: string;
  public amount: number;
  public plan_id: number;

  constructor(item) {
    this.id = item.id;
    this.iso = item.currency_iso;
    this.amount = item.amount;
    this.plan_id = item.plan_id;
  }
}